import React, { useState, useEffect } from 'react';
import axios from 'axios';
import EserviceCard from '../../../default_components/EserviceCard/EserviceCard';
import { Button } from "@material-tailwind/react";

function Eservices(props) {

    return (
        <>
            <div className="lg:py-10 font-['Montserrat']">
                <div className="pb-32">
                    <div className="font-bold max-md:text-xl text-4xl text-neutral-900 pb-7">
                        TOUS NOS E-SERVICES
                    </div>
                    <EserviceCard title="Statut des MPME" subtitle="Attestation d'identification du statut de la MPME" lien='https://service-public.bj/public/services/service/PS00897' />
                </div>
                <div className="pb-32">
                    <div className="font-bold max-md:text-xl text-4xl text-neutral-900 pb-7">
                        AUTRES SERVICES
                    </div>
                    <a href='https://service-public.bj/public/services/e-services' target='_blank'>
                        <Button variant="gradient" className="rounded-full bg-green-700 flex items-center gap-2">
                            Portail des services
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={2}
                                stroke="currentColor"
                                className="h-5 w-5"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                                />
                            </svg>
                        </Button>
                    </a>
                </div>
            </div>
        </>
    );
}

export default Eservices;