import React from 'react';
import fond1 from './Images/imgbanactu.png'
import fond2 from './Images/imgbanserv.png'
import fond3 from './Images/imgbanopportunite.jpg'
import fond4 from './Images/imgbancontact.jpg'
import fond5 from './Images/imgbanguide.jpg'
import SearchBar from '../../default_components/SearchBar/SearchBar';
// max-md:text-black
//backgroundImage:"url(/static/media/imgbanguide.db5f32a8ca7c02ffa139.jpg
// min - [1200px]: px - 14 pt - 72 max - sm: px - 4 max - xl: px - 14 mx - auto max - w - screen - xl text - left py - 16 lg: py - 16
function Banniere(props) {
    const backgroundImageStyle = {
        backgroundImage: `url(${props.desc1 === "Actualités" ? fond1 : props.desc1 === "E-services" ? fond2 : props.desc1 === "Opportunités" ? fond3 : props.desc1 === "Contactez" ? fond4 : fond5})`,
        position: 'relative', // Ajout de position relative
    };

    const overlayStyle = {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.3)', // Couleur de fond semi-transparente
    };
    return (
        <section style={backgroundImageStyle} className="h-full bg-no-repeat bg-cover bg-green-700">
            <div style={overlayStyle}></div>
            
            <div className="pt-44 max-md:px-4 pb-24 lg:px-44 font-['Montserrat']">
                <div>
                    <h1 className="max-md:text-6xl animate-fade-right animate-once animate-duration-1000 animate-delay-300 mb-4 text-7xl font-semibold tracking-tight leading-none text-white md:text-7xl lg:text-7xl">{props.desc1}</h1>
                </div>
                <div>
                    <h1 className="max-md:text-6xl animate-fade-right animate-once animate-duration-1000 animate-delay-300 mb-4 text-7xl font-semibold tracking-tight leading-none text-white md:text-7xl lg:text-7xl">{props.desc2}</h1>
                </div>
                <div className='py-10'>
                    {/* <SearchBar /> */}
                </div>
            </div>
        </section>
    );
}

export default Banniere;