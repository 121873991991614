import React from 'react';
import quisommenouspng from '../Images/quisommenous.jpg'
import glyph1 from '../Images/glyph1.png'
import glyph2 from '../Images/glyph2.png'

function Quisommesnous(props) {
    return (
        <>
            <div className='grid grid-cols-2 max-xl:flex max-xl:flex-col'>
                <div>
                    <img src={quisommenouspng} />
                </div>
                <div className='p-10 max-md:px-4 max-[1440px]:p-5'>
                    <div>
                        <span className="text-yellow-300 text-[26px] font-bold font-['Montserrat']">ADPME</span>
                    </div>
                    <div>
                        <span className="text-neutral-900 text-[32px] font-bold font-['Montserrat']">Qui sommes-nous ?</span>
                    </div>
                    <div className='pt-6 max-[1440px]:pt-3'>
                        <p className="text-justify text-neutral-900 text-[18px] font-normal font-['Montserrat']">
                            L'ADPME, c'est l'assurance d'un accompagnement professionnel et personnalisé, pour vous permettre de réaliser votre rêve d'entrepreneur.
                            Nous sommes là pour vous aider à faire de votre entreprise un succès.
                            
                        </p>
                        <br />
                        <p className="text-justify text-neutral-900 text-[18px] font-normal font-['Montserrat']">
                            L'Agence de Développement des Petites et Moyennes Entreprises a pour vocation de fédérer et de mettre en cohérence l'ensemble des interventions publiques en appui aux MPME avec une offre intégrée d'accompagnement, d'orientation et de financement des MPME.
                        </p>
                        <p className="mb-2 text-neutral-900 text-[18px] font-normal font-['Montserrat']">
                            Contactez-nous dès aujourd'hui pour en savoir plus.
                        </p>
                        <div className='max-md:flex max-md:flex-col max-md:gap-4 grid grid-cols-2 pt-6 max-[1440px]:pt-3'>
                            <div className='flex flex-row'>
                                <div>
                                    <img src={glyph1} />
                                </div>
                                <div>
                                    <p className="text-neutral-900 text-[18px] font-normal font-['Montserrat']">
                                        AccompagnementOrientation,
                                        Développement, Appui,
                                        Succès
                                    </p>
                                </div>
                            </div>
                            <div className='flex flex-row'>
                                <div>
                                    <img src={glyph2} />
                                </div>
                                <div>
                                    <p className="text-neutral-900 text-[18px] font-normal bold font-['Montserrat']">
                                        Libérez le potentiel de votre entreprise dès aujourd'hui !
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Quisommesnous;