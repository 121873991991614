import React, {useState, useEffect} from 'react';
import { TECarousel, TECarouselItem } from "tw-elements-react";
import axios from 'axios'

const urlstandart = process.env.REACT_APP_URL_STANDART

function Catalogue(props) {
    const [event, setEvent] = useState([])
    useEffect(() => {
        const newurl = process.env.REACT_APP_URL_STANDART + "api/evenements"
        axios.get(newurl, { withCredentials: true })
            .then(response => {
                let tabevent = response.data.data.evenements.filter((ev) =>
                    ev.id == props.id
                );
                console.log(response.data.data)
                setEvent(tabevent);
                console.log(tabevent)
                
            })
            .catch(err => {
                alert('Erreur lors de la récupération des informations')
            })
    }, [])
    const tabimgcat = []
    if(event)
    {
        event.map((ev)=>{
            if (ev.actualite.catalogue)
            {
                ev.actualite.catalogue.ressources.map((res) => {
                    let imginfo = res
                    let tabimg = []
                    tabimg = imginfo.split(',')
                    imginfo = tabimg[0]
                    imginfo = imginfo.replace(/\\/g, "/")
                    tabimgcat.push(imginfo)
                })
            }
        })
        console.log(tabimgcat)
    }
    if(!event)
    {
        return ""
    }
    return (
        <div>
            {tabimgcat.length > 0 && (
                <div className="bg-[#E3F2E5] font-[' Montserrat'] lg:px-44 lg:py-10">
                    <div className='mb-5 max-md:px-4 max-md:text-xl text-4xl font-bold'>
                        IMAGES DE L'ÉVÈNEMENT
                    </div>
                    <div className='mb-5 max-md:px-4 text-3sm font-normal'>
                        Crédits photos: ADPME
                    </div>
                    <TECarousel showControls showIndicators crossfade ride="carousel">
                        <div className="relative w-full overflow-hidden after:clear-both after:block after:content-['']">
                            {tabimgcat.map((tb, index) => (
                                <TECarouselItem
                                    itemID={index + 1}
                                    className="relative float-left -mr-[100%] hidden w-full !transform-none transition-opacity duration-[600ms] ease-in-out motion-reduce:transition-none"
                                >
                                    <img
                                        crossOrigin="anonymous"
                                        src={`${urlstandart}api/${tb}`}
                                        className="block w-full"
                                        alt="..."
                                    />
                                </TECarouselItem>
                            ))}
                        </div>
                    </TECarousel>
                </div>
            )}
        </div>
    );
}

export default Catalogue;