import React from 'react';

function Description() {
    return (
        <>
            <section>
                <div className='max-xl:px-4 px-14 mx-auto max-w-screen-xl text-left py-24 lg:py-15 sm:py-15'>
                    <div className="max-xl:w-full h-full max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                        <h5 className="mb-2 text-2sm font-bold tracking-tight text-gray-900 dark:text-white">
                            <span>Attestation d'identification du statut de la MPME </span>
                            
                        </h5>
                        {/* <p className="mb-3 text-sm font-normal text-gray-700 dark:text-gray-400"></p> */}
                        <a target='_blank' href='https://service-public.bj/public/services/service/PS00897' className="rounded-l-lg rounded-tr-lg inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-amber-700 rounded-lg hover:bg-amber-800 focus:ring-4 focus:outline-none focus:ring-amber-300 dark:bg-amber-600 dark:hover:bg-amber-700 dark:focus:ring-amber-800">
                            Allez voir
                            <svg className="w-3 h-3 ms-2.5 rtl:rotate-[270deg]" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 11v4.833A1.166 1.166 0 0 1 13.833 17H2.167A1.167 1.167 0 0 1 1 15.833V4.167A1.166 1.166 0 0 1 2.167 3h4.618m4.447-2H17v5.768M9.111 8.889l7.778-7.778" />
                            </svg>
                        </a>
                    </div>
                    {/* <ol className="space-y-4 text-black list-decimal list-inside dark:text-black ml-5 text-left">
                        <li>
                            <a target='_blank' href='https://service-public.bj/public/services/service/PS00897' className='inline-flex text-amber-700 items-center'>
                                <span>Attestation d'identification du statut de la MPME </span>
                                <svg className="w-3 h-3 ms-2.5 rtl:rotate-[270deg]" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 11v4.833A1.166 1.166 0 0 1 13.833 17H2.167A1.167 1.167 0 0 1 1 15.833V4.167A1.166 1.166 0 0 1 2.167 3h4.618m4.447-2H17v5.768M9.111 8.889l7.778-7.778" />
                                </svg>
                            </a>
                            
                        </li>
                    </ol> */}
                </div>
            </section>
        </>
    );
}

export default Description;