import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
// import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { FileInput, Label, Modal, TextInput, Select } from 'flowbite-react';
import {
    Tabs,
    TabsHeader,
    TabsBody,
    Tab,
    TabPanel,
} from "@material-tailwind/react";
import MenuItem from '@mui/material/MenuItem';
import SelectM from '@mui/material/Select';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import axios from 'axios'
import { Button } from "@material-tailwind/react";

const steps = [
    {
        label: 'Informations du dirigeant',
        description: "",
    },
    {
        label: 'Informations de l\'entreprise',
        description:
            'An ad group contains one or more ads which target a shared set of keywords.',
    },
    {
        label: 'Potentiel commercial et stratégie de croissance',
        description: `Try out different ad text to see what brings in the most customers,
              and learn how to enhance your ads using features like ad extensions.
              If you run into any problems with your ads, find out how to tell if
              they're running and how to resolve approval issues.`,
    },
];

const data = [
    {
        label: "Enregistrer son entreprise",
        value: "entreprise",
        desc: `It really matters and then like it really doesn't matter.
      What matters is the people who are sparked by it. And the people 
      who are like offended by it, it doesn't matter.`,
    },
    {
        label: "Rechercher son entreprise",
        value: "recherche",
        desc: `Because it's about motivating the doers. Because I'm here
      to follow my dreams and inspire other people to follow their dreams, too.`,
    },
];

export default function PMESearch() {
    const currentDate = new Date();
    const [activeTab, setActiveTab] = React.useState("entreprise");
    const [activeStep, setActiveStep] = useState(0);
    const [fullName, setFullName] = useState('')
    const [nom, setNom] = useState('')
    const [prenom, setPrenom] = useState('')
    const [old, setOld] = useState(new Date())
    const [genre, setGenre] = useState('')
    const [mail, setMail] = useState('')
    const [tel, setTel] = useState('')
    const [fonction, setFonction] = useState('')
    const [part, setPart] = useState(0)
    const [sigle, setSigle] = useState('')
    const [raisonSociale, setRaisonSociale] = useState('')
    const [secteur, setSecteur] = useState(0)
    const [sSecteur, setSSecteur] = useState([])
    const [sousSecteur, setSousSecteur] = useState(0)
    const [numeroRCCM, setNumeroRCCM] = useState('')
    const [villageOuQuartier, setVillageOuQuartier] = useState(0)
    const [arrondissement, setArrondissement] = useState(0)
    const [commune, setCommune] = useState(0)
    const [dept, setDept] = useState(0)
    const [dateCreation, setDateCreation] = useState(new Date())
    const [adresse, setAdresse] = useState('')
    const [telEntreprise, setTelEntreprise] = useState('')
    const [mailEntreprise, setMailEntreprise] = useState('')
    const [bp, setBp] = useState('')
    const [statutJuridique, setStatutJuridique] = useState(0)
    const [capitaleSocial, setCapitalSocial] = useState(0)
    const [currentYear, setCurrentYear] = useState(0);
    const [firstYear, setFirstYear] = useState(currentDate.getFullYear() - 1);
    const [firstYearCA, setFirstYearCA] = useState(0);
    const [firstYearEmployer, setFirstYearEmployer] = useState(0);
    const [secondYear, setSecondYear] = useState(currentDate.getFullYear() - 2);
    const [secondYearCA, setSecondYearCA] = useState(0);
    const [secondYearEmployer, setSecondYearEmployer] = useState(0);
    const [value, setValue] = React.useState('1');
    const [communes, setCommunes] = useState([]);
    const [communesChoose, setCommunesChoose] = useState([]);
    const [arrondissements, setArrondissements] = useState([]);
    const [arrondissementsChoose, setArrondissementsChoose] = useState([]);
    const [depts, setDepts] = useState([]);
    const [vils, setVils] = useState([]);
    const [secteurs, setSecteurs] = useState([]);
    const [sousSecteurs, setSousSecteurs] = useState([]);
    const [sousSecteursChoose, setSousSecteursChoose] = useState([]);
    const [vilsChoose, setVilsChoose] = useState([]);
    const [projets, setProjets] = useState([]);
    const [statusJs, setStatutJs] = useState([]);
    const [projet, setProjet] = useState([]);
    const [searchComapnyName, setSearchCompanyName] = useState('');
    const [companySearched, setCompanySearched] = useState([]);
    const [companySearchedId, setCompanySearchedId] = useState(0);
    const [selectedFileRccm, setSelectedFileRccm] = useState(null);
    const [selectedFileIdentity, setSelectedFileIdentity] = useState(null);
    const [selectedFileUpload, setSelectedFileUpload] = useState(null);
    const [selectedFileAuth, setSelectedFileAuth] = useState(null);
    const [getFileAuth, setGetFileAuth] = useState(false);
    const [errorFile, setErrorFile] = useState('');
    const [otherProjet, setOtherProject] = useState(false);
    const [otherProjetText, setOtherProjectText] = useState('');
    const [otherSecteur, setOtherSecteur] = useState(false);
    const [otherSecteurText, setOtherSecteurText] = useState('');

    const handleRadioChange = (event) => {
        setGenre(event.target.value);
    };
    const handleFileChangeAuth = (event) => {
        setSelectedFileAuth(event.target.files[0]);
    }
    const handleFileChangeRccm = (event) => {
        const selectedFile = event.target.files[0];
        const fileSizeInMegabytes = selectedFile.size / (1024 * 1024); // Taille du fichier en Mo
        console.log(fileSizeInMegabytes)
        console.log(selectedFile)
        if (fileSizeInMegabytes > 5) {
            alert("Veuillez joindre un fichier de moins de 5 MO.")
            setSelectedFileRccm(null);
        } else {
            setSelectedFileRccm(event.target.files[0]);
        }
    }
    const handleFileChangeIdentite = (event) => {
        const selectedFile = event.target.files[0];
        const fileSizeInMegabytes = selectedFile.size / (1024 * 1024); // Taille du fichier en Mo

        if (fileSizeInMegabytes > 5) {
            alert("Veuillez joindre un fichier de moins de 5 MO.")
            setSelectedFileIdentity(null);
        } else {
            setSelectedFileIdentity(event.target.files[0]);
        }

    }

    const deptChoose = (e) => {
        setDept(e.target.value)
        setCommunesChoose(communes.filter((com) => com.departementId == e.target.value))
        console.log("departementid :" + e.target.value)
        console.log(communesChoose)
    }

    const comChoose = (e) => {
        setCommune(e.target.value)
        setArrondissementsChoose(arrondissements.filter((com) => com.communeId == e.target.value))
        console.log("communeid :" + e.target.value)
        console.log(arrondissementsChoose)
    }

    const arrChoose = (e) => {
        setArrondissement(e.target.value)
        setVilsChoose(vils.filter((com) => com.arrondissementId == e.target.value))
        console.log("Arrondissementid :" + e.target.value)
        console.log(vilsChoose)
    }

    const sectChoose = (e) => {
        setSecteur(e.target.value)
        const tab = sousSecteurs.filter((com) => com.secteurId == e.target.value)
        tab.push({ libelle: "Autre", code: "AUT" })
        setSousSecteursChoose(tab)
        console.log("secteurId :" + e.target.value)
        console.log(vilsChoose)
    }

    const handleChangeSSecteur = (event) => {
        const { checked, id } = event.target;

        // Créer une copie de l'état actuel de projet
        const secteurCopy = [...sSecteur];

        if (checked) {
            // Ajouter la valeur de la case à cocher sélectionnée à la copie de projet
            secteurCopy.push(id);
            secteurCopy.map((s) => {
                console.log(s === "AUT")
                if (s === "AUT") {
                    setOtherSecteur(true)
                    return
                }
            })

        } else {
            // Retirer la valeur de la case à cocher désélectionnée de la copie de projet
            const index = secteurCopy.indexOf(id);
            secteurCopy.map((s) => {
                console.log(s === "AUT")
                if (s === "AUT") {
                    setOtherSecteur(false)
                    setOtherSecteurText('')
                    return
                }
            })

            if (index !== -1) {
                secteurCopy.splice(index, 1);
            }
        }

        // Mettre à jour l'état de projet avec la copie modifiée
        setSSecteur(secteurCopy);

        console.log(secteurCopy);
    };

    const handleChangeProjet = (event) => {
        const { checked, id } = event.target;

        // Créer une copie de l'état actuel de projet
        const projetCopy = [...projet];

        if (checked) {
            // Ajouter la valeur de la case à cocher sélectionnée à la copie de projet
            projetCopy.push(id);
            id == 'Autre' ? setOtherProject(true) : setOtherProject(false)
        } else {
            // Retirer la valeur de la case à cocher désélectionnée de la copie de projet
            const index = projetCopy.indexOf(id);
            if (id == 'Autre') {
                setOtherProjectText('')
            }
            id == 'Autre' ? setOtherProject(false) : setOtherProject(true)
            if (index !== -1) {
                projetCopy.splice(index, 1);
            }
        }

        // Mettre à jour l'état de projet avec la copie modifiée
        setProjet(projetCopy);

        console.log(projetCopy);

    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    const handleNext = () => {
        setActiveStep((prevActiveStep) => {
            console.log(prevActiveStep)
            return prevActiveStep + 1
        });
    };

    const handleDateChange = (e) => {
        setOld(e.target.value);
        console.log(e.target.value)
        const today = new Date().toISOString().split('T')[0]

        if (e.target.value > today) {
            alert("Sélectionnez une date antérieure à la date d'aujourd'hui.")
            setOld('')
        } else {
            // Si l'âge est inférieur à 18 ans, afficher le champ de type fichier
            let controlleur = false
            const dateActuelle = new Date();
            const dateNaissanceObj = new Date(e.target.value);
            const differenceEnAnnees = dateActuelle.getFullYear() - dateNaissanceObj.getFullYear();
            controlleur = differenceEnAnnees < 18 ? true : false
            setGetFileAuth(controlleur);
        }

    };
    const handleDateEntChange = (e) => {
        setDateCreation(e.target.value);
        const today = new Date().toISOString().split('T')[0]

        if (e.target.value > today) {
            alert("Sélectionnez une date antérieure à la date d'aujourd'hui.")
            setDateCreation('')
        }

    };
    const handleSubmit = (e) => {
        e.preventDefault()
        let tab = []
        sSecteur.map((el) => {
            let sectChoose = []
            console.log(el)
            console.log(sousSecteursChoose)
            if (el != "AUT") {

                sectChoose = sousSecteursChoose.find((s) => s.code == el)

                tab.push({ "id": parseInt(sectChoose.id, 10) })
            }
        })
        let tabp = []
        projet.map((el) => {
            let sectChoose = []
            if (el != "Autre") {


                sectChoose = projets.find((s) => s.actualite.title == el)
                // sectChoose = projets.find((s) => s.libelle == el)
                tabp.push({ "appelAProjetId": parseInt(sectChoose.id, 10), "decision": true })

            }
        })
        console.log(
            fullName + "\n " +
            old + "\n " +
            genre + "\n " +
            mail + "\n " +
            tel + "\n " +
            fonction + "\n " +
            part + "\n " +
            sigle + "\n " +
            raisonSociale + "\n " +
            secteur + "\n " +
            sousSecteur + "\n " +
            numeroRCCM + "\n " +
            villageOuQuartier + "\n " +
            arrondissement + "\n " +
            commune + "\n " +
            dept + "\n " +
            dateCreation + "\n " +
            adresse + "\n " +
            telEntreprise + "\n " +
            mailEntreprise + "\n " +
            bp + "\n " +
            statutJuridique + "\n " +
            capitaleSocial + "\n " +
            firstYear + "\n " +
            firstYearCA + "\n " +
            firstYearEmployer + "\n " +
            secondYear + "\n " +
            secondYearCA + "\n " +
            secondYearEmployer + "\n " +
            tab
        )
        const tabExercice = [];
        tabExercice.push(
            {
                "ca": parseInt(firstYearCA, 10),
                "nbreEmp": parseInt(firstYearEmployer, 10),
                "annee": parseInt(firstYear, 10)
            },
            {
                "ca": parseInt(secondYearCA, 10),
                "nbreEmp": parseInt(secondYearEmployer, 10),
                "annee": parseInt(secondYear, 10)
            }
        )
        const formdata = new FormData()
        if (otherProjetText != '') {
            formdata.append("autreAppelAProjet", otherProjetText)
        }
        if (otherSecteurText != '') {
            formdata.append("autreSecteur", otherSecteurText)
        }
        formdata.append("sigle", sigle)
        formdata.append("raisonSociale", raisonSociale)
        formdata.append("rccm", numeroRCCM)
        formdata.append("rccmFile", selectedFileRccm)
        formdata.append("adresse", adresse)
        formdata.append("phone", telEntreprise)
        formdata.append("email", mailEntreprise)
        formdata.append("capitalSocial", parseInt(capitaleSocial, 10))
        formdata.append("statusJuridiqueId", parseInt(statutJuridique, 10))
        formdata.append("villagesOuQuartiersDevilleId", villageOuQuartier)
        formdata.append("website", "website.rvr")
        formdata.append("boitePostale", bp)
        formdata.append("Exercice", JSON.stringify(tabExercice))
        formdata.append("pieceIdentite", selectedFileIdentity)
        formdata.append("autorisation", selectedFileAuth)
        formdata.append("CreateurDirigeant", JSON.stringify([
            {
                "firstname": prenom,
                "lastname": nom,
                "gender": genre,
                "phone": tel,
                "birthday": old,
                "email": mail,
                "pme_createur_dirigeants": [
                    {
                        "poste": fonction,
                        "part": parseInt(part, 10)
                    }
                ]
            }
        ]))
        formdata.append("ParticipeAppelAProjet", JSON.stringify(tabp))
        formdata.append("sousSecteurs", JSON.stringify(tab))
        if (window.confirm("Voulez-vous vraiment éffectuer cet enregistrement ?") == true) {
            const newurl = process.env.REACT_APP_URL_STANDART + "api/pmes"
            console.log(selectedFileRccm);
            axios.post(newurl, formdata, { withCredentials: true })
                .then(response => {
                    // console.log(response.data.data['uniqueIdentificationNumber'].split("-")[0])
                    alert(`Vous avez été enregistré. Votre entreprise est enregistrer sous le numero: ${response.data.data['uniqueIdentificationNumber'].split("-")[0]}. Veuillez noter ce numero, elle servira pour toute modification ou toute recherche à l'avenir.`)
                    window.location.reload()
                })
                .catch(err => {
                    alert('Erreur lors de l\'enregistrement')
                })
        }
    };

    const SearchCompany = async (e) => {
        e.preventDefault();
        setFullName('')
        setOld('')
        setMail('')
        setTel('')
        setFonction(0)
        setPart(0)
        const newurlsearch = process.env.REACT_APP_URL_STANDART + `api/pmes/${searchComapnyName}`
        await axios.get(newurlsearch, { withCredentials: true })
            .then(response => {
                //alert('Recrutement supprimer')
                setCompanySearched(response.data.data);
                console.log(response.data.data)
                const tab = response.data.data[0]
                setCompanySearchedId(tab.id)
            })
            .catch(err => {
                alert('Aucune entreprise n\'a été trouvée. Assurez vous d\'avoir bien renseigné le nom ou la raison sociale')
            })
    }

    const EditForm = (e) => {
        e.preventDefault();
        const newurlsearch = process.env.REACT_APP_URL_STANDART + `api/pmes/addCreateurDirigeant/${companySearchedId}`
        const formdata = new FormData()
        formdata.append("pieceIdentite", selectedFileIdentity)
        formdata.append("autorisation", selectedFileAuth)
        formdata.append("firstname", fullName);
        formdata.append("lastname", fullName);
        formdata.append("gender", genre);
        formdata.append("phone", tel);
        formdata.append("birthday", old);
        formdata.append("email", mail);
        formdata.append("poste", fonction);
        formdata.append("part", parseInt(part, 10));
        // formdata.append("CreateurDirigeant", [
        // {
        // "firstname": fullName,
        // "lastname": fullName,
        // "gender": genre,
        // "phone": tel,
        // "birthday": old,
        // "email": mail,
        // "pme_createur_dirigeants": [{
        // "poste": fonction,
        // "part": parseInt(part, 10)
        // }]
        // }
        // ])
        axios.put(newurlsearch, formdata, { withCredentials: true })
            .then(response => {
                alert("Enregistrement réussi")
                window.location.reload()
            })
            .catch(err => {
                alert('Erreur lors de la soumission du formulaire')
            })
    }
    useEffect(() => {
        const getCurrentYear = () => {
            const currentDate = new Date();
            const year = currentDate.getFullYear();
            setCurrentYear(year);
        };
        getCurrentYear();
        const newurlcom = process.env.REACT_APP_URL_STANDART + "api/communes"
        axios.get(newurlcom, {}, { withCredentials: true })
            .then(response => {
                //alert('Recrutement supprimer')
                setCommunes(response.data.data)
            })
            .catch(err => {
                console.log('Erreur lors de la récupération des communes')
            })
        const newurlarr = process.env.REACT_APP_URL_STANDART + "api/arrondissements"
        axios.get(newurlarr, {}, { withCredentials: true })
            .then(response => {
                //console.log('Recrutement supprimer')
                setArrondissements(response.data.data)
            })
            .catch(err => {
                console.log('Erreur lors de la récupération des arrondissements')
            })
        const newurldept = process.env.REACT_APP_URL_STANDART + "api/departements"
        axios.get(newurldept, {}, { withCredentials: true })
            .then(response => {
                //console.log('Recrutement supprimer')
                setDepts(response.data.data)
            })
            .catch(err => {
                console.log('Erreur lors de la récupération du départements')
            })
        const newurlvils = process.env.REACT_APP_URL_STANDART + "api/villagesOuQuartiersDeVilles"
        axios.get(newurlvils, {}, { withCredentials: true })
            .then(response => {
                //console.log('Recrutement supprimer')
                setVils(response.data.data)
            })
            .catch(err => {
                console.log('Erreur lors de la récupération du villages ou quartiers')
            })
        const newurlsects = process.env.REACT_APP_URL_STANDART + "api/secteurs"
        axios.get(newurlsects, {}, { withCredentials: true })
            .then(response => {
                //console.log('Recrutement supprimer')
                setSecteurs(response.data.data)
            })
            .catch(err => {
                console.log('Erreur lors de la récupération du secteurs')
            })
        const newurlssects = process.env.REACT_APP_URL_STANDART + "api/sousSecteurs"
        axios.get(newurlssects, {}, { withCredentials: true })
            .then(response => {
                //console.log('Recrutement supprimer')
                setSousSecteurs(response.data.data)
            })
            .catch(err => {
                console.log('Erreur lors de la récupération du sous secteurs')
            })
        const newurlpro = process.env.REACT_APP_URL_STANDART + "api/appelAProjets"
        axios.get(newurlpro, {}, { withCredentials: true })
            .then(response => {
                //console.log('Recrutement supprimer')
                const tab = response.data.data.appelAProjets
                tab.push({ actualite: { title: "Autre" } })
                // tab = response.data.data
                setProjets(tab)
                console.log("Projets : ")
                console.log(response.data.data)
                console.log(tab)
            })
            .catch(err => {
                console.log('Erreur lors de la récupération des projets')
            })
        const newurlstat = process.env.REACT_APP_URL_STANDART + "api/statutJuridiques"
        axios.get(newurlstat, {}, { withCredentials: true })
            .then(response => {
                //console.log('Recrutement supprimer')
                setStatutJs(response.data.data)
            })
            .catch(err => {
                console.log('Erreur lors de la récupération du statut juridique')
            })
    }, []);

    return (
        <div className="max-xl:px-4 lg:py-10 font-[' Montserrat']">
            <form onSubmit={SearchCompany}>
                <label for="default-search" className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
                <div className="relative">
                    <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                        <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                        </svg>
                    </div>
                    <input onChange={(e) => setSearchCompanyName(e.target.value)} value={searchComapnyName} type="search" id="default-search" className="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Recherchez votre entreprise" required />
                    <button type="submit" className="text-white absolute end-2.5 bottom-2.5 bg-amber-700 hover:bg-amber-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Rechercher</button>
                </div>

            </form>
            {companySearched.length > 0 ? (
                <>
                    {companySearched.map((cs) => (
                        <>
                            <div className='mt-5'>
                                <span className='text-3sm font-bold'>Sigle de la compagnie : </span><span>{cs.sigle}</span>
                            </div>
                            <div>
                                <span className='text-3sm font-bold'>Raison sociale de la compagnie : </span><span>{cs.raisonSociale}</span>
                            </div>
                            <div className="mb-5">
                                <span className='text-3sm font-bold'>N° RCCM : </span><span>{cs.rccm}</span>
                            </div>
                        </>
                    ))}
                    <hr />
                    <div>
                        <span className="font-bold text-3sm">Veuillez remplir ce formulaire pour être enregistrer dans votre entreprise.</span>
                    </div>
                    <form onSubmit={EditForm}>
                        <div className='mt-5'>
                            <div className="mb-2 block">
                                <Label htmlFor="nom" value={<span className='font-bold'>Nom(s) et prénoms(s) <span className='text-red-500'>*</span></span>} />
                            </div>
                            <TextInput onChange={(e) => setFullName(e.target.value)} value={fullName} id="nom" placeholder='Ex: John Doe' type="text" required />
                        </div>
                        <div>
                            <div className="mb-2 block">
                                <Label htmlFor="datenais" value={<span className='font-bold'>Date de naissance <span className='text-red-500'>*</span></span>} />
                            </div>
                            <TextInput onChange={handleDateChange} value={old} id="datenais" type="date" required />
                        </div>
                        {getFileAuth && (
                            <div className="col-span-full">
                                <label htmlFor="cover-photoautosearch" className="block text-sm font-medium leading-6 text-gray-900">
                                    Autorisation <span className='text-red-500'>*</span>
                                </label>
                                <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                                    <div className="text-center">
                                        <div className="mt-4 flex text-sm leading-6 text-gray-600">
                                            <label
                                                htmlFor="file-upload-auto-search"
                                                className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                                            >
                                                <span>Glisser un fichier</span>
                                                <input onChange={handleFileChangeAuth} id="file-upload-auto-search" name="file-upload-auto-search" type="file" className="sr-only" />
                                            </label>
                                        </div>
                                        <p className="text-xs leading-5 text-gray-600">Max 5MB</p>
                                    </div>
                                </div>
                                <p className="text-xs leading-5 text-black font-bold">{selectedFileAuth ? selectedFileAuth.name : ("")}</p>
                            </div>
                        )}
                        <div>
                            <FormControl>
                                <FormLabel><span className='font-bold'>Genre</span></FormLabel>
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    defaultValue="female"
                                    name="radio-buttons-group"
                                    value={genre}
                                    onChange={handleRadioChange}
                                >
                                    <FormControlLabel value="HOMME" control={<Radio />} label="Femme" />
                                    <FormControlLabel value="FEMME" control={<Radio />} label="Homme" />
                                </RadioGroup>
                            </FormControl>
                        </div>
                        <div>
                            <div className="mb-2 block">
                                <Label htmlFor="mail" value={<span className='font-bold'>Adresse email <span className='text-red-500'>*</span></span>} />
                            </div>
                            <TextInput onChange={(e) => setMail(e.target.value)} value={mail} id="mail" placeholder='Ex: johndoe@gmail.com' type="text" required />
                        </div>
                        <div>
                            <div className="mb-2 block">
                                <Label htmlFor="phone" value={<span className='font-bold'>Numéro de télephone mobile <span className='text-red-500'>*</span></span>} />
                            </div>
                            {/* <TextInput onChange={(e) => setTel(e.target.value)} value={tel} id="phone" placeholder='Ex:0022960XXXXXX' type="text" required /> */}
                            <PhoneInput
                                defaultCountry="bj"
                                value={tel}
                                onChange={(phone) => setTel(phone)}
                            />
                        </div>
                        <div>
                            <div className="mb-2 block">
                                <Label htmlFor="fonction" value={<span className='font-bold'>Fonction dans l'entreprise <span className='text-red-500'>*</span></span>} />
                            </div>
                            <Select onChange={(e) => setFonction(e.target.value)} value={fonction} id="fonction" required>
                                <option value=''>Sélectionner votre fonction dans l'entreprise</option>
                                <option value='Associé'>Associé</option>
                                <option value='Propriétaire'>Propriétaire</option>
                                <option value='Actionnaire'>Actionnaire</option>
                                <option value='Gestionnaire'>Gestionnaire</option>
                                <option value='Collaborateur'>Collaborateur</option>
                            </Select>
                        </div>
                        <div>
                            <div className="mb-2 block">
                                <Label htmlFor="part" value={<span className='font-bold'>Quel est votre pourcentage des parts sociales que vous détenez ? <span className='text-red-500'>*</span></span>} />
                            </div>
                            <TextInput onChange={(e) => setPart(e.target.value)} value={part} id="part" min="0" placeholder='Ex:100' type="number" required />
                        </div>
                        <div className="col-span-full">
                            <label htmlFor="cartesearch" className="block text-sm font-medium leading-6 text-gray-900">
                                Carte d'identité <span className='text-red-500'>*</span>
                            </label>
                            <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                                <div className="text-center">
                                    <div className="mt-4 flex text-sm leading-6 text-gray-600">
                                        <label
                                            htmlFor="file-upload-search"
                                            className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                                        >
                                            <span>Glisser un fichier</span>
                                            <input required onChange={handleFileChangeIdentite} id="file-upload-search" name="file-upload-search" type="file" className="sr-only" />
                                        </label>
                                    </div>
                                    <p className="text-xs leading-5 text-gray-600">Max 10MB</p>
                                </div>
                            </div>
                            <p className="text-xs leading-5 text-black font-bold">{selectedFileIdentity ? selectedFileIdentity.name : ("")}</p>
                        </div>
                        <br />
                        <Button variant="gradient" type="submit" className="rounded-full bg-green-700">
                            S'enregistrer
                        </Button>
                    </form>
                </>
            ) : ("")}
        </div>
    );
}//detpt, commune, arrondissement, village ou quartiers et adresse(carré et autre), ajouter capital sociale(étape deux), nbre employer (étape 3), donner la possibilité d'enregistrer les associées selon si l'entreprise existe