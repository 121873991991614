import React from 'react';
import Banniere from './Components/banniere';
import Guide from './Components/guide';
import Evenement from './Components/Evenement';
import Actualite from './Components/Actualite';
import FAQ_home from './Components/FAQ_home';
import Counter from './Components/Counter';
import Partenaires from './Components/Partenaires';
import Quisommesnous from './Components/Quisommesnous';

function homepage(props) {
    return (
        <>
            <Banniere />
            <Partenaires />
            <Actualite />
            <br/>
            <Guide />
            <Quisommesnous />
            {/* <Evenement /> */}
            {/* <Counter /> */}
            {/* <FAQ_home /> */}
            
        </>
    );
}

export default homepage;