import React from 'react';
import Banniere from '../../default_pages/banniere/Banniere';
import Description from './Components/Description';

function JeunesForm(props) {
    return (
        <>
            <Banniere desc="FORMULAIRE DES 250 JEUNES" name="Formulaire des 250 jeunes" />
            <Description />
        </>
    );
}

export default JeunesForm;