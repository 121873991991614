import React from 'react';
import Banniere from '../../default_pages/banniere/Banniere';
import TabsComponentActualite from './Components/Tabs';

function Actualites(props) {
    return (
        <>
            <div className='bg-[#E3F2E5]'>
                <Banniere desc1="Actualités" desc2="& Informations" name="Actualite" />
                <TabsComponentActualite />
            </div>
        </>
    );
}

export default Actualites;