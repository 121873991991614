import React,{useState, useEffect} from 'react';
import { Dropdown } from 'flowbite-react';
import axios from 'axios';
import EventCard from '../../../default_components/EventCard/EventCard';
import { Select, Option } from "@material-tailwind/react";
import { Button } from "@material-tailwind/react";

const urlstandart = process.env.REACT_APP_URL_STANDART

function Evenement(props) {
    const [events, setEvents] = React.useState([]);
    const [event, setEvent] = React.useState([]);
    const [themes, setThemes] = React.useState([]);
    const [langues, setLangues] = useState([]);
    const [value, setValue] = useState('Tous');
    const [types, setTypes] = useState([])
    const [orgs, setOrgs] = useState([])
    const [selectedTheme, setSelectedTheme] = useState("");
    const [selectedType, setSelectedType] = useState("");
    const [selectedOrganisation, setSelectedOrganisation] = useState("");

    const handleChange = async (e, libel) => {
        setValue(e.target.value);
        const v = e.target.value
        if (libel == "theme") {
            setSelectedTheme(v)
            setSelectedType("")
            setSelectedOrganisation("")
            let tabevent = events.filter((ev) =>
                ev.themeEvenement.id == parseInt(e.target.value,10)
            );
            setEvent(tabevent);
        } else if (libel == "type") {
            setSelectedType(v)
            setSelectedTheme("")
            setSelectedOrganisation("")
            let tabevent = events.filter((ev) =>
                ev.typeEvenement.id == parseInt(e.target.value,10)
            );
            setEvent(tabevent);
        } else if (libel == "organisation") {
            setSelectedOrganisation(v)
            setSelectedTheme("")
            setSelectedType("")
            let tabevent = events.filter((ev) =>
                ev.organisation.id == parseInt(e.target.value,10)
            );
            setEvent(tabevent);
        }else{
            setSelectedOrganisation("")
            setSelectedTheme("")
            setSelectedType("")
        }
    };
    
    useEffect(() => {
        const newurl = process.env.REACT_APP_URL_STANDART + "api/evenements"
        axios.get(newurl, { withCredentials: true })
            .then(response => {
                console.log(response.data.data)
                setEvents(response.data.data.evenements)
            })
            .catch(err => {
                console.log('Erreur lors de la récupération des informations')
            })
        const newurltype = process.env.REACT_APP_URL_STANDART + "api/typeEvenements"
        axios.get(newurltype, { withCredentials: true })
            .then(response => {
                setTypes(response.data.data)
            })
            .catch(err => {
                console.log('Erreur lors de la récupération des informations')
            })
        const newurltheme = process.env.REACT_APP_URL_STANDART + "api/themeEvenements"
        axios.get(newurltheme, { withCredentials: true })
            .then(response => {
                setThemes(response.data.data)
            })
            .catch(err => {
                console.log('Erreur lors de la récupération des informations')
            })
        const newurllangue = process.env.REACT_APP_URL_STANDART + "api/langues"
        axios.get(newurllangue, { withCredentials: true })
            .then(response => {
                setLangues(response.data.data)
            })
            .catch(err => {
                console.log('Erreur lors de la récupération des informations')
            })
        const newurlorg = process.env.REACT_APP_URL_STANDART + "api/organisations"
        axios.get(newurlorg, { withCredentials: true })
            .then(response => {
                setOrgs(response.data.data)
            })
            .catch(err => {
                console.log('Erreur lors de la récupération des informations')
            })
    }, [])

    return (
        <>
            <div className="lg:py-10 font-['Montserrat']">
                <div className="grid grid-rows-2 pb-14 gap-8 max-md:pb-16 max-md:flex max-md:flex-col">
                    <div className="font-bold max-md:text-xl text-4xl text-neutral-900">
                        Les moments à ne pas rater
                    </div>
                    <div className="flex w-full flex-row gap-4 max-md:w-auto max-md:flex max-md:flex-col">
                        <Button variant="outlined" className="text-2xs bg-white rounded-full border font-semibold border-gray-300 text-green-700" onClick={(e) => handleChange(e, "Tous")} value="Tous">
                            Tous
                        </Button>
                        <select value={selectedTheme} onChange={(e) => handleChange(e,"theme")} className='outline-none text-3xs bg-white rounded-full border font-semibold border-gray-300 text-green-700 min-w-10 w-auto'>
                            <option disabled selected value="">Thèmes</option>
                            {themes.map((th)=>(
                                <option value={th.id}>{th.theme}</option>
                            ))}
                        </select>
                        <select value={selectedType} onChange={(e) => handleChange(e,"type")} className='outline-none text-3xs bg-white rounded-full border font-semibold border-gray-300 text-green-700 min-w-10 w-auto'>
                            <option disabled selected value="">Types</option>
                            {types.map((ty) => (
                                <option value={ty.id}>{ty.libelleType}</option>
                            ))}
                        </select>
                        <select value={selectedOrganisation} onChange={(e) => handleChange(e,"organisation")} className='outline-none text-3xs bg-white rounded-full border font-semibold border-gray-300 text-green-700 min-w-10 w-auto'>
                            <option disabled selected value="">Organisateurs</option>
                            {orgs.map((og) => (
                                <option value={og.id}>{og.name}</option>
                            ))}
                        </select>
                        {/* <select className='outline-none bg-white rounded-full border font-semibold border-gray-300 text-green-700 min-w-10 w-auto'>
                            <option disabled>Coût</option>
                            <option value="">Gratuit</option>
                            <option value="">Payant</option>
                        </select> */}
                    </div>
                </div>
                <div className='grid grid-cols-3 gap-4 max-md:flex max-md:flex-col max-md:px-4'>
                    {value == "Tous" ?
                        events.map((el) => {
                            let imginfo = el.actualite.image
                            let tabimg = []
                            tabimg = imginfo.split(',')
                            imginfo = tabimg[0]
                            imginfo = imginfo.replace(/\\/g, "/")

                            const dn = new Date(el.startDate)
                            const startdate = dn.toISOString().split('T')[0]
                            const df = new Date(el.endDate)
                            const enddate = df.toISOString().split('T')[0]
                            return (
                                <EventCard imgevent={imginfo} date={startdate} type={el.typeEvenement.libelleType} titre={el.actualite.title} lien={`/details-evenement/${el.actualite.title}`} />
                            )
                        }
                        ) : 
                        event.map((el) => {
                            let imginfo = el.actualite.image
                            let tabimg = []
                            tabimg = imginfo.split(',')
                            imginfo = tabimg[0]
                            imginfo = imginfo.replace(/\\/g, "/")

                            const dn = new Date(el.startDate)
                            const startdate = dn.toISOString().split('T')[0]
                            const df = new Date(el.endDate)
                            const enddate = df.toISOString().split('T')[0]
                            return (
                                <EventCard imgevent={imginfo} date={startdate} type={el.typeEvenement.libelleType} titre={el.actualite.title} lien={`/details-evenement/${el.actualite.title}`} />
                            )
                        })
                        }
                </div>
            </div>
        </>
    );
}

export default Evenement;