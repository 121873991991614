import React from 'react';
import BanGuide from './Components/BanGuide';
import LesGuides from './Components/LesGuides';
import Banniere from '../../default_pages/banniere/Banniere';

function Guide(props) {
    return (
        <>
            <Banniere desc1="Nos" desc2="Guides" />
            <LesGuides />
        </>
    );
}

export default Guide;