import React from 'react';
import Banniere from '../../default_pages/banniere/Banniere'
import LesServicesNonFinancier from './Components/LesServicesNonFinancier';
import LesServicesFinancier from './Components/LesServicesFinancier';
import TabsComponentsService from './Components/TabsComponentsService';

function Service(props) {
    return (
        <>
            <div className='bg-[#E3F2E5]'>
                <Banniere desc1="E-services" desc2="& Autres détails" />
                <TabsComponentsService />
            </div>
        </>
    );
}

export default Service;