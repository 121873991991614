import React, { useEffect, useState } from "react";
import { RecrutementsListe } from "../../../../NoSQL";
import { Link } from "react-router-dom";
import { Modal } from "flowbite-react";
import axios from "axios";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { Button } from "@material-tailwind/react";
// Core viewer
import { Viewer } from "@react-pdf-viewer/core";
import { Worker } from "@react-pdf-viewer/core";
// Plugins
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";

// Import styles
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

// pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const urlstandart = process.env.REACT_APP_URL_STANDART;

function Description(props) {
  // Create new plugin instance
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  const [openModal, setOpenModal] = useState(false);
  const [offre, setOffre] = useState([]);

  useEffect(() => {
    const newurl = process.env.REACT_APP_URL_STANDART + "api/recrutements";
    axios
      .get(newurl, { withCredentials: true })
      .then((response) => {
        let tabevent = response.data.data.recrutements.filter(
          (ev) => ev.poste == props.name
        );
        setOffre(tabevent);
      })
      .catch((err) => {
        alert("Erreur lors de la récupération des informations");
      });
  }, []);
  if (offre.length === 0) {
    return <div>Aucun recrutement trouvé avec le titre {props.name}</div>;
  }

  const uneOffre = offre[0];

  const dn = new Date(uneOffre.startDate);
  const startdate = dn.toISOString().split("T")[0];
  const df = new Date(uneOffre.endDate);
  const enddate = df.toISOString().split("T")[0];

  const today = new Date();
  const eventDate = new Date(uneOffre.endDate);

  const isDatePassed = today > eventDate;

  let resinfo = uneOffre.document;
  let tabres = [];
  tabres = resinfo.split(",");
  resinfo = tabres[0];
  resinfo = resinfo.replace(/\\/g, "/");
  return (
    <>
      <div className="max-xl:px-14 px-14 mx-auto max-w-screen-xl text-left py-24 lg:py-15 sm:py-15">
        <div className="mt-5">
          <Link
            to={
              uneOffre.typeDeRecru === "PERSON"
                ? "/recrutement/recrutementperson"
                : uneOffre.typeDeRecru === "MPME"
                  ? "/recrutement/selectionmpme"
                  : ""
            }
          >
            <Button className="bg-green-700 text-white flex items-center gap-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                class="h-5 w-5"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18"
                />
              </svg>
              Retour
            </Button>
          </Link>
        </div>
        <br />
        <div className="max-md:flex max-md:flex-col flex gap-2">
          <div className="basis-1/2">
            <div className="mb-10">
              <span className="text-xl font-bold">
                {" "}
                DESCRIPTION{" "}
                {uneOffre.typeDeRecru === "MPME" ||
                uneOffre.typeDeRecru === "SAE"
                  ? "DE L'OFFRE"
                  : "DU POSTE"}
              </span>
            </div>
            <div>
              <div className="flex flex-col">
                <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                    <div className="overflow-hidden">
                      <table className="min-w-full text-left text-sm font-light">
                        <tbody>
                          <tr className="border-b bg-green-100 dark:border-amber-500 dark:bg-amber-700">
                            <td className="whitespace-nowrap px-6 py-4">
                              {uneOffre.typeDeRecru == "MPME" ||
                              uneOffre.typeDeRecru == "SAE"
                                ? "OFFRE"
                                : "POSTE"}
                            </td>
                            <td className="whitespace-nowrap px-6 py-4">
                              {uneOffre.poste}
                            </td>
                          </tr>
                          <tr className="border-b bg-white dark:border-amber-500 dark:bg-amber-600">
                            <td className="whitespace-nowrap px-6 py-4">
                              {uneOffre.typeDeRecru == "MPME" ||
                              uneOffre.typeDeRecru == "SAE"
                                ? "CIBLE"
                                : "TYPE D'EMPLOI"}
                            </td>
                            <td className="whitespace-nowrap px-6 py-4">
                              {uneOffre.typeEmploi}
                            </td>
                          </tr>
                          <tr className="border-b bg-green-100 dark:border-amber-500 dark:bg-amber-600">
                            <td className="whitespace-nowrap px-6 py-4">
                              SPECIALITE
                            </td>
                            <td className="whitespace-nowrap px-6 py-4">
                              {uneOffre.specialite.libelleSpecialite}
                            </td>
                          </tr>
                          {uneOffre.typeDeRecru == "MPME" ||
                          uneOffre.typeDeRecru == "SAE" ? (
                            ""
                          ) : (
                            <>
                              <tr className="border-b bg-white dark:border-amber-500 dark:bg-amber-600">
                                <td className="whitespace-nowrap px-6 py-4">
                                  LIEU
                                </td>
                                <td className="whitespace-nowrap px-6 py-4">
                                  {uneOffre.lieu}
                                </td>
                              </tr>
                              <tr className="border-b bg-green-100 dark:border-amber-500 dark:bg-amber-600">
                                <td className="whitespace-nowrap px-6 py-4">
                                  REGION
                                </td>
                                <td className="whitespace-nowrap px-6 py-4">
                                  {uneOffre.region}
                                </td>
                              </tr>
                              <tr className="border-b bg-white dark:border-amber-500 dark:bg-amber-600">
                                <td className="whitespace-nowrap px-6 py-4">
                                  DESCRIPTION DU SALAIRE
                                </td>
                                <td className="whitespace-nowrap px-6 py-4">
                                  {uneOffre.salaire}
                                </td>
                              </tr>
                            </>
                          )}

                          <tr className="border-b bg-green-100 dark:border-amber-500 dark:bg-amber-600">
                            <td className="whitespace-nowrap px-6 py-4">
                              PUBLIE LE
                            </td>
                            <td className="whitespace-nowrap px-6 py-4">
                              {startdate}
                            </td>
                          </tr>
                          <tr className="border-b bg-white dark:border-amber-500 dark:bg-amber-600">
                            <td className="whitespace-nowrap px-6 py-4">
                              DATE FIN
                            </td>
                            <td className="whitespace-nowrap px-6 py-4">
                              {enddate}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="mt-5 flex gap-2">
                  <Button
                    onClick={() => setOpenModal(true)}
                    variant="outlined"
                    className="rounded-full bg-green-700 text-white border-green-700"
                  >
                    Consulter le document
                  </Button>
                  <div>
                    {isDatePassed ? (
                      <>
                        <Button
                          variant="outlined"
                          disabled
                          className="rounded-full text-green-700 border-green-700 w-full"
                        >
                          Postuler
                        </Button>
                      </>
                    ) : (
                      <>
                        <a href={`/postuler-recrutement/${uneOffre.poste}`}>
                          <Button
                            variant="outlined"
                            className="rounded-full text-green-700 border-green-700 w-full"
                          >
                            Postuler
                          </Button>
                        </a>
                      </>
                    )}
                  </div>
                  <Modal
                    show={openModal}
                    size="7xl"
                    popup
                    onClose={() => setOpenModal(false)}
                    position="center"
                  >
                    <Modal.Header>Document Viewer</Modal.Header>
                    <Modal.Body>
                      <div style={{ width: "100%", height: "100%" }}>
                        {/* <Document file={`${urlstandart}api/${resinfo}`} style={{ width: '100%', height: '100%' }}>
                                        <Page pageNumber={1} scale={1.5}/>
                                    </Document> */}
                        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                          <Viewer
                            fileUrl={`${urlstandart}api/${resinfo}`}
                            plugins={[
                              // Register plugins
                              defaultLayoutPluginInstance,
                            ]}
                          />
                        </Worker>
                      </div>
                    </Modal.Body>
                  </Modal>
                </div>
              </div>
            </div>
          </div>
          <div className="basis-1/2">
            <div className="mb-10">
              <span className="text-xl font-bold">
                INFORMATIONS COMPLÉMENTAIRES
              </span>
            </div>
            <div>
              <div className="prose prose-xl text-justify w-full mt-5 max-w-none text-black">
                {uneOffre.subtitle}
                <ReactMarkdown
                  className="w-full max-w-none"
                  remarkPlugins={[remarkGfm]}
                >
                  {uneOffre.description}
                </ReactMarkdown>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Description;
