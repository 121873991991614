import React, {useEffect} from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import {
    Tabs,
    TabsHeader,
    TabsBody,
    Tab,
    TabPanel,
} from "@material-tailwind/react";
import RendezVous from './RendezVous';
import FoireAuxQuestions from './FoireAuxQuestions';
import ContactMail from './ContactMail'
import Plainte from './Plainte';

function TabsComponentsContact(props) {
    const { id } = useParams()
    const [activeTab, setActiveTab] = React.useState(id || "plainte");
    const nav = useNavigate()

    const handleTabClick = (value) => {
        setActiveTab(value);
        
        nav(`./${value}`);
    };

    useEffect(() => {
        
        nav(`/contact`);
    }, [id]);
    const data = [
        // {
        //     label: "PRENDRE RENDEZ-VOUS",
        //     value: "rendezvous",
        //     desc: <RendezVous />,
        //     disabledTabs: true,
        // },
        {
            label: "PORTER PLAINTE",
            value: "plainte",
            desc: <Plainte />,
        },
        {
            label: "FOIRE AUX QUESTIONS",
            value: "questions",
            desc: <FoireAuxQuestions />,
        },
        {
            label: "MAIL ET CONTACT",
            value: "contact",
            desc: <ContactMail />,
        },
    ];
    return (
        <>
            <div className="border-b border-blue-gray-50 font-[' Montserrat']">
                <Tabs value={activeTab}>
                    <TabsHeader
                        className="rounded-none bg-white w-full lg:pr-60 lg:pl-32"
                        indicatorProps={{
                            className:
                                "bg-transparent border-b-2 border-yellow-300 shadow-none rounded-none p-7 w-full",
                        }}
                    >
                        {data.map(({ label, value, disabledTabs }) => (
                            <Tab
                                disabled={disabledTabs ? true : false}
                                key={value}
                                value={value}
                                onClick={() => handleTabClick(value)}
                                className={activeTab === value ? "text-gray-900 font-semibold p-7 w-[20%] font-[' Montserrat'] max-md:w-[30%]" : "font-[' Montserrat'] max-md:w-[30%] p-7 w-[20%] font-semibold"}
                            >
                                <span className='max-md:text-[12px]'>{label}</span>
                            </Tab>
                        ))}
                    </TabsHeader>
                    <TabsBody className='lg:pr-72 lg:pl-32'>
                        {data.map(({ value, desc }) => (
                            <TabPanel key={value} value={value}>
                                {desc}
                            </TabPanel>
                        ))}
                    </TabsBody>
                </Tabs>
            </div>
        </>
    );
}

export default TabsComponentsContact;