import React, { useState, useEffect } from 'react';
import { ActualitePrincipal } from '../../../NoSQL';
import { TECarousel, TECarouselItem } from "tw-elements-react";
import axios from 'axios'
import TopActualiteCard from '../../../default_components/TopActualiteComponent/TopActualiteCard';

const urlstandart = process.env.REACT_APP_URL_STANDART

function Actualite(props) {
    const [actus, setActus] = useState([])
    const [imginfoevent, setImgInfoEvent] = useState('')
    const [imginfoprojet, setImgInfoProjet] = useState('')
    const [imginfocom, setImgInfoCom] = useState('')

    useEffect(() => {
        const fetchData = async () => {
            try {
                const eventsResponse = await axios.get(process.env.REACT_APP_URL_STANDART + 'api/evenements', { withCredentials: true });
                const projetsResponse = await axios.get(process.env.REACT_APP_URL_STANDART + 'api/appelAProjets', { withCredentials: true });
                const comsResponse = await axios.get(process.env.REACT_APP_URL_STANDART + 'api/communiques', { withCredentials: true });

                let lastEvent = eventsResponse.data.data.evenements[0];
                if (lastEvent)
                {
                    let imginfoevents = lastEvent.actualite.image
                    let tabimg = []
                    tabimg = imginfoevents.split(',')
                    imginfoevents = tabimg[0]
                    imginfoevents = imginfoevents.replace(/\\/g, "/")
                    setImgInfoEvent(imginfoevents)
                    lastEvent = lastEvent.actualite
                }

                // const dn = new Date(lastEvent.startDate)
                // const startdate = dn.toISOString().split('T')[0]
                // const df = new Date(lastEvent.endDate)
                // const enddate = df.toISOString().split('T')[0]
                
                let lastProjet = projetsResponse.data.data.appelAProjets[0];
                if (lastProjet)
                {
                    let imginfoprojets = lastProjet.actualite.image
                    let tabimgpr = []
                    tabimgpr = imginfoprojets.split(',')
                    imginfoprojets = tabimgpr[0]
                    imginfoprojets = imginfoprojets.replace(/\\/g, "/")
                    setImgInfoProjet(imginfoprojets)
                    lastProjet = lastProjet.actualite
                }

                // const dn = new Date(lastEvent.startDate)
                // const startdate = dn.toISOString().split('T')[0]
                // const df = new Date(lastEvent.endDate)
                // const enddate = df.toISOString().split('T')[0]

                let lastCom = comsResponse.data.data.communiques[0];
                if(lastCom)
                {
                    let imginfocoms = lastCom.actualite.image
                    let tabimgcom = []
                    tabimgcom = imginfocoms.split(',')
                    imginfocoms = tabimgcom[0]
                    imginfocoms = imginfocoms.replace(/\\/g, "/")
                    setImgInfoCom(imginfocoms)
                    lastCom = lastCom.actualite
                }
                
                let mytab = [lastEvent, lastProjet, lastCom]
                mytab = mytab.filter((element) => element !== undefined);
                console.log(mytab)
                setActus(mytab);
            } catch (error) {
                console.error('Erreur lors de la récupération des informations', error);
            }
        };

        fetchData();
    }, [])
    console.log(actus)
    return (
        <section className="bg-[#E3F2E5] w-full">
            <div className='lg:px-44 py-24 max-md:px-4'>
                <div>
                    <span className="text-yellow-300 max-md:text-[20px] text-[26px] font-bold font-['Montserrat']">Top actualités</span>
                </div>
                <div>
                    <span className="text-black max-md:text-[26px] text-[36px] font-bold font-['Montserrat']">À la une de l'Agence</span>
                </div>
                <br/>
                <div className='max-md:flex-col max-md:flex grid grid-cols-3 gap-4'>
                    {actus.map((actualite, index)=>(
                        <div key={index}>
                            <TopActualiteCard titre={actualite.title} imgevent={imginfoevent} imgprojet={imginfoprojet} imgcom={imginfocom} date={actualite.createdAt} etiquette={actualite.etiquette} />
                        </div>
                    ))}
                </div>
                <div className='flex justify-center text-center'>
                    <a href={`/actualites`}>
                        <div className='flex flex-row items-center pt-5 font-bold text-green-700 cursor-pointer text-xl'>
                            <span>Voir toutes les actualités</span>
                            <svg className="w-3 h-3 ms-2.5 rtl:rotate-[270deg]" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 11v4.833A1.166 1.166 0 0 1 13.833 17H2.167A1.167 1.167 0 0 1 1 15.833V4.167A1.166 1.166 0 0 1 2.167 3h4.618m4.447-2H17v5.768M9.111 8.889l7.778-7.778" />
                            </svg>
                        </div>
                    </a>
                </div>
            </div>
        </section>
    );
}

export default Actualite;