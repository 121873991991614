import React from 'react';

function Presentation(props) {
    return (
        <>
            <section className="bg-white dark:bg-gray-900 max-md:px-4 p-28">
                <div className='grid grid-cols-2 max-xl:flex max-xl:flex-col'>
                    <div className='flex text-left'>
                        <div>
                            <h2 className="text-neutral-900 max-md:text-[25px] text-[32px] font-semibold font-['Montserrat']">Présentation de l'ADPME</h2>
                        </div>
                    </div>
                    <div>
                        <div className="text-[24px] max-md:text-[17px] font-[' Montserrat']">
                            <p className="text-justify font-['Montserrat']">
                                L'Agence de Développement des Petites et Moyennes Entreprises
                                a pour vocation <span className='text-green-700 font-bold'>de fédérer et de mettre en cohérence
                                    l'ensemble des interventions publiques en appui aux MPME
                                    avec une offre intégrée d'accompagnement, d'orientation et de financement des MPME</span>.
                            </p>

                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Presentation;