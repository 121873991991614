import React from 'react';
import DescBannniereApropos from './Components/DescBannniereApropos';
import Presentation from './Components/Presentation';
import Vision from './Components/Vision';
import Missions from './Components/Missions';
import Valeurs from './Components/Valeurs';
import Ambitions from './Components/Ambitions';
import Priorites from './Components/Priorites';

function Quisommesnous(props) {
    return (
        <>
            <DescBannniereApropos />
            {/* <SectionDG /> */}
            <Presentation />
            <Vision />
            <Missions />
            <Valeurs />
            <Ambitions />
            <Priorites />
            {/* <Teams /> */}
        </>
    );
}

export default Quisommesnous;