import React, {useEffect, useState} from 'react';
import ReactMarkdown from 'react-markdown';
import 'tailwindcss/tailwind.css';
import remarkGfm from 'remark-gfm'
import { Button } from "@material-tailwind/react";
import journalisteimg from '../Images/journaliste.png'
import axios from 'axios'
// import { Document, Page } from 'react-pdf'
// import { pdfjs } from 'react-pdf';
import {Modal} from 'flowbite-react'

// Core viewer
import { Viewer } from '@react-pdf-viewer/core';
import { Worker } from '@react-pdf-viewer/core';
// Plugins
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

// Import styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

// pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const urlstandart = process.env.REACT_APP_URL_STANDART

function Description(props) {
    // Create new plugin instance
    const defaultLayoutPluginInstance = defaultLayoutPlugin();

    const [openModal, setOpenModal] = useState(false);
    const [communique, setCommunique] = useState([]);

    useEffect(() => {
        const newurl = process.env.REACT_APP_URL_STANDART + "api/communiques"
        axios.get(newurl, { withCredentials: true })
            .then(response => {
                let tabcom = response.data.data.communiques.filter((tc) =>
                    tc.actualite.title == props.name
                );
                setCommunique(tabcom);
            })
            .catch(err => {
                alert('Erreur lors de la récupération des informations')
            })
    }, [])

    if (communique.length === 0) {
        return <div>Aucun communiqué trouvé avec le titre {props.name}</div>;
    }
    return (
        <>
        {communique ? 
        communique.map((com)=>{
            let imginfo = com.actualite.image
            let tabimg = []
            tabimg = imginfo.split(',')
            imginfo = tabimg[0]
            imginfo = imginfo.replace(/\\/g, "/")

            let resinfo = com.ressource
            let tabres = []
            tabres = resinfo.split(',')
            resinfo = tabres[0]
            resinfo = resinfo.replace(/\\/g, "/")

            const dn = new Date(com.createdAt)
            const startdate = dn.toISOString().split('T')[0]
            return (
                <>
                    <div className="bg-[#E3F2E5] font-[' Montserrat']">
                        <div className='lg:px-44 max-md:px-4 lg:py-10'>
                            <ol className='inline-flex max-md:flex max-md:flex-col items-center space-x-1 md:space-x-2 mb-5'>
                                <li className="inline-flex items-center">
                                    <a href="/accueil" className="inline-flex max-md:text-sm items-center text-3sm font-normal text-black hover:text-amber-600 dark:text-white dark:hover:text-white">
                                        <svg className="w-3 h-3 me-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                            <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
                                        </svg>
                                        Home
                                    </a>
                                </li>
                                <li aria-current="page">
                                    <div className="flex items-center">
                                        <svg className="rtl:rotate-180 w-3 h-3 text-black mx-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4" />
                                        </svg>
                                        <span className="ms-1 max-md:text-sm text-3sm font-normal text-black md:ms-2 dark:text-white">Communiqué</span>
                                    </div>
                                </li>
                                <li aria-current="page">
                                    <div className="flex items-center">
                                        <svg className="rtl:rotate-180 w-3 h-3 text-black mx-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4" />
                                        </svg>
                                        <span className="ms-1 max-md:text-sm text-3sm font-normal text-black md:ms-2 dark:text-white">{props.name}</span>
                                    </div>
                                </li>
                            </ol>
                            <div className='text-4xl max-md:text-xl font-bold text-neutral-900 pb-6'>
                                {props.name}
                            </div>
                            <div>
                                <img crossOrigin="anonymous" src={`${urlstandart}api/${imginfo}`} className='w-full' />
                            </div>
                            
                        </div>
                    </div>
                    <div className="font-[' Montserrat'] max-md:px-4 lg:px-44 lg:py-10 pb-5">
                        <div className="inline-flex max-md:flex max-md:flex-col max-md:gap-4 gap-7 font-[' Montserrat'] font-semibold pt-3 pb-5">
                            <div className='inline-flex'>
                                <svg
                                    fill="#000000"
                                    width={21} height={21}
                                    viewBox="0 0 1024 1024"
                                    xmlns="http://www.w3.org/2000/svg"

                                >
                                    <path d="M960 95.888l-256.224.001V32.113c0-17.68-14.32-32-32-32s-32 14.32-32 32v63.76h-256v-63.76c0-17.68-14.32-32-32-32s-32 14.32-32 32v63.76H64c-35.344 0-64 28.656-64 64v800c0 35.343 28.656 64 64 64h896c35.344 0 64-28.657 64-64v-800c0-35.329-28.656-63.985-64-63.985zm0 863.985H64v-800h255.776v32.24c0 17.679 14.32 32 32 32s32-14.321 32-32v-32.224h256v32.24c0 17.68 14.32 32 32 32s32-14.32 32-32v-32.24H960v799.984zM736 511.888h64c17.664 0 32-14.336 32-32v-64c0-17.664-14.336-32-32-32h-64c-17.664 0-32 14.336-32 32v64c0 17.664 14.336 32 32 32zm0 255.984h64c17.664 0 32-14.32 32-32v-64c0-17.664-14.336-32-32-32h-64c-17.664 0-32 14.336-32 32v64c0 17.696 14.336 32 32 32zm-192-128h-64c-17.664 0-32 14.336-32 32v64c0 17.68 14.336 32 32 32h64c17.664 0 32-14.32 32-32v-64c0-17.648-14.336-32-32-32zm0-255.984h-64c-17.664 0-32 14.336-32 32v64c0 17.664 14.336 32 32 32h64c17.664 0 32-14.336 32-32v-64c0-17.68-14.336-32-32-32zm-256 0h-64c-17.664 0-32 14.336-32 32v64c0 17.664 14.336 32 32 32h64c17.664 0 32-14.336 32-32v-64c0-17.68-14.336-32-32-32zm0 255.984h-64c-17.664 0-32 14.336-32 32v64c0 17.68 14.336 32 32 32h64c17.664 0 32-14.32 32-32v-64c0-17.648-14.336-32-32-32z" />
                                </svg>
                                &nbsp;
                                <span className="text-[16px] font-[' Montserrat']">{startdate}</span>
                            </div><div className="max-md:hidden">|</div>
                            <div className='inline-flex'>
                                <img className="w-6 h-6" src={journalisteimg} />&nbsp;
                                <span className="text-[16px] font-[' Montserrat']">COMMUNIQUE</span>
                            </div>
                        </div>
                        <div className='text-4xl max-md:pb-3 max-md:text-xl font-bold text-neutral-900 pb-6'>
                            {com.actualite.subTitle}
                        </div>
                        <div className='mt-5'>
                            <div className="prose prose-xl font-[' Montserrat'] text-justify w-full mt-5 max-w-none text-black">
                                <ReactMarkdown className='w-full max-w-none' remarkPlugins={[remarkGfm]}>
                                    {/* <div dangerouslySetInnerHTML={{ __html: com.actualite.description }} /> */}
                                    {com.actualite.description}
                                </ReactMarkdown>
                            </div>
                        </div>
                        <div className='mt-5'>
                            <div className='mt-5'>
                                <a>
                                    <Button onClick={() => setOpenModal(true)} variant="gradient" className="rounded-full bg-green-700 text-white flex items-center gap-2 justify-center text-center">
                                        Consulter le document
                                    </Button>
                                </a>
                                <Modal show={openModal} size="7xl" popup onClose={() => setOpenModal(false)} position="center">
                                    <Modal.Header>Document Viewer</Modal.Header>
                                    <Modal.Body>
                                        <div style={{ width: '100%', height: '100%' }}>
                                            {/* <Document file={`${urlstandart}api/${resinfo}`} style={{ width: '100%', height: '100%' }}>
                                        <Page pageNumber={1} scale={1.5}/>
                                    </Document> */}
                                            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                                                <Viewer
                                                    fileUrl={`${urlstandart}api/${resinfo}`}
                                                    plugins={[
                                                        // Register plugins
                                                        defaultLayoutPluginInstance
                                                    ]}
                                                />
                                            </Worker>
                                        </div>
                                    </Modal.Body>
                                </Modal>

                            </div>
                        </div>
                    </div>
                    
                </>
            )
        }) : ("")}
            
        </>
    );
}

export default Description;