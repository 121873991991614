import React,{useState, useEffect} from 'react';
import { GuidesListe } from '../../../NoSQL';
import axios from 'axios'
import GuideCard from '../../../default_components/GuideCard/GuideCard';
import fontvert from '../Images/fondlogovert.jpg'

const urlstandart = process.env.REACT_APP_URL_STANDART

function Guide(props) {
    const [guides, setGuides] = useState([])
    
    useEffect(() => {
        const newurl = process.env.REACT_APP_URL_STANDART + "api/guides"
        axios.get(newurl, { withCredentials: true })
            .then(response => {
                setGuides(response.data.data)
            })
            .catch(err => {
                alert('Erreur lors de la récupération des informations')
            })
    }, [])
    return (
        <>
            <section style={{ backgroundImage: `url(${fontvert})` }} className="dark:bg-gray-900">
                <div className='text-center lg:px-44 py-24 max-md:text-left max-md:px-4'>
                    {/* <h3 className="text-left pb-5">
                        <span className="text-3xl font-bold dark:text-white mr-5">Guide</span>
                        <a className='hover:underline inline-flex text-amber-700 items-center' href="/guide">
                            <span>voir tout</span>
                            <svg className="w-3 h-3 ms-2.5 rtl:rotate-[270deg]" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 11v4.833A1.166 1.166 0 0 1 13.833 17H2.167A1.167 1.167 0 0 1 1 15.833V4.167A1.166 1.166 0 0 1 2.167 3h4.618m4.447-2H17v5.768M9.111 8.889l7.778-7.778" />
                            </svg>
                        </a>
                    </h3> */}
                    <div>
                        <span className="text-neutral-900 max-md:text-[26px] text-[36px] font-semibold font-['Montserrat']">Présentation des guides</span>
                    </div>
                    <div>
                        <span className="text-neutral-900 max-md:text-[16px] text-[20px] font-normal font-['Montserrat']">Quelques conseils pour vous aider</span>
                    </div>
                    <br />
                    <div className="grid grid-cols-3 gap-4 text-left max-md:flex max-md:flex-col">
                    {guides.length <= 0 ? (
                            <div role="status" className="max-w-md p-4 space-y-4 border border-gray-200 divide-y divide-gray-200 rounded shadow animate-pulse dark:divide-gray-700 md:p-6 dark:border-gray-700">
                                <div className="flex items-center justify-between">
                                    <div>
                                        <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
                                        <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                                    </div>
                                    <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
                                </div>
                                <span className="sr-only">Loading...</span>
                            </div>
                    ) : 
                    guides.map((guide,index)=>{
                        let imginfo = guide.icon
                        let tabimg = []
                        tabimg = imginfo.split(',')
                        imginfo = tabimg[0]
                        imginfo = imginfo.replace(/\\/g, "/")

                        let resinfo = guide.ressource
                        let tabres = []
                        tabres = resinfo.split(',')
                        resinfo = tabres[0]
                        resinfo = resinfo.replace(/\\/g, "/")
                        return (
                            <div key={index}>
                                <GuideCard id={guide.id} mode="accueil" title={guide.title} doc={resinfo} img={`${urlstandart}api/${imginfo}`} />
                            </div>
                        )
                    })
                    }
                        
                    </div>
                    <div className='flex justify-center text-center'>
                        <a href={`/guide`}>
                            <div className='flex flex-row items-center pt-5 font-bold text-green-700 cursor-pointer text-xl'>
                                <span>Voir tous les guides</span>
                                <svg className="w-3 h-3 ms-2.5 rtl:rotate-[270deg]" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 11v4.833A1.166 1.166 0 0 1 13.833 17H2.167A1.167 1.167 0 0 1 1 15.833V4.167A1.166 1.166 0 0 1 2.167 3h4.618m4.447-2H17v5.768M9.111 8.889l7.778-7.778" />
                                </svg>
                            </div>
                        </a>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Guide;