import React from 'react';
import logo from './Images/logoadpme_a.png'

function footer() {
    return (
        <footer className="bg-white dark:bg-gray-800 max-md:flex max-md:flex-col max-md:justify-center max-md:items-center">
            {/* <div className="max-w-screen-xl p-4 py-6 mx-auto lg:py-16 md:p-8 lg:p-10">
                <div>
                    <a href="/accueil" className="flex items-center justify-center mb-5">
                        <img src={logo} className="w-16 md:w-32 lg:w-80 max-md:w-64" alt="ADPME Logo" />
                    </a>
                </div>
                <hr className="my-6 border-black sm:mx-auto dark:border-black lg:my-8" />
                <div className="text-center">
                    <span className="block text-sm text-center text-black dark:text-white">©2024 ADPME.
                    </span>
                    <ul className="flex justify-center mt-5 space-x-5">
                        <li>
                            <a target='_blank' href="https://www.facebook.com/adpmebenin?mibextid=ViGcVu" className="text-gray-500 hover:text-gray-900 dark:hover:text-white dark:text-gray-400">
                                <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true"><path fillRule="evenodd" d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" clipRule="evenodd" /></svg>
                            </a>
                        </li>
                        <li>
                            <a target='_blank' href="https://www.linkedin.com/company/adpme-benin/" className="text-gray-500 hover:text-gray-900 dark:hover:text-white dark:text-gray-400">
                                <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0,0,256,256"
                                    style={{fill:"#737373"}}>
                                    <g fill="#737373" fillRule="nonzero" stroke="none" strokeWidth="1" strokeLinecap="butt" strokeLinejoin="miter" strokeMiterlimit="10" strokeDasharray="" strokeDashoffset="0" style={{mixBlendMode: "normal"}}><g transform="scale(8.53333,8.53333)"><path d="M24,4h-18c-1.105,0 -2,0.895 -2,2v18c0,1.105 0.895,2 2,2h18c1.105,0 2,-0.895 2,-2v-18c0,-1.105 -0.895,-2 -2,-2zM10.954,22h-2.95v-9.492h2.95zM9.449,11.151c-0.951,0 -1.72,-0.771 -1.72,-1.72c0,-0.949 0.77,-1.719 1.72,-1.719c0.948,0 1.719,0.771 1.719,1.719c0,0.949 -0.771,1.72 -1.719,1.72zM22.004,22h-2.948v-4.616c0,-1.101 -0.02,-2.517 -1.533,-2.517c-1.535,0 -1.771,1.199 -1.771,2.437v4.696h-2.948v-9.492h2.83v1.297h0.04c0.394,-0.746 1.356,-1.533 2.791,-1.533c2.987,0 3.539,1.966 3.539,4.522z"></path></g></g>
                                </svg>
                            </a>
                        </li>
                        <li>
                            <a target='_blank' href="https://www.youtube.com/@adpmebenin" className="text-gray-500 hover:text-gray-900 dark:hover:text-white dark:text-gray-400">
                                <svg className="w-5 h-5 hover:text-text-gray-900" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 16 16" style={{ fill: "#737373" }}>
                                    <path d="M 8 2 C 5.960938 2 4.15625 2.210938 3.058594 2.375 C 2.160156 2.507813 1.445313 3.210938 1.28125 4.113281 C 1.140625 4.902344 1 6.054688 1 7.5 C 1 8.945313 1.140625 10.097656 1.28125 10.886719 C 1.445313 11.789063 2.160156 12.488281 3.058594 12.625 C 4.160156 12.789063 5.972656 13 8 13 C 10.027344 13 11.835938 12.789063 12.9375 12.625 L 12.941406 12.625 C 13.839844 12.492188 14.554688 11.789063 14.71875 10.886719 C 14.859375 10.09375 15 8.941406 15 7.5 C 15 6.054688 14.859375 4.902344 14.714844 4.113281 C 14.554688 3.210938 13.839844 2.507813 12.941406 2.375 C 11.84375 2.210938 10.039063 2 8 2 Z M 8 3 C 9.96875 3 11.730469 3.203125 12.792969 3.363281 C 13.261719 3.433594 13.640625 3.800781 13.730469 4.289063 C 13.863281 5.027344 14 6.121094 14 7.5 C 14 8.878906 13.863281 9.972656 13.734375 10.707031 C 13.644531 11.199219 13.265625 11.566406 12.792969 11.636719 C 11.722656 11.792969 9.957031 12 8 12 C 6.042969 12 4.273438 11.792969 3.207031 11.636719 C 2.738281 11.566406 2.355469 11.199219 2.265625 10.707031 C 2.136719 9.96875 2 8.878906 2 7.5 C 2 6.117188 2.136719 5.027344 2.265625 4.289063 C 2.355469 3.800781 2.734375 3.433594 3.203125 3.367188 L 3.207031 3.367188 C 4.269531 3.207031 6.03125 3 8 3 Z M 6 5 L 6 10 L 11 7.5 Z"></path>
                                </svg>
                            </a>
                        </li>
                    </ul>
                </div>
            </div> */}
            <div className="grid grid-cols-4 max-md:flex max-md:flex-col max-md:px-4 max-md:justify-center max-md:items-center px-28 pt-16 font-['Montserrat']">
                <div className='col-span-1 flex flex-col max-md:mb-5 max-md:flex max-md:flex-col max-md:justify-center max-md:items-center'>
                    <div>
                        <img src={logo} className="w-[160px] md:w-[160px] lg:w-[160px] max-md:w-[160px]" alt="ADPME Logo" />
                    </div>
                    <div className='text-gray-500 max-md:flex max-md:justify-center max-md:items-center p-3'>
                        "ADPME : Accompagner Votre Essor,<br/> Ensemble vers la Réussite Entrepreneuriale !"
                    </div>
                </div>
                <div className='grid grid-rows-7 max-md:mb-5 max-md:flex max-md:flex-col max-md:justify-center max-md:items-center'>
                    <div className='font-bold text-xl'>Liens utiles</div>
                    <div>
                        <a className='underline underline-offset-4 text-gray-500' href={`/accueil`}>Accueil</a>
                    </div>
                    <div>
                        <a className='underline underline-offset-4 text-gray-500' href={`/quisommesnous`}>Qui sommes nous</a>
                    </div>
                    <div>
                        <a className='underline underline-offset-4 text-gray-500' href={`/actualites`}>Actualités</a>
                    </div>
                    <div>
                        <a className='underline underline-offset-4 text-gray-500' href={`/guide`}>Guides</a>
                    </div>
                    <div>
                        <a className='underline underline-offset-4 text-gray-500' href={`/service`}>Services</a>
                    </div>
                    <div>
                        <a className='underline underline-offset-4 text-gray-500' href={`/recrutement`}>Opportunités</a>
                    </div>
                    <div>
                        <a className='underline underline-offset-4 text-gray-500' href={`/contact`}>Contact</a>
                    </div>
                </div>
                <div className='grid grid-rows-6 max-md:mb-5 max-md:flex max-md:flex-col max-md:justify-center max-md:items-center'>
                    <div className='font-bold text-xl'>Autres sites</div>
                    <div>
                        <a className='underline underline-offset-4 text-gray-500' target="_blank" href='https://www.gouv.bj/'>gouv.bj</a>
                    </div>
                    <div>
                        <a className='underline underline-offset-4 text-gray-500' target="_blank" href='https://pmepe.gouv.bj/'>pmepe.gouv.bj</a>
                    </div>
                    <div>
                        <a className='underline underline-offset-4 text-gray-500' target="_blank" href='https://anpe.bj/'>anpe.bj</a>
                    </div>
                    <div>
                        <a className='underline underline-offset-4 text-gray-500' target="_blank" href='https://www.cmabenin.bj/'>cmabenin.bj</a>
                    </div>
                    <div>
                        <a className='underline underline-offset-4 text-gray-500' target="_blank" href='https://fda.bj/'>fda.bj</a>
                    </div>
                    <div>
                        <a className='underline underline-offset-4 text-gray-500' target="_blank" href='https://semecity.bj/'>semecity.bj</a>
                    </div>
                </div>
                <div className='grid grid-rows-5 max-md:flex max-md:flex-col max-md:justify-center max-md:items-center'>
                    <div className='font-bold text-xl'>Contact</div>
                    <div className='text-gray-500'>+229 41 29 27 22</div>
                    <div className='text-gray-500'>contact@adpme.bj</div>
                    <div className='text-gray-500 max-md:flex max-md:justify-center'>Immeuble R+5 C/1184 Cadjèhoun 01 BP 8435 Cotonou</div>
                    <div>
                        <ul className="flex mt-5 lg:space-x-5">
                            <li>
                                <a target='_blank' href="https://www.facebook.com/adpmebenin?mibextid=ViGcVu" className="text-gray-500 hover:text-gray-900 dark:hover:text-white dark:text-gray-400">
                                    <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true"><path fillRule="evenodd" d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" clipRule="evenodd" /></svg>
                                </a>
                            </li>
                            <li>
                                <a target='_blank' href="https://www.linkedin.com/company/adpme-benin/" className="text-gray-500 hover:text-gray-900 dark:hover:text-white dark:text-gray-400">
                                    <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0,0,256,256"
                                        style={{ fill: "#737373" }}>
                                        <g fill="#737373" fillRule="nonzero" stroke="none" strokeWidth="1" strokeLinecap="butt" strokeLinejoin="miter" strokeMiterlimit="10" strokeDasharray="" strokeDashoffset="0" style={{ mixBlendMode: "normal" }}><g transform="scale(8.53333,8.53333)"><path d="M24,4h-18c-1.105,0 -2,0.895 -2,2v18c0,1.105 0.895,2 2,2h18c1.105,0 2,-0.895 2,-2v-18c0,-1.105 -0.895,-2 -2,-2zM10.954,22h-2.95v-9.492h2.95zM9.449,11.151c-0.951,0 -1.72,-0.771 -1.72,-1.72c0,-0.949 0.77,-1.719 1.72,-1.719c0.948,0 1.719,0.771 1.719,1.719c0,0.949 -0.771,1.72 -1.719,1.72zM22.004,22h-2.948v-4.616c0,-1.101 -0.02,-2.517 -1.533,-2.517c-1.535,0 -1.771,1.199 -1.771,2.437v4.696h-2.948v-9.492h2.83v1.297h0.04c0.394,-0.746 1.356,-1.533 2.791,-1.533c2.987,0 3.539,1.966 3.539,4.522z"></path></g></g>
                                    </svg>
                                </a>
                            </li>
                            <li>
                                <a target='_blank' href="https://www.youtube.com/@adpmebenin" className="text-gray-500 hover:text-gray-900 dark:hover:text-white dark:text-gray-400">
                                    <svg className="w-5 h-5 hover:text-text-gray-900" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 16 16" style={{ fill: "#737373" }}>
                                        <path d="M 8 2 C 5.960938 2 4.15625 2.210938 3.058594 2.375 C 2.160156 2.507813 1.445313 3.210938 1.28125 4.113281 C 1.140625 4.902344 1 6.054688 1 7.5 C 1 8.945313 1.140625 10.097656 1.28125 10.886719 C 1.445313 11.789063 2.160156 12.488281 3.058594 12.625 C 4.160156 12.789063 5.972656 13 8 13 C 10.027344 13 11.835938 12.789063 12.9375 12.625 L 12.941406 12.625 C 13.839844 12.492188 14.554688 11.789063 14.71875 10.886719 C 14.859375 10.09375 15 8.941406 15 7.5 C 15 6.054688 14.859375 4.902344 14.714844 4.113281 C 14.554688 3.210938 13.839844 2.507813 12.941406 2.375 C 11.84375 2.210938 10.039063 2 8 2 Z M 8 3 C 9.96875 3 11.730469 3.203125 12.792969 3.363281 C 13.261719 3.433594 13.640625 3.800781 13.730469 4.289063 C 13.863281 5.027344 14 6.121094 14 7.5 C 14 8.878906 13.863281 9.972656 13.734375 10.707031 C 13.644531 11.199219 13.265625 11.566406 12.792969 11.636719 C 11.722656 11.792969 9.957031 12 8 12 C 6.042969 12 4.273438 11.792969 3.207031 11.636719 C 2.738281 11.566406 2.355469 11.199219 2.265625 10.707031 C 2.136719 9.96875 2 8.878906 2 7.5 C 2 6.117188 2.136719 5.027344 2.265625 4.289063 C 2.355469 3.800781 2.734375 3.433594 3.203125 3.367188 L 3.207031 3.367188 C 4.269531 3.207031 6.03125 3 8 3 Z M 6 5 L 6 10 L 11 7.5 Z"></path>
                                    </svg>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className='px-10 py-10 text-center max-md:px-4'>
                <hr className="my-6 border-gray-300 sm:mx-auto dark:border-black lg:my-8" />
                <div className="text-center">
                    <span className="block text-3sm font-bold text-center text-neutral-900 dark:text-white">©2024 ADPME.
                    </span>
                </div>
            </div>
        </footer>
    );
}

export default footer;