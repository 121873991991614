import React, { useState, useEffect } from 'react';
import fond from '../Images/fond.png'
import { GuidesListe } from '../../../NoSQL';
import axios from 'axios'
import { Modal } from 'flowbite-react'
import GuideCard from '../../../default_components/GuideCard/GuideCard';

const urlstandart = process.env.REACT_APP_URL_STANDART

function LesGuides(props) {
    const [guides, setGuides] = useState([])
    useEffect(() => {
        const newurl = process.env.REACT_APP_URL_STANDART + "api/guides"
        axios.get(newurl, { withCredentials: true })
            .then(response => {
                setGuides(response.data.data)
            })
            .catch(err => {
                alert('Erreur lors de la récupération des informations')
            })
    }, [])
    return (
        <div className='max-xl:px-4 px-14 mx-auto max-w-screen-xl text-left py-24 lg:py-15 sm:py-15'>
            <div className="lg:grid lg:grid-cols-3 lg:gap-4 sm:flex sm:flex-col max-xl:space-y-2">
                {guides.length <= 0 ? (<span>Aucun guide pour le moment</span>) :
                    guides.map((guide,index) => {
                        let imginfo = guide.icon
                        let tabimg = []
                        tabimg = imginfo.split(',')
                        imginfo = tabimg[0]
                        imginfo = imginfo.replace(/\\/g, "/")

                        let resinfo = guide.ressource
                        let tabres = []
                        tabres = resinfo.split(',')
                        resinfo = tabres[0]
                        resinfo = resinfo.replace(/\\/g, "/")
                        return (
                            <div key={index}>
                                <GuideCard id={guide.id} title={guide.title} doc={resinfo} img={`${urlstandart}api/${imginfo}`} />
                            </div>
                        )
                    })
                }
            </div>
        </div>
    );
}

export default LesGuides;