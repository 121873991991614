import React, { useState, useEffect } from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { TypeEvent } from '../../../NoSQL';
import { EvenemntsLists } from '../../../NoSQL';
import EventImg from '../Images/actuessai.jpg'
import axios from 'axios'

const urlstandart = process.env.REACT_APP_URL_STANDART

function LesEvenements() {
    const [events, setEvents] = React.useState([]);
    const [event, setEvent] = React.useState([]);
    const [imgPath, setImgPath] = useState([]);
    const [value, setValue] = useState('tous');

    const handleChange = (e,libel) => {
        setValue(e.target.value);
        if(libel == "theme"){
            let tabevent = events.filter((ev) =>
                ev.themeEvenement.theme == e.target.value
            );
            setEvent(tabevent);
        }else if(libel == "type")
        {
            let tabevent = events.filter((ev) =>
                ev.typeEvenement.codeType == e.target.value
            );
            setEvent(tabevent);
        }
    };
    useEffect(() => {
        const newurl = process.env.REACT_APP_URL_STANDART + "api/evenements"
        axios.get(newurl, { withCredentials: true })
            .then(response => {
                setEvents(response.data.data.evenements)
                // console.log(response.data.data)
            })
            .catch(err => {
                alert('Erreur lors de la récupération des informations')
            })
    }, [])
    return (
        <>
            <div className='max-xl:px-4 px-20 mx-auto max-w-screen-xl text-center py-24 lg:py-15 sm:py-15'>
                {events.length <= 0 ? (<span>Aucun évènement pour le moment</span>) : 
                    <div className="lg:grid lg:grid-cols-4 lg:gap-4 sm:flex sm:flex-col">
                        <div className="text-left">
                            {events ?
                                (
                                    <div className='pb-4'>
                                        <FormControl>
                                            <FormLabel id="demo-radio-buttons-group-label">Thème</FormLabel>
                                            <RadioGroup
                                                aria-labelledby="demo-radio-buttons-group-label"
                                                defaultValue="female"
                                                name="radio-buttons-group"
                                                onChange={(e) => handleChange(e,"theme")}
                                                value={value}
                                            >
                                                {
                                                    events.map((evl) => {
                                                        return (
                                                            <div className='pb-4'>
                                                                <FormControlLabel value={evl.themeEvenement.theme} key={evl.themeEvenement.id} control={<Radio />} label={<span className='text-sm'>{evl.themeEvenement.theme}</span>} />
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </RadioGroup>
                                        </FormControl>
                                        <br />
                                        <FormControl>
                                            <FormLabel id="demo-radio-buttons-group-label">Type</FormLabel>
                                            <RadioGroup
                                                aria-labelledby="demo-radio-buttons-group-label"
                                                defaultValue="female"
                                                name="radio-buttons-group"
                                                onChange={(e) => handleChange(e, "type")}
                                                value={value}
                                            >
                                                {
                                                    events.map((evl) => {
                                                        return (
                                                            <div className='pb-4'>
                                                                <FormControlLabel value={evl.typeEvenement.codeType} key={evl.typeEvenement.id} control={<Radio />} label={<span className='text-sm'>{evl.typeEvenement.libelleType}</span>} />
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </RadioGroup>
                                        </FormControl>
                                    </div>

                                ) : ("")
                            }
                        </div>


                        <div className="col-span-3">
                            <div className="grid lg:grid-cols-3 lg:gap-8 text-left grid-cols-2 gap-4">
                                {value == 'tous' ?
                                    events.map((el) => {
                                        let imginfo = el.actualite.image
                                        let tabimg = []
                                        tabimg = imginfo.split(',')
                                        imginfo = tabimg[0]
                                        imginfo = imginfo.replace(/\\/g, "/")

                                        const dn = new Date(el.startDate)
                                        const startdate = dn.toISOString().split('T')[0]
                                        const df = new Date(el.endDate)
                                        const enddate = df.toISOString().split('T')[0]
                                        return (
                                            <div key={el.id} className="max-w-sm bg-white">
                                                <a href={`/details-evenement/${el.actualite.title}`}>
                                                    {el.actualite.image ? (<img crossOrigin="anonymous" src={`${urlstandart}api/${imginfo}`} elt="" />) : ("")}
                                                </a>
                                                <div className="p-5">
                                                    <p>
                                                        <span className='inline-flex'>
                                                            <svg
                                                                fill="#000000"
                                                                width={21} height={21}
                                                                viewBox="0 0 1024 1024"
                                                                xmlns="http://www.w3.org/2000/svg"

                                                            >
                                                                <path d="M960 95.888l-256.224.001V32.113c0-17.68-14.32-32-32-32s-32 14.32-32 32v63.76h-256v-63.76c0-17.68-14.32-32-32-32s-32 14.32-32 32v63.76H64c-35.344 0-64 28.656-64 64v800c0 35.343 28.656 64 64 64h896c35.344 0 64-28.657 64-64v-800c0-35.329-28.656-63.985-64-63.985zm0 863.985H64v-800h255.776v32.24c0 17.679 14.32 32 32 32s32-14.321 32-32v-32.224h256v32.24c0 17.68 14.32 32 32 32s32-14.32 32-32v-32.24H960v799.984zM736 511.888h64c17.664 0 32-14.336 32-32v-64c0-17.664-14.336-32-32-32h-64c-17.664 0-32 14.336-32 32v64c0 17.664 14.336 32 32 32zm0 255.984h64c17.664 0 32-14.32 32-32v-64c0-17.664-14.336-32-32-32h-64c-17.664 0-32 14.336-32 32v64c0 17.696 14.336 32 32 32zm-192-128h-64c-17.664 0-32 14.336-32 32v64c0 17.68 14.336 32 32 32h64c17.664 0 32-14.32 32-32v-64c0-17.648-14.336-32-32-32zm0-255.984h-64c-17.664 0-32 14.336-32 32v64c0 17.664 14.336 32 32 32h64c17.664 0 32-14.336 32-32v-64c0-17.68-14.336-32-32-32zm-256 0h-64c-17.664 0-32 14.336-32 32v64c0 17.664 14.336 32 32 32h64c17.664 0 32-14.336 32-32v-64c0-17.68-14.336-32-32-32zm0 255.984h-64c-17.664 0-32 14.336-32 32v64c0 17.68 14.336 32 32 32h64c17.664 0 32-14.32 32-32v-64c0-17.648-14.336-32-32-32z" />
                                                            </svg>
                                                            &nbsp;
                                                            <span className='text-gray-500 text-sm'><i>{startdate} à {enddate}</i></span>
                                                        </span>
                                                    </p>
                                                    <a href={`/details-evenement/${el.actualite.title}`}>
                                                        <h5 className="mb-2 text-2sm font-bold tracking-tight text-black-900 dark:text-white">{el.actualite.title}</h5>
                                                    </a>
                                                    <p className="mb-3 font-normal text-sm dark:text-black-200">{el.actualite.subTitle}</p>

                                                </div>
                                            </div>
                                        )
                                }) :
                                    event.map((ev) => {
                                        let imginfo = ev.actualite.image
                                        let tabimg = []
                                        tabimg = imginfo.split(',')
                                        imginfo = tabimg[0]
                                        imginfo = imginfo.replace(/\\/g, "/")

                                        const dn = new Date(ev.startDate)
                                        const startdate = dn.toISOString().split('T')[0]
                                        const df = new Date(ev.endDate)
                                        const enddate = df.toISOString().split('T')[0]
                                        return (
                                            <div key={ev.id} className="max-w-sm bg-white">
                                                <a href={`/details-evenement/${ev.actualite.title}`}>
                                                    {ev.actualite.image ? (<img crossOrigin="anonymous" src={`${urlstandart}api/${imginfo}`} elt="" />) : ("")}
                                                </a>
                                                <div className="p-5">
                                                    <p>
                                                        <span className='inline-flex'>
                                                            <svg
                                                                fill="#000000"
                                                                width={21} height={21}
                                                                viewBox="0 0 1024 1024"
                                                                xmlns="http://www.w3.org/2000/svg"

                                                            >
                                                                <path d="M960 95.888l-256.224.001V32.113c0-17.68-14.32-32-32-32s-32 14.32-32 32v63.76h-256v-63.76c0-17.68-14.32-32-32-32s-32 14.32-32 32v63.76H64c-35.344 0-64 28.656-64 64v800c0 35.343 28.656 64 64 64h896c35.344 0 64-28.657 64-64v-800c0-35.329-28.656-63.985-64-63.985zm0 863.985H64v-800h255.776v32.24c0 17.679 14.32 32 32 32s32-14.321 32-32v-32.224h256v32.24c0 17.68 14.32 32 32 32s32-14.32 32-32v-32.24H960v799.984zM736 511.888h64c17.664 0 32-14.336 32-32v-64c0-17.664-14.336-32-32-32h-64c-17.664 0-32 14.336-32 32v64c0 17.664 14.336 32 32 32zm0 255.984h64c17.664 0 32-14.32 32-32v-64c0-17.664-14.336-32-32-32h-64c-17.664 0-32 14.336-32 32v64c0 17.696 14.336 32 32 32zm-192-128h-64c-17.664 0-32 14.336-32 32v64c0 17.68 14.336 32 32 32h64c17.664 0 32-14.32 32-32v-64c0-17.648-14.336-32-32-32zm0-255.984h-64c-17.664 0-32 14.336-32 32v64c0 17.664 14.336 32 32 32h64c17.664 0 32-14.336 32-32v-64c0-17.68-14.336-32-32-32zm-256 0h-64c-17.664 0-32 14.336-32 32v64c0 17.664 14.336 32 32 32h64c17.664 0 32-14.336 32-32v-64c0-17.68-14.336-32-32-32zm0 255.984h-64c-17.664 0-32 14.336-32 32v64c0 17.68 14.336 32 32 32h64c17.664 0 32-14.32 32-32v-64c0-17.648-14.336-32-32-32z" />
                                                            </svg>
                                                            &nbsp;
                                                            <span className='text-gray-500 text-sm'><i>{startdate} - {enddate}</i></span>
                                                        </span>
                                                    </p>
                                                    <a href={`/details-evenement/${ev.actualite.title}`}>
                                                        <h5 className="mb-2 text-2sm font-bold tracking-tight text-black-900 dark:text-white">{ev.actualite.title}</h5>
                                                    </a>
                                                    <p className="mb-3 font-normal text-sm dark:text-black-200">{ev.actualite.subTitle}</p>

                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                }
            </div>
            
        </>
    );
}

export default LesEvenements;