import React from 'react';
import img from '../Images/97aa576264f4bb462bdc4925813690dc.jpg'
function DescBannniereApropos(props) {
    return (
        <>
            <div className="flex flex-col font-[' Montserrat'] justify-center">
                <div className='px-32 max-md:px-4 py-10 bg-white'>
                    <div>
                        <span className="text-yellow-300  max-md:text-[16px] text-[24px] font-bold font-['Montserrat']">Qui sommes-nous ?</span>
                    </div>
                    <div>
                        <span className="text-neutral-900 max-md:text-[20px] text-[32px] font-bold font-['Montserrat']">
                            Votre accompagnateur dès votre naissance et à toutes les phases de votre développement
                        </span>
                    </div>
                </div>
                <div>
                    <img className='w-full' src={img} />
                </div>
            </div>
        </>
    );
}

export default DescBannniereApropos;