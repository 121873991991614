import React, { useState, useEffect } from 'react';
import {useNavigate, Link} from 'react-router-dom'
// import Box from '@mui/material/Box';
// import Stepper from '@mui/material/Stepper';
// import Step from '@mui/material/Step';
// import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
// import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
// import Typography from '@mui/material/Typography';
import { FileInput, Label, Modal, TextInput } from 'flowbite-react';
// import {
//     Tabs,
//     TabsHeader,
//     TabsBody,
//     Tab,
//     TabPanel,
// } from "@material-tailwind/react";
import ReCAPTCHA from "react-google-recaptcha";
// import MenuItem from '@mui/material/MenuItem';
// import SelectM from '@mui/material/Select';
// import Radio from '@mui/material/Radio';
// import RadioGroup from '@mui/material/RadioGroup';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import FormControl from '@mui/material/FormControl';
// import FormLabel from '@mui/material/FormLabel';
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import axios from 'axios'
import { Button } from "@material-tailwind/react"
import imgmodalsuccess from './Images/imgmodalsuccess.PNG'
import logo from './Images/logoadpme_a.png'

export default function Description(props) {
    const currentDate = new Date();
    // const [activeTab, setActiveTab] = React.useState("entreprise");
    // const [activeStep, setActiveStep] = useState(0);
    // const [fullName, setFullName] = useState('')
    const [nom, setNom] = useState('')
    const [prenom, setPrenom] = useState('')
    const [old, setOld] = useState(new Date())
    const [genre, setGenre] = useState('')
    const [mail, setMail] = useState('')
    const [tel, setTel] = useState('')
    const [fonction, setFonction] = useState('')
    const [part, setPart] = useState(0)
    const [sigle, setSigle] = useState('')
    const [raisonSociale, setRaisonSociale] = useState('')
    const [secteur, setSecteur] = useState(0)
    const [sSecteur, setSSecteur] = useState([])
    const [sousSecteur, setSousSecteur] = useState(0)
    const [numeroRCCM1, setNumeroRCCM1] = useState('')
    const [numeroRCCM2, setNumeroRCCM2] = useState("")
    const [numeroRCCM3, setNumeroRCCM3] = useState("")
    const [numeroRCCM4, setNumeroRCCM4] = useState("")
    const [villageOuQuartier, setVillageOuQuartier] = useState(0)
    const [arrondissement, setArrondissement] = useState(0)
    const [commune, setCommune] = useState(0)
    const [dept, setDept] = useState(0)
    const [dateCreation, setDateCreation] = useState(new Date())
    const [adresse, setAdresse] = useState('')
    const [telEntreprise, setTelEntreprise] = useState('')
    const [mailEntreprise, setMailEntreprise] = useState('')
    const [bp, setBp] = useState('')
    const [ifu, setIfu] = useState("")
    const [statutJuridique, setStatutJuridique] = useState(0)
    const [capitaleSocial, setCapitalSocial] = useState(0)
    const [currentYear, setCurrentYear] = useState(0);
    const [firstYear, setFirstYear] = useState(currentDate.getFullYear() - 1);
    const [firstYearCA, setFirstYearCA] = useState(0);
    const [firstYearEmployer, setFirstYearEmployer] = useState(0);
    const [secondYear, setSecondYear] = useState(currentDate.getFullYear() - 2);
    const [secondYearCA, setSecondYearCA] = useState(0);
    const [secondYearEmployer, setSecondYearEmployer] = useState(0);
    const [value, setValue] = React.useState('1');
    const [communes, setCommunes] = useState([]);
    const [communesChoose, setCommunesChoose] = useState([]);
    const [arrondissements, setArrondissements] = useState([]);
    const [arrondissementsChoose, setArrondissementsChoose] = useState([]);
    const [depts, setDepts] = useState([]);
    const [vils, setVils] = useState([]);
    const [secteurs, setSecteurs] = useState([]);
    const [sousSecteurs, setSousSecteurs] = useState([]);
    const [sousSecteursChoose, setSousSecteursChoose] = useState([]);
    const [vilsChoose, setVilsChoose] = useState([]);
    const [projets, setProjets] = useState([]);
    const [statusJs, setStatutJs] = useState([]);
    const [projet, setProjet] = useState([]);
    const [searchComapnyName, setSearchCompanyName] = useState('');
    const [companySearched, setCompanySearched] = useState([]);
    const [companySearchedId, setCompanySearchedId] = useState(0);
    const [selectedFileRccm, setSelectedFileRccm] = useState(null);
    const [selectedFileIfu, setSelectedFileIfu] = useState(null);
    const [selectedFileCV, setSelectedFileCV] = useState(null);
    const [selectedFileLettre, setSelectedFileLettre] = useState(null);
    const [selectedFileOther, setSelectedFileOther] = useState(null);
    const [selectedFileCnss, setSelectedFileCnss] = useState(null);
    const [selectedFileFiscale, setSelectedFileFiscale] = useState(null);
    const [selectedFileBusiness, setSelectedFileBusiness] = useState(null);
    const [selectedFileIdentity, setSelectedFileIdentity] = useState(null);
    const [selectedFileUpload, setSelectedFileUpload] = useState(null);
    const [selectedFileAuth, setSelectedFileAuth] = useState(null);
    const [getFileAuth, setGetFileAuth] = useState(false);
    const [errorFile, setErrorFile] = useState('');
    const [otherProjet, setOtherProject] = useState(false);
    const [otherProjetText, setOtherProjectText] = useState('');
    const [otherSecteur, setOtherSecteur] = useState(false);
    const [otherSecteurText, setOtherSecteurText] = useState('');
    const [isClicked, setIsClicked] = useState(false);
    const [isModalShow, setIsModalShow] = useState(false)
    const [msgSuccess, setMsgSuccess] = useState('')
    const [offre, setOffre] = useState([]);
    const [statutEnt, setStatutEnt] = useState(null);
    const [website, setWebsite] = useState('')
    const [prefixWebsite, setPrefixWebsite] = useState('https://')
    const [suffixWebsite, setSuffixWebsite] = useState('.bj')
    const [creationYear, setCreationYear] = useState("")
    const [effectiveStartupYear, setEffectiveStartupYear] = useState("")
    const [verified, setVerified] = useState(false);
    const [recaptchaRef, setRecaptchaRef] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const nav = useNavigate()

    function onChangeRecaptcha(value) {
        setVerified(!verified)
    }

    const handleRadioChange = (event) => {
        setStatutEnt(event.target.value);
    };
    const handleFileChangeAuth = (event) => {
        setSelectedFileAuth(event.target.files[0]);
    }
    const handleFileChangeRccm = (event) => {
        const selectedFile = event.target.files[0];
        const fileSizeInMegabytes = selectedFile ? selectedFile.size / (1024 * 1024) : 0; // Taille du fichier en Mo
        console.log(fileSizeInMegabytes)
        console.log(selectedFile)
        if (fileSizeInMegabytes > 5) {
            alert("Veuillez joindre un fichier de moins de 5 MO.")
            setSelectedFileRccm(null);
        } else {
            setSelectedFileRccm(event.target.files[0]);
        }
    }
    const handleFileChangeIfu = (event) => {
        const selectedFile = event.target.files[0];
        const fileSizeInMegabytes = selectedFile ? selectedFile.size / (1024 * 1024) : 0; // Taille du fichier en Mo
        console.log(fileSizeInMegabytes)
        console.log(selectedFile)
        if (fileSizeInMegabytes > 5) {
            alert("Veuillez joindre un fichier de moins de 5 MO.")
            setSelectedFileIfu(null);
        } else {
            setSelectedFileIfu(event.target.files[0]);
        }
    }
    const handleFileChangeCV = (event) => {
        const selectedFile = event.target.files[0];
        const fileSizeInMegabytes = selectedFile ? selectedFile.size / (1024 * 1024) : 0; // Taille du fichier en Mo
        console.log(fileSizeInMegabytes)
        console.log(selectedFile)
        if (fileSizeInMegabytes > 5) {
            alert("Veuillez joindre un fichier de moins de 5 MO.")
            setSelectedFileCV(null);
        } else {
            setSelectedFileCV(event.target.files[0]);
        }
    }
    const handleFileChangeOther = (event) => {
        const selectedFile = event.target.files[0];
        const fileSizeInMegabytes = selectedFile ? selectedFile.size / (1024 * 1024) : 0; // Taille du fichier en Mo
        console.log(fileSizeInMegabytes)
        console.log(selectedFile)
        if (fileSizeInMegabytes > 5) {
            alert("Veuillez joindre un fichier de moins de 5 MO.")
            setSelectedFileOther(null);
        } else {
            setSelectedFileOther(event.target.files[0]);
        }
    }
    const handleFileChangeLettre = (event) => {
        const selectedFile = event.target.files[0];
        const fileSizeInMegabytes = selectedFile ? selectedFile.size / (1024 * 1024) : 0; // Taille du fichier en Mo
        console.log(fileSizeInMegabytes)
        console.log(selectedFile)
        if (fileSizeInMegabytes > 5) {
            alert("Veuillez joindre un fichier de moins de 5 MO.")
            setSelectedFileLettre(null);
        } else {
            setSelectedFileLettre(event.target.files[0]);
        }
    }
    const handleFileChangeCnss = (event) => {
        const selectedFile = event.target.files[0];
        const fileSizeInMegabytes = selectedFile ? selectedFile.size / (1024 * 1024) : 0; // Taille du fichier en Mo
        console.log(fileSizeInMegabytes)
        console.log(selectedFile)
        if (fileSizeInMegabytes > 5) {
            alert("Veuillez joindre un fichier de moins de 5 MO.")
            setSelectedFileCnss(null);
        } else {
            setSelectedFileCnss(event.target.files[0]);
        }
    }
    const handleCreationYear = (e) => {
        setCreationYear(e.target.value)
        const cyear = new Date(e.target.value)
        const date = new Date()
        if (cyear.getFullYear() > date.getFullYear()) {
            alert("Veuillez sélectionner une date inférieure ou égal à la date actuelle")
            setCreationYear("")
        }
    }
    const handleEffectiveStartupYear = (e) => {
        setEffectiveStartupYear(e.target.value)
        if(creationYear > e.target.value)
        {
          alert("La date de création de votre ne peut pas être supérieur à la date de démarrage effectif")
          setEffectiveStartupYear("");
        }else{
          const eyear = new Date(e.target.value)
          const date = new Date()
          if (eyear.getFullYear() > date.getFullYear()) {
            alert("Veuillez sélectionner une date inférieure ou égal à la date actuelle")
            setEffectiveStartupYear("")
          }
        }
        
    }
    const handleFileChangeFiscale = (event) => {
        const selectedFile = event.target.files[0];
        const fileSizeInMegabytes = selectedFile ? selectedFile.size / (1024 * 1024) : 0; // Taille du fichier en Mo
        console.log(fileSizeInMegabytes)
        console.log(selectedFile)
        if (fileSizeInMegabytes > 5) {
            alert("Veuillez joindre un fichier de moins de 5 MO.")
            setSelectedFileFiscale(null);
        } else {
            setSelectedFileFiscale(event.target.files[0]);
        }
    }
    const handleFileChangeBusiness = (event) => {
        const selectedFile = event.target.files[0];
        const fileSizeInMegabytes = selectedFile ? selectedFile.size / (1024 * 1024) : 0; // Taille du fichier en Mo
        console.log(fileSizeInMegabytes)
        console.log(selectedFile)
        if (fileSizeInMegabytes > 5) {
            alert("Veuillez joindre un fichier de moins de 5 MO.")
            setSelectedFileBusiness(null);
        } else {
            setSelectedFileBusiness(event.target.files[0]);
        }
    }
    const handleFileChangeIdentite = (event) => {
        const selectedFile = event.target.files[0];
        const fileSizeInMegabytes = selectedFile ? selectedFile.size / (1024 * 1024) : 0; // Taille du fichier en Mo

        if (fileSizeInMegabytes > 5) {
            alert("Veuillez joindre un fichier de moins de 5 MO.")
            setSelectedFileIdentity(null);
        } else {
            setSelectedFileIdentity(event.target.files[0]);
        }

    }

    const deptChoose = (e) => {
        setDept(e.target.value)
        setCommunesChoose(communes.filter((com) => com.departementId == e.target.value))
        console.log("departementid :" + e.target.value)
        console.log(communesChoose)
    }

    const comChoose = (e) => {
        setCommune(e.target.value)
        setArrondissementsChoose(arrondissements.filter((com) => com.communeId == e.target.value))
        console.log("communeid :" + e.target.value)
        console.log(arrondissementsChoose)
    }

    const arrChoose = (e) => {
        setArrondissement(e.target.value)
        setVilsChoose(vils.filter((com) => com.arrondissementId == e.target.value))
        console.log("Arrondissementid :" + e.target.value)
        console.log(vilsChoose)
    }

    const sectChoose = (e) => {
        setSecteur(e.target.value)
        const tab = sousSecteurs.filter((com) => com.secteurId == e.target.value)
        tab.push({ libelle: "Autre", code: "AUT" })
        setSousSecteursChoose(tab)
        console.log("secteurId :" + e.target.value)
        console.log(vilsChoose)
    }

    const handleChangeSSecteur = (event) => {
        const { checked, id } = event.target;

        // Créer une copie de l'état actuel de projet
        const secteurCopy = [...sSecteur];

        if (checked) {
            // Ajouter la valeur de la case à cocher sélectionnée à la copie de projet
            secteurCopy.push(id);
            secteurCopy.map((s) => {
                console.log(s === "AUT")
                if (s === "AUT") {
                    setOtherSecteur(true)
                    return
                }
            })

        } else {
            // Retirer la valeur de la case à cocher désélectionnée de la copie de projet
            const index = secteurCopy.indexOf(id);
            secteurCopy.map((s) => {
                console.log(s === "AUT")
                if (s === "AUT") {
                    setOtherSecteur(false)
                    setOtherSecteurText('')
                    return
                }
            })

            if (index !== -1) {
                secteurCopy.splice(index, 1);
            }
        }

        // Mettre à jour l'état de projet avec la copie modifiée
        setSSecteur(secteurCopy);

        console.log(secteurCopy);
    };

    const ModalClose = () => {
        setIsModalShow(false)
        setIsClicked(false)
        if(offre.typeDeRecru == "MPME"){
          nav('/recrutement/selectionmpme')
        }
        if(offre.typeDeRecru == "PERSON"){
          nav('/recrutement/recrutementperson')
        }
        // window.location.reload()
    }

    const handleChangeProjet = (event) => {
        const { checked, id } = event.target;

        // Créer une copie de l'état actuel de projet
        const projetCopy = [...projet];

        if (checked) {
            // Ajouter la valeur de la case à cocher sélectionnée à la copie de projet
            projetCopy.push(id);
            id == 'Autre' ? setOtherProject(true) : setOtherProject(false)
        } else {
            // Retirer la valeur de la case à cocher désélectionnée de la copie de projet
            const index = projetCopy.indexOf(id);
            if (id == 'Autre') {
                setOtherProjectText('')
            }
            id == 'Autre' ? setOtherProject(false) : setOtherProject(true)
            if (index !== -1) {
                projetCopy.splice(index, 1);
            }
        }

        // Mettre à jour l'état de projet avec la copie modifiée
        setProjet(projetCopy);

        console.log(projetCopy);

    };


    const handleSubmit = (e) => {
        e.preventDefault()
        setIsLoading(true)
        const cyear = new Date(creationYear)
        const eyear = new Date(effectiveStartupYear)
        setIsClicked(true)
        const formdata = new FormData()

        const nomComplet = prenom + ' ' + nom
        let tab = []
        sSecteur.map((el) => {
            let sectChoose = []
            console.log(el)
            console.log(sousSecteursChoose)
            if (el != "AUT") {

                sectChoose = sousSecteursChoose.find((s) => s.code == el)

                tab.push({ "id": parseInt(sectChoose.id, 10) });
                // formdata.append("sousSecteurs"
            }
        })
        let tabp = []
        projet.map((el) => {
            let sectChoose = []
            if (el != "Autre") {


                sectChoose = projets.find((s) => s.actualite.title == el)
                // sectChoose = projets.find((s) => s.libelle == el)
                tabp.push({ "appelAProjetId": parseInt(sectChoose.id, 10), "decision": true })

            }
        })
        console.log(
            nomComplet + "\n " +
            old + "\n " +
            genre + "\n " +
            mail + "\n " +
            tel + "\n " +
            fonction + "\n " +
            part + "\n " +
            sigle + "\n " +
            raisonSociale + "\n " +
            secteur + "\n " +
            sousSecteur + "\n " +
            villageOuQuartier + "\n " +
            arrondissement + "\n " +
            commune + "\n " +
            dept + "\n " +
            dateCreation + "\n " +
            adresse + "\n " +
            telEntreprise + "\n " +
            mailEntreprise + "\n " +
            bp + "\n " +
            statutJuridique + "\n " +
            capitaleSocial + "\n " +
            firstYear + "\n " +
            firstYearCA + "\n " +
            firstYearEmployer + "\n " +
            secondYear + "\n " +
            secondYearCA + "\n " +
            secondYearEmployer + "\n " +
            tab + "\n" +
            statutEnt + "\n" +
            prefixWebsite+website+suffixWebsite+ "\n" +
            "RB"+"/"+numeroRCCM1+"/"+numeroRCCM2+" "+numeroRCCM3+" "+numeroRCCM4
        )

        const tabExercice = [];
        tabExercice.push(
            {
                "ca": parseInt(firstYearCA, 10),
                "nbreEmp": parseInt(firstYearEmployer, 10),
                "annee": parseInt(firstYear, 10)
            },
            {
                "ca": parseInt(secondYearCA, 10),
                "nbreEmp": parseInt(secondYearEmployer, 10),
                "annee": parseInt(secondYear, 10)
            }
        )
        formdata.append("Exercice", JSON.stringify(tabExercice))
        if (otherSecteurText != '') {
            formdata.append("autreSousSecteur", otherSecteurText)
        }
        formdata.append("ceoName", nomComplet)
        formdata.append("ceoEmail", mail)
        formdata.append("ifu", ifu)
        formdata.append("status", statutEnt)
        formdata.append("sigle", sigle)
        formdata.append("creationYear", cyear.getFullYear())
        formdata.append("effectiveStartupYear", eyear.getFullYear())
        formdata.append("raisonSociale", raisonSociale)
        const numeroRCCM = "RB"+"/"+numeroRCCM1+"/"+numeroRCCM2+" "+numeroRCCM3+" "+numeroRCCM4
        if (numeroRCCM1 && numeroRCCM2 && numeroRCCM3 && numeroRCCM4) formdata.append("rccm", numeroRCCM)
        if (selectedFileRccm) formdata.append("rccmFile", selectedFileRccm)
        if (selectedFileBusiness) formdata.append("businessPlan", selectedFileBusiness)
        formdata.append("website", prefixWebsite+website+suffixWebsite)
        if (selectedFileIfu) formdata.append("ifuFile", selectedFileIfu)
        if (selectedFileCnss) formdata.append("certificateCNSS", selectedFileCnss)
        if (selectedFileFiscale) formdata.append("fiscaleCertificate", selectedFileFiscale)
        formdata.append("recrutementId", offre.id)
        formdata.append("addresse", adresse)
        formdata.append("phone", telEntreprise)
        formdata.append("email", mailEntreprise)
        formdata.append("capitalSocial", parseInt(capitaleSocial, 10))
        formdata.append("statusJuridiqueId", parseInt(statutJuridique, 10))
        if (villageOuQuartier) formdata.append("villagesOuQuartiersDevilleId", villageOuQuartier)
        formdata.append("postalAddress", bp)
        // formdata.append("Exercice", tabExercice)
        formdata.append("ceoIdentityPiece", selectedFileIdentity)
        // formdata.append("autorisation", selectedFileAuth)
        // formdata.append("CreateurDirigeant", JSON.stringify([
        //     {
        //         "firstname": prenom,
        //         "lastname": nom,
        //         "gender": genre,
        //         "phone": tel,
        //         "birthday": old,
        //         "email": mail,
        //         "pme_createur_dirigeants": [
        //             {
        //                 "poste": fonction,
        //                 "part": parseInt(part, 10)
        //             }
        //         ]
        //     }
        // ]))
        // formdata.append("ParticipeAppelAProjet", JSON.stringify(tabp))
        formdata.append("sousSecteurs", JSON.stringify(tab))
        if (window.confirm("Voulez-vous vraiment éffectuer cet enregistrement ?") == true) {
            const newurl = process.env.REACT_APP_URL_STANDART + "api/pmeSelected"
            console.log(selectedFileRccm);

            axios.post(newurl, formdata, { withCredentials: true })
                .then(response => {
                    setIsLoading(false)
                    setIsModalShow(true)
                    setIsClicked(false)
                    //nav('/recrutement/selectionmpme')
                })
                .catch(err => {
                    setIsClicked(false)
                    setIsLoading(false)
                    alert('Erreur lors de la soumission de la candidature')
                })
        } else {
            setIsLoading(false)
        }
    };

    const handleSubmitPerson = (e) => {
        e.preventDefault()
        setIsLoading(true)
        setIsClicked(true)
        console.log(
            prenom + "\n " +
            nom + "\n " +
            mail + "\n " +
            tel + "\n " +
            villageOuQuartier + "\n " +
            selectedFileLettre + "\n " +
            selectedFileCV + "\n " +
            selectedFileOther
        )
        const formdata = new FormData()
        formdata.append("recrutementId", offre.id)
        formdata.append("firstname", prenom)
        formdata.append("lastname", nom)
        formdata.append("email", mail)
        formdata.append("phone", tel)
        if (villageOuQuartier) {
          formdata.append("residenceId", villageOuQuartier)
        }
        formdata.append("coverLetter", selectedFileLettre)
        formdata.append("resume", selectedFileCV)
        if (selectedFileOther) formdata.append("OtherFile", selectedFileOther)

        if (window.confirm("Voulez-vous vraiment éffectuer cet enregistrement ?") == true) {
            const newurl = process.env.REACT_APP_URL_STANDART + "api/hiringPerson"
            console.log(selectedFileRccm);

            axios.post(newurl, formdata, { withCredentials: true })
                .then(response => {
                    setIsLoading(false)
                    setIsModalShow(true)
                    setIsClicked(false)
                    //nav('/recrutement/recrutementperson')
                })
                .catch(err => {
                    setIsClicked(false)
                    setIsLoading(false)
                    alert('Erreur lors de la soumission de la candidature')
                })
        } else {
            setIsLoading(false)
        }
    };


    useEffect(() => {
        const getCurrentYear = () => {
            const currentDate = new Date();
            const year = currentDate.getFullYear();
            setCurrentYear(year);
        };
        getCurrentYear();
        const newurlcom = process.env.REACT_APP_URL_STANDART + "api/communes"
        axios.get(newurlcom, {}, { withCredentials: true })
            .then(response => {
                //alert('Recrutement supprimer')
                setCommunes(response.data.data)
            })
            .catch(err => {
                console.log('Erreur lors de la récupération des communes')
            })
        const newurlarr = process.env.REACT_APP_URL_STANDART + "api/arrondissements"
        axios.get(newurlarr, {}, { withCredentials: true })
            .then(response => {
                //console.log('Recrutement supprimer')
                setArrondissements(response.data.data)
            })
            .catch(err => {
                console.log('Erreur lors de la récupération des arrondissements')
            })
        const newurldept = process.env.REACT_APP_URL_STANDART + "api/departements"
        axios.get(newurldept, {}, { withCredentials: true })
            .then(response => {
                //console.log('Recrutement supprimer')
                setDepts(response.data.data)
            })
            .catch(err => {
                console.log('Erreur lors de la récupération du départements')
            })
        const newurlvils = process.env.REACT_APP_URL_STANDART + "api/villagesOuQuartiersDeVilles"
        axios.get(newurlvils, {}, { withCredentials: true })
            .then(response => {
                //console.log('Recrutement supprimer')
                setVils(response.data.data)
            })
            .catch(err => {
                console.log('Erreur lors de la récupération du villages ou quartiers')
            })
        const newurlsects = process.env.REACT_APP_URL_STANDART + "api/secteurs"
        axios.get(newurlsects, {}, { withCredentials: true })
            .then(response => {
                //console.log('Recrutement supprimer')
                setSecteurs(response.data.data)
            })
            .catch(err => {
                console.log('Erreur lors de la récupération du secteurs')
            })
        const newurlssects = process.env.REACT_APP_URL_STANDART + "api/sousSecteurs"
        axios.get(newurlssects, {}, { withCredentials: true })
            .then(response => {
                //console.log('Recrutement supprimer')
                setSousSecteurs(response.data.data)
            })
            .catch(err => {
                console.log('Erreur lors de la récupération du sous secteurs')
            })
        const newurlpro = process.env.REACT_APP_URL_STANDART + "api/appelAProjets"
        axios.get(newurlpro, {}, { withCredentials: true })
            .then(response => {
                //console.log('Recrutement supprimer')
                const tab = response.data.data.appelAProjets
                tab.push({ actualite: { title: "Autre" } })
                // tab = response.data.data
                setProjets(tab)
                console.log("Projets : ")
                console.log(response.data.data)
                console.log(tab)
            })
            .catch(err => {
                console.log('Erreur lors de la récupération des projets')
            })
        const newurlstat = process.env.REACT_APP_URL_STANDART + "api/statutJuridiques"
        axios.get(newurlstat, {}, { withCredentials: true })
            .then(response => {
                //console.log('Recrutement supprimer')
                setStatutJs(response.data.data)
            })
            .catch(err => {
                console.log('Erreur lors de la récupération du statut juridique')
            })
        const newurloffre = process.env.REACT_APP_URL_STANDART + "api/recrutements"
        axios.get(newurloffre, { withCredentials: true })
            .then(response => {
                let tabevent = response.data.data.recrutements.filter((ev) =>
                    ev.poste == props.title
                );
                setOffre(tabevent[0]);
            })
            .catch(err => {
                alert('Erreur lors de la récupération des informations')
            })
    }, []);

    return (
      <div className="max-xl:px-14 px-14 mx-auto max-w-screen-xl text-left py-24 lg:py-15 sm:py-15">
        <Modal show={isLoading} popup size="sm" position="center">
          <div className="flex justify-center">
            <div className="flex-col">
              <div className="flex justify-center">
                <img className="h-6 w-6" src={logo} />
              </div>
              <div className="flex justify-center">
                <span className="font-bold">Merci de patientez...</span>
              </div>
            </div>
          </div>
        </Modal>
        {offre.typeDeRecru == "MPME" && (
          <form onSubmit={handleSubmit}>
            <div className="space-y-12">
              <Link to="/recrutement/selectionmpme">
                <Button className="bg-green-700 text-white flex items-center gap-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          class="h-5 w-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18"
                          />
                        </svg>
                        Retour
                </Button>
              </Link>
              <h2 className="text-4xl font-semibold leading-relaxed text-neutral-900 pb-3 font-[' Montserrat']">
                Candidature pour l'offre de :{" "}
                {offre ? (
                  <span className="text-green-700">{offre.poste}</span>
                ) : (
                  "..."
                )}
              </h2>
              <br />
              {/* Entreprise formelle ou informelle */}
              <div className="mb-3">
                <div className="sm:col-span-6">
                  <label
                    htmlFor="statent"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Statut de l'entreprise{" "}
                    <span className="text-red-500">*</span>
                  </label>
                  <div className="flex items-center ps-4 border border-gray-200 rounded dark:border-gray-700">
                    <input
                      required
                      onChange={handleRadioChange}
                      id="bordered-radio-1"
                      type="radio"
                      value="FORMELLE"
                      name="bordered-radio"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      for="bordered-radio-1"
                      className="w-full py-4 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      Formel
                    </label>
                  </div>
                  <div className="flex items-center ps-4 border border-gray-200 rounded dark:border-gray-700">
                    <input
                      required
                      onChange={handleRadioChange}
                      id="bordered-radio-2"
                      type="radio"
                      value="INFORMELLE"
                      name="bordered-radio"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      for="bordered-radio-2"
                      className="w-full py-4 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      Informel
                    </label>
                  </div>
                </div>
              </div>
              {statutEnt && (
                <>
                  <div className="border-b border-gray-900/10 pb-12">
                    <h2 className="text-2xl font-semibold leading-7 text-neutral-900 pb-3 font-[' Montserrat']">
                      Informations du dirigeant
                    </h2>
                    <p className="mt-1 text-2xs leading-6 text-gray-500 font-[' Montserrat']">
                      Utilisez une adresse permanente où vous pouvez recevoir du
                      courrier.
                    </p>

                    <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                      <div className="sm:col-span-3">
                        <label
                          htmlFor="first-name"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Prénom(s) <span className="text-red-500">*</span>
                        </label>
                        <div className="mt-2">
                          <input
                            type="text"
                            name="first-name"
                            id="first-name"
                            autoComplete="given-name"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                            onChange={(e) => setPrenom(e.target.value)}
                            value={prenom}
                            required
                          />
                        </div>
                      </div>

                      <div className="sm:col-span-3">
                        <label
                          htmlFor="last-name"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Nom <span className="text-red-500">*</span>
                        </label>
                        <div className="mt-2">
                          <input
                            type="text"
                            name="last-name"
                            id="last-name"
                            autoComplete="family-name"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                            onChange={(e) => setNom(e.target.value)}
                            value={nom}
                            required
                          />
                        </div>
                      </div>

                      <div className="sm:col-span-3">
                        <label
                          htmlFor="email"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Adresse mail <span className="text-red-500">*</span>
                        </label>
                        <div className="mt-2">
                          <input
                            id="email"
                            name="email"
                            type="email"
                            autoComplete="email"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                            onChange={(e) => setMail(e.target.value)}
                            value={mail}
                            required
                          />
                          <p className="flex items-center gap-1 mt-2 text-xs antialiased font-normal leading-normal text-gray-700">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              fill="currentColor"
                              className="w-4 h-4 -mt-px"
                            >
                              <path
                                fillRule="evenodd"
                                d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm8.706-1.442c1.146-.573 2.437.463 2.126 1.706l-.709 2.836.042-.02a.75.75 0 01.67 1.34l-.04.022c-1.147.573-2.438-.463-2.127-1.706l.71-2.836-.042.02a.75.75 0 11-.671-1.34l.041-.022zM12 9a.75.75 0 100-1.5.75.75 0 000 1.5z"
                                clipRule="evenodd"
                              ></path>
                            </svg>
                            Renseignez une adresse mail valide afin de recevoir
                            le récapitulatif de votre demande.
                          </p>
                        </div>
                      </div>

                      {/* <div className="sm:col-span-3">
                                <label htmlFor="naissance" className="block text-sm font-medium leading-6 text-gray-900">
                                    Date de naissance <span className='text-red-500'>*</span>
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="naissance"
                                        name="naissance"
                                        type="date"
                                        autoComplete="naissance"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                                        onChange={handleDateChange}
                                        value={old}
                                        required
                                    />
                                </div>
                            </div>
                            {getFileAuth && (
                                <div className="col-span-full">
                                    <label htmlFor="cover-photoauto" className="block text-sm font-medium leading-6 text-gray-900">
                                        Autorisation <span className='text-red-500'>*</span>
                                    </label>
                                    <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                                        <div className="text-center">
                                            <div className="mt-4 flex text-sm leading-6 text-gray-600">
                                                <label
                                                    htmlFor="file-upload-auto"
                                                    className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                                                >
                                                    <span>Glisser un fichier</span>
                                                    <input onChange={handleFileChangeAuth} id="file-upload-auto" name="file-upload-auto" type="file" className="sr-only" />
                                                </label>
                                            </div>
                                            <p className="text-xs leading-5 text-gray-600">Max 5MB</p>
                                        </div>
                                    </div>
                                    <p className="text-md leading-5 text-green-700 font-bold">{selectedFileAuth ? selectedFileAuth.name : ("")}</p>
                                </div>
                            )}
                            <div className="sm:col-span-6">
                                <label htmlFor="naissance" className="block text-sm font-medium leading-6 text-gray-900">
                                    Genre <span className='text-red-500'>*</span>
                                </label>
                                <div className="flex items-center ps-4 border border-gray-200 rounded dark:border-gray-700">
                                    <input required onChange={handleRadioChange} id="bordered-radio-1" type="radio" value="HOMME" name="bordered-radio" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                    <label for="bordered-radio-1" className="w-full py-4 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Homme</label>
                                </div>
                                <div className="flex items-center ps-4 border border-gray-200 rounded dark:border-gray-700">
                                    <input required onChange={handleRadioChange} id="bordered-radio-2" type="radio" value="FEMME" name="bordered-radio" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                    <label for="bordered-radio-2" className="w-full py-4 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Femme</label>
                                </div>
                            </div> */}

                      <div className="sm:col-span-3">
                        <label
                          htmlFor="num"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Numéro de télephone mobile{" "}
                          <span className="text-red-500">*</span>
                        </label>
                        <div className="mt-2">
                          {/* <input
                                                            id="num"
                                                            name="num"
                                                            type="text"
                                                            autoComplete="num"
                                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                                                            onChange={(e) => setTel(e.target.value)} value={tel}
                                                            required
                                                        /> */}
                          <PhoneInput
                            defaultCountry="bj"
                            value={tel}
                            onChange={(phone) => setTel(phone)}
                          />
                        </div>
                      </div>

                      {/* <div className="sm:col-span-3">
                                <label htmlFor="country" className="block text-sm font-medium leading-6 text-gray-900">
                                    Fonction dans l'entreprise <span className='text-red-500'>*</span>
                                </label>
                                <div className="mt-2">
                                    <select
                                        id="country"
                                        name="country"
                                        autoComplete="country-name"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                                        onChange={(e) => setFonction(e.target.value)} value={fonction}
                                    >
                                        <option value=''>Sélectionner votre fonction dans l'entreprise</option>
                                        <option value='Associé'>Associé</option>
                                        <option value='Propriétaire'>Propriétaire</option>
                                        <option value='Actionnaire'>Actionnaire</option>
                                        <option value='Gestionnaire'>Gestionnaire</option>
                                        <option value='Collaborateur'>Collaborateur</option>
                                    </select>
                                </div>
                            </div>

                            <div className="sm:col-span-3">
                                <label htmlFor="adresse" className="block text-sm font-medium leading-6 text-gray-900">
                                    Quel est votre pourcentage des parts sociales que vous détenez ? <span className='text-red-500'>*</span>
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="adresse"
                                        name="adresse"
                                        type="number"
                                        autoComplete="number"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                                        onChange={(e) => setPart(e.target.value)} value={part}
                                        min="0"
                                        required
                                    />
                                </div>
                            </div> */}
                    </div>
                  </div>
                  <div className="border-b border-gray-900/10 pb-12">
                    <h2 className="text-2xl font-semibold leading-7 text-neutral-900 pb-3">
                      Informations de l'entreprise
                    </h2>
                    <p className="mt-1 text-2xs leading-6 text-gray-600">
                      Cette section concerne les informations relatives à
                      l'entreprise
                    </p>

                    <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                      <div className="sm:col-span-3">
                        <label
                          htmlFor="sigle"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Sigle de l'entreprise{" "}
                          <span className="text-red-500">*</span>
                        </label>
                        <div className="mt-2">
                          <input
                            id="sigle"
                            name="sigle"
                            type="text"
                            autoComplete="text"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                            onChange={(e) => setSigle(e.target.value)}
                            value={sigle}
                            placeholder="Ex: ADPME"
                            required
                          />
                        </div>
                      </div>

                      <div className="sm:col-span-3">
                        <label
                          htmlFor="sigle"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Raison sociale <span className="text-red-500">*</span>
                        </label>
                        <div className="mt-2">
                          <input
                            id="sigle"
                            name="sigle"
                            type="text"
                            autoComplete="text"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                            onChange={(e) => setRaisonSociale(e.target.value)}
                            value={raisonSociale}
                            required
                            placeholder="Ex: Agence de Developpement des Petites et Moyennes Entreprises"
                          />
                        </div>
                      </div>

                      {/* <div className="sm:col-span-3">
                                <label htmlFor="capital_social" className="block text-sm font-medium leading-6 text-gray-900">
                                    Capital social (en FCFA) <span className='text-red-500'>*</span>
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="capital_social"
                                        name="capital_social"
                                        type="number"
                                        autoComplete="text"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                                        onChange={(e) => setCapitalSocial(e.target.value)} value={capitaleSocial}
                                        min="0"
                                        required
                                    />
                                </div>

                            </div> */}

                      {/* <div className="sm:col-span-3">
                                <label htmlFor="date_creation" className="block text-sm font-medium leading-6 text-gray-900">
                                    Date de création de l'entreprise <span className='text-red-500'>*</span>
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="date_creation"
                                        name="date_creation"
                                        type="date"
                                        autoComplete="text"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                                        onChange={handleDateEntChange} value={dateCreation}
                                        required

                                    />
                                </div>

                            </div> */}

                      <div className="sm:col-span-3">
                        <label
                          htmlFor="tel_ent"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Télephone de l'entreprise{" "}
                          <span className="text-red-500">*</span>
                        </label>
                        <div className="mt-2">
                          <PhoneInput
                            defaultCountry="bj"
                            value={telEntreprise}
                            onChange={(phone) => setTelEntreprise(phone)}
                          />
                        </div>
                      </div>
                      <div className="sm:col-span-3">
                        <label
                          htmlFor="adresse_physique"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Adresse physique de l'entreprise (Carré, Lot, ect...){" "}
                          <span className="text-red-500">*</span>
                        </label>
                        <div className="mt-2">
                          <input
                            type="text"
                            name="adresse_physique"
                            id="adresse_physique"
                            autoComplete="given-name"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                            onChange={(e) => setAdresse(e.target.value)}
                            value={adresse}
                            placeholder="Ex: c/20XX Lot X"
                            required
                          />
                        </div>
                      </div>

                      <div className="sm:col-span-3">
                        <label
                          htmlFor="date_creation"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Date de création de l'entreprise{" "}
                          <span className="text-red-500">*</span>
                        </label>
                        <div className="mt-2">
                          <input
                            id="date_creation"
                            name="date_creation"
                            type="date"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                            onChange={handleCreationYear}
                            value={creationYear}
                            required
                          />
                        </div>
                      </div>

                      <div className="sm:col-span-3">
                        <label
                          htmlFor="anne_effective"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Date de démarrage effectif
                          <span className="text-red-500">*</span>
                        </label>
                        <div className="mt-2">
                          <input
                            id="anne_effective"
                            name="anne_effective"
                            disabled={creationYear ? false : true}
                            type="date"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                            onChange={handleEffectiveStartupYear}
                            value={effectiveStartupYear}
                            required
                          />
                        </div>
                      </div>

                      <div className="sm:col-span-3">
                        <label
                          htmlFor="mail_ent"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Adresse mail de l'entreprise{" "}
                          <span className="text-red-500">*</span>
                        </label>
                        <div className="mt-2">
                          <input
                            id="mail_ent"
                            name="mail_ent"
                            type="email"
                            autoComplete="text"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                            onChange={(e) => setMailEntreprise(e.target.value)}
                            value={mailEntreprise}
                            placeholder="Ex: adpme@gmail.com"
                            required
                          />
                        </div>
                        <p className="flex items-center gap-1 mt-2 text-xs antialiased font-normal leading-normal text-gray-700">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              fill="currentColor"
                              className="w-4 h-4 -mt-px"
                            >
                              <path
                                fillRule="evenodd"
                                d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm8.706-1.442c1.146-.573 2.437.463 2.126 1.706l-.709 2.836.042-.02a.75.75 0 01.67 1.34l-.04.022c-1.147.573-2.438-.463-2.127-1.706l.71-2.836-.042.02a.75.75 0 11-.671-1.34l.041-.022zM12 9a.75.75 0 100-1.5.75.75 0 000 1.5z"
                                clipRule="evenodd"
                              ></path>
                            </svg>
                            Renseignez une adresse mail valide afin de recevoir
                            le récapitulatif de votre demande.
                          </p>
                      </div>

                      <div className="sm:col-span-3">
                        <label
                          htmlFor="website"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Site web de l'entreprise
                        </label>
                        <div className="mt-2 flex items-center">
                          <select
                            className="block basis-1/4 w-full rounded-l-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                            onChange={(e) => setPrefixWebsite(e.target.value)}
                            value={prefixWebsite}
                          >
                            <option selected value="https://">https://</option>
                            <option value="http://">http://</option>
                          </select>
                          <input
                            id="website"
                            name="website"
                            type="text"
                            autoComplete="text"
                            className="block basis-1/2 w-full border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                            onChange={(e) => setWebsite(e.target.value)}
                            value={website}
                            placeholder="monsite"
                          />
                          <select
                            className="block basis-1/4 w-full rounded-r-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                            onChange={(e) => setSuffixWebsite(e.target.value)}
                            value={suffixWebsite}
                            
                          >
                            <option selected value=".bj">.bj</option>
                            <option value=".com">.com</option>
                            <option value=".ca">.ca</option>
                            <option value=".fr">.fr</option>
                          </select>
                        </div>
                      </div>

                      <div className="sm:col-span-3">
                        <label
                          htmlFor="country"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Département 
                        </label>
                        <div className="mt-2">
                          <select
                            id="country"
                            name="country"
                            autoComplete="country-name"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                            onChange={deptChoose}
                            value={dept}
                          >
                            <option disabed selected>
                              Sélectionner un département
                            </option>
                            {depts.length > 0
                              ? depts.map((sp, index) => {
                                  return (
                                    <option key={index} value={sp.id}>
                                      {sp.libelle}
                                    </option>
                                  );
                                })
                              : "Aucun départements"}
                          </select>
                        </div>
                      </div>
                      <div className="sm:col-span-3">
                        <label
                          htmlFor="country"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Commune 
                        </label>
                        <div className="mt-2">
                          <select
                            id="country"
                            name="country"
                            autoComplete="country-name"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                            disabled={dept ? false : true}
                            onChange={comChoose}
                            value={commune}
                          >
                            <option disabed selected>
                              Sélectionner une commune
                            </option>
                            {communesChoose.length > 0
                              ? communesChoose.map((sp, index) => {
                                  return (
                                    <option key={index} value={sp.id}>
                                      {sp.libelle}
                                    </option>
                                  );
                                })
                              : "Aucune commune"}
                          </select>
                        </div>
                      </div>
                      <div className="sm:col-span-3">
                        <label
                          htmlFor="country"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Arrondissement 
                        </label>
                        <div className="mt-2">
                          <select
                            id="country"
                            name="country"
                            autoComplete="country-name"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                            disabled={commune ? false : true}
                            onChange={arrChoose}
                            value={arrondissement}
                          >
                            <option disabed selected>
                              Sélectionner un arrondissement
                            </option>
                            {arrondissementsChoose.length > 0
                              ? arrondissementsChoose.map((sp, index) => {
                                  return (
                                    <option key={index} value={sp.id}>
                                      {sp.libelle}
                                    </option>
                                  );
                                })
                              : "Aucun arrondissement"}
                          </select>
                        </div>
                      </div>

                      <div className="sm:col-span-3">
                        <label
                          htmlFor="country"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Village ou quartier de ville{" "}
                          
                        </label>
                        <div className="mt-2">
                          <select
                            id="country"
                            name="country"
                            autoComplete="country-name"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                            disabled={arrondissement ? false : true}
                            onChange={(e) =>
                              setVillageOuQuartier(e.target.value)
                            }
                            value={villageOuQuartier}
                          >
                            <option disabed selected>
                              Sélectionner votre village ou votre quartier
                            </option>
                            {vilsChoose.length > 0
                              ? vilsChoose.map((sp, index) => {
                                  return (
                                    <option key={index} value={sp.id}>
                                      {sp.libelle}
                                    </option>
                                  );
                                })
                              : "Aucun Village ou quartier"}
                          </select>
                        </div>
                      </div>

                      <div className="sm:col-span-6">
                        <label
                          htmlFor="ideas"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Secteurs d'activités{" "}
                          <span className="text-red-500">*</span>
                        </label>
                        <select
                          id="country"
                          name="country"
                          autoComplete="country-name"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                          onChange={sectChoose}
                          value={secteur}
                          required
                        >
                          <option value={0} disabed selected>
                            Sélectionner le secteur de votre entreprise
                          </option>
                          {secteurs.length > 0
                            ? secteurs.map((sp, index) => {
                                return (
                                  <option key={index} value={sp.id}>
                                    {sp.libelleSecteur}
                                  </option>
                                );
                              })
                            : "Aucun Secteur ou quartier"}
                        </select>
                      </div>

                      {secteur ? (
                        <>
                          <div className="sm:col-span-6">
                            <div className="mb-2 block">
                              <Label
                                htmlFor="sous-secteur"
                                value={
                                  <span className="font-bold">
                                    Sous-secteur (Vous pouvez faire plusieurs
                                    sélection){" "}
                                    <span className="text-red-500">*</span>
                                  </span>
                                }
                              />
                            </div>
                            {sousSecteursChoose.map((sec, index) => (
                              <div
                                key={index}
                                className="flex items-center ps-4 border border-gray-200 rounded dark:border-gray-700"
                              >
                                <input
                                  renderValue={(selected) =>
                                    selected.join(", ")
                                  }
                                  onChange={handleChangeSSecteur}
                                  value={sec.libelle}
                                  id={sec.code}
                                  type="checkbox"
                                  name={sec.code}
                                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                />
                                <label
                                  for={sec.code}
                                  className="w-full py-4 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                >
                                  {sec.libelle}
                                </label>
                              </div>
                            ))}
                          </div>
                          <div className="sm:col-span-6 mt-2">
                            <div>
                              <label
                                htmlFor="othersecteur"
                                className="block text-sm font-medium leading-6 text-gray-900"
                              >
                                Précisez le sous-secteur de votre entreprise
                              </label>
                              <div className="mt-2">
                                <textarea
                                  name="othersecteur"
                                  id="othersecteur"
                                  autoComplete="given-name"
                                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                                  onChange={(e) =>
                                    setOtherSecteurText(e.target.value)
                                  }
                                  value={otherSecteurText}
                                  disabled={!otherSecteur ? true : false}
                                />
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        ""
                      )}

                      <div className="sm:col-span-6">
                        <label
                          htmlFor="rccm"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          N° RCCM{" "}
                          {statutEnt == "INFORMELLE" ? (
                            ""
                          ) : (
                            <span className="text-red-500">*</span>
                          )}
                        </label>
                        <div className="mt-2 flex items-center">
                          <input disabled value="RB" className='w-6'/>
                          <div className='px-2'>/</div>
                          <input 
                          placeholder='COT, ABOMEY ...'
                          onChange={(e) => setNumeroRCCM1(e.target.value)}
                          value={numeroRCCM1}
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                          type="text"
                          required={statutEnt == "FORMELLE"}
                          />
                          <div className='px-2'>/</div>
                          <div className='pr-2'>
                            <input 
                            type="number"
                            onChange={(e) => setNumeroRCCM2(e.target.value)}
                            value={numeroRCCM2}
                            placeholder='XX'
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                            required={statutEnt == "FORMELLE"}
                            />
                          </div>
                          <div className='pr-2'>
                            <input 
                            type="text"
                            onChange={(e) => setNumeroRCCM3(e.target.value)}
                            value={numeroRCCM3}
                            placeholder='A, B ...'
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                            required={statutEnt == "FORMELLE"}
                            />
                          </div>
                          <input
                            type="number"
                            name="rccm"
                            id="rccm"
                            autoComplete="given-name"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                            onChange={(e) => setNumeroRCCM4(e.target.value)}
                            value={numeroRCCM4}
                            // placeholder="XXXX"
                            required={statutEnt == "FORMELLE"}
                          />
                        </div>
                      </div>

                      <div className="sm:col-span-3">
                        <label
                          htmlFor="ifu"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          N° IFU <span className="text-red-500">*</span>
                        </label>
                        <div className="mt-2">
                          <input
                            type="number"
                            name="ifu"
                            id="ifu"
                            autoComplete="given-name"
                            className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ${ifu.length > 13 ? 'border-red-500 text-red-500 focus:ring-red-500' : 'ring-gray-300 focus:ring-amber-600'} placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6`}
                            onChange={(e) => setIfu(e.target.value)}
                            value={ifu}
                            placeholder=""
                            required
                            onBlur={(e) => {
                                if (ifu.length > 13) {
                                    e.target.focus();
                                }
                            }}
                            min="0"
                          />
                        </div>
                        <div className='grid grid-cols-2'>
                          <p className={`flex items-center gap-1 mt-2 text-xs antialiased font-normal leading-normal ${ifu.length > 13 ? 'text-red-500' : 'text-gray-700'}`}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              fill="currentColor"
                              className="w-4 h-4 -mt-px"
                            >
                              <path
                                fillRule="evenodd"
                                d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm8.706-1.442c1.146-.573 2.437.463 2.126 1.706l-.709 2.836.042-.02a.75.75 0 01.67 1.34l-.04.022c-1.147.573-2.438-.463-2.127-1.706l.71-2.836-.042.02a.75.75 0 11-.671-1.34l.041-.022zM12 9a.75.75 0 100-1.5.75.75 0 000 1.5z"
                                clipRule="evenodd"
                              ></path>
                            </svg>
                            Votre numéro IFU doit comporter 13 chiffres
                          </p>
                          <div className={`flex justify-end ${ifu.length > 13 ? 'text-red-500' : 'text-gray-700'}`}>
                            <div>
                              {ifu.length ? ifu.length : 0} / 13
                            </div>
                          </div>
                        </div>

                      </div>

                      <div className="sm:col-span-3">
                        <label
                          htmlFor="bp"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Boîte postale
                        </label>
                        <div className="mt-2">
                          <input
                            type="text"
                            name="bp"
                            id="bp"
                            autoComplete="given-name"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                            onChange={(e) => setBp(e.target.value)}
                            value={bp}
                            placeholder="Ex: BP XXXXX"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="border-b border-gray-900/10 pb-12">
                    <h2 className="text-2xl font-semibold leading-7 text-neutral-900 pb-3">
                      Potentiel commercial et stratégie de croissance
                    </h2>
                    <p className="mt-1 text-2xs leading-6 text-gray-600">
                      Soumettez vos chiffres d'affaires les deux dernières
                      années et les pièces jointes.
                    </p>

                    <div>
                      <span>
                        Quel est le montant de votre chiffre annuel moyen au
                        cours des deux dernières années et le nombre d'employé
                        que vous avez ? (en FCFA){" "}
                        <span className="text-red-500">*</span>
                      </span>
                      <fieldset className="border border-solid border-gray-300 p-3">
                        <legend>
                          <div>
                            <span className="font-bold">{currentYear - 2}</span>
                          </div>
                        </legend>
                        <div className="mt-2">
                          <div>
                            <label
                              htmlFor="firstyearca"
                              className="block text-sm font-medium leading-6 text-gray-900"
                            >
                              Indiquer le chiffre d'affaire
                            </label>
                            <div className="mt-2">
                              <input
                                type="number"
                                name="firstyearca"
                                id="firstyearca"
                                autoComplete="given-name"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                                onChange={(e) => setFirstYearCA(e.target.value)}
                                value={firstYearCA}
                                placeholder="Ex: 520XXXXXX"
                                min="0"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="mt-2">
                          <div>
                            <label
                              htmlFor="firstyearempl"
                              className="block text-sm font-medium leading-6 text-gray-900"
                            >
                              Indiquer le nombre d'employé
                            </label>
                            <div className="mt-2">
                              <input
                                type="number"
                                name="firstyearempl"
                                id="firstyearempl"
                                autoComplete="given-name"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                                onChange={(e) =>
                                  setFirstYearEmployer(e.target.value)
                                }
                                value={firstYearEmployer}
                                min="0"
                              />
                            </div>
                          </div>
                        </div>
                      </fieldset>
                      <br />
                      <fieldset className="border border-solid border-gray-300 p-3">
                        <legend>
                          <div>
                            <span className="font-bold">{currentYear - 1}</span>
                          </div>
                        </legend>
                        <div className="mt-2">
                          <div>
                            <label
                              htmlFor="firstyearca"
                              className="block text-sm font-medium leading-6 text-gray-900"
                            >
                              Indiquer le chiffre d'affaire
                            </label>
                            <div className="mt-2">
                              <input
                                type="number"
                                name="firstyearca"
                                id="firstyearca"
                                autoComplete="given-name"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                                onChange={(e) =>
                                  setSecondYearCA(e.target.value)
                                }
                                value={secondYearCA}
                                placeholder="Ex: 520XXXXXX"
                                min="0"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="mt-2">
                          <div>
                            <label
                              htmlFor="secondyearempl"
                              className="block text-sm font-medium leading-6 text-gray-900"
                            >
                              Indiquer le nombre d'employé
                            </label>
                            <div className="mt-2">
                              <input
                                type="number"
                                name="secondyearempl"
                                id="secondyearempl"
                                autoComplete="given-name"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                                onChange={(e) =>
                                  setSecondYearEmployer(e.target.value)
                                }
                                value={secondYearEmployer}
                                min="0"
                              />
                            </div>
                          </div>
                        </div>
                      </fieldset>
                    </div>
                    <div className="col-span-full mt-5">
                      <label
                        htmlFor="rccm"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        RCCM{" "}
                        {statutEnt == "INFORMELLE" ? (
                          ""
                        ) : (
                          <span className="text-red-500">*</span>
                        )}
                      </label>
                      <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                        <div className="text-center">
                          <div className="mt-4 flex text-sm leading-6 text-gray-600">
                            <label
                              htmlFor="file-rccm-upload"
                              className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                            >
                              <span>Glisser un fichier</span>
                              <input
                                required={statutEnt == "FORMELLE"}
                                accept=".pdf, .jpg, .jpeg, .png, .PNG, .ZIP, .zip"
                                onChange={handleFileChangeRccm}
                                id="file-rccm-upload"
                                name="file-rccm-upload"
                                type="file"
                                className="sr-only"
                              />
                            </label>
                          </div>
                          <p className="text-xs leading-5 text-gray-600">
                            Max 5MB
                          </p>
                        </div>
                      </div>
                      <p className="text-md leading-5 text-green-700 font-bold">
                        {selectedFileRccm ? selectedFileRccm.name : ""}
                      </p>
                    </div>
                    <div className="col-span-full mt-5">
                      <label
                        htmlFor="file-ifu-upload"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        IFU <span className="text-red-500">*</span>
                      </label>
                      <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                        <div className="text-center">
                          <div className="mt-4 flex text-sm leading-6 text-gray-600">
                            <label
                              htmlFor="file-ifu-upload"
                              className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                            >
                              <span>Glisser un fichier</span>
                              <input
                                required
                                onChange={handleFileChangeIfu}
                                accept=".pdf, .jpg, .jpeg, .png, .PNG, .ZIP, .zip"
                                id="file-ifu-upload"
                                name="file-ifu-upload"
                                type="file"
                                className="sr-only"
                              />
                            </label>
                          </div>
                          <p className="text-xs leading-5 text-gray-600">
                            Max 5MB
                          </p>
                        </div>
                      </div>
                      <p className="text-md leading-5 text-green-700 font-bold">
                        {selectedFileIfu ? selectedFileIfu.name : ""}
                      </p>
                    </div>
                    <div className="col-span-full mt-5">
                      <label
                        htmlFor="file-cnss-upload"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Certificat CNSS
                      </label>
                      <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                        <div className="text-center">
                          <div className="mt-4 flex text-sm leading-6 text-gray-600">
                            <label
                              htmlFor="file-cnss-upload"
                              className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                            >
                              <span>Glisser un fichier</span>
                              <input
                                onChange={handleFileChangeCnss}
                                accept=".pdf, .jpg, .jpeg, .png, .PNG, .ZIP, .zip"
                                id="file-cnss-upload"
                                name="file-cnss-upload"
                                type="file"
                                className="sr-only"
                              />
                            </label>
                          </div>
                          <p className="text-xs leading-5 text-gray-600">
                            Max 5MB
                          </p>
                        </div>
                      </div>
                      <p className="text-md leading-5 text-green-700 font-bold">
                        {selectedFileCnss ? selectedFileCnss.name : ""}
                      </p>
                    </div>
                    <div className="col-span-full mt-5">
                      <label
                        htmlFor="file-fiscale-upload"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Certificat Fiscale
                      </label>
                      <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                        <div className="text-center">
                          <div className="mt-4 flex text-sm leading-6 text-gray-600">
                            <label
                              htmlFor="file-fiscale-upload"
                              className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                            >
                              <span>Glisser un fichier</span>
                              <input
                                onChange={handleFileChangeFiscale}
                                accept=".pdf, .jpg, .jpeg, .png, .PNG, .ZIP, .zip"
                                id="file-fiscale-upload"
                                name="file-fiscale-upload"
                                type="file"
                                className="sr-only"
                              />
                            </label>
                          </div>
                          <p className="text-xs leading-5 text-gray-600">
                            Max 5MB
                          </p>
                        </div>
                      </div>
                      <p className="text-md leading-5 text-green-700 font-bold">
                        {selectedFileFiscale ? selectedFileFiscale.name : ""}
                      </p>
                    </div>
                    <div className="col-span-full mt-5">
                      <label
                        htmlFor="file-businessplan-upload"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Plan d'affaire{" "}
                        {statutEnt == "FORMELLE" ? (
                          ""
                        ) : (
                          <span className="text-red-500">*</span>
                        )}
                      </label>
                      <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                        <div className="text-center">
                          <div className="mt-4 flex text-sm leading-6 text-gray-600">
                            <label
                              htmlFor="file-businessplan-upload"
                              className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                            >
                              <span>Glisser un fichier</span>
                              <input
                                required={statutEnt == "INFORMELLE"}
                                accept=".pdf, .jpg, .jpeg, .png, .PNG, .ZIP, .zip"
                                onChange={handleFileChangeBusiness}
                                id="file-businessplan-upload"
                                name="file-businessplan-upload"
                                type="file"
                                className="sr-only"
                              />
                            </label>
                          </div>
                          <p className="text-xs leading-5 text-gray-600">
                            Max 5MB
                          </p>
                        </div>
                      </div>
                      <p className="text-md leading-5 text-green-700 font-bold">
                        {selectedFileBusiness ? selectedFileBusiness.name : ""}
                      </p>
                    </div>
                    <div className="col-span-full mt-5">
                      <label
                        htmlFor="carte"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Carte d'identité <span className="text-red-500">*</span>
                      </label>
                      <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                        <div className="text-center">
                          <div className="mt-4 flex text-sm leading-6 text-gray-600">
                            <label
                              htmlFor="file-upload"
                              className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                            >
                              <span>Glisser un fichier</span>
                              <input
                                required
                                onChange={handleFileChangeIdentite}
                                accept=".pdf, .jpg, .jpeg, .png, .PNG, .ZIP, .zip"
                                id="file-upload"
                                name="file-upload"
                                type="file"
                                className="sr-only"
                              />
                            </label>
                          </div>
                          <p className="text-xs leading-5 text-gray-600">
                            Max 5MB
                          </p>
                        </div>
                      </div>
                      <p className="text-md leading-5 text-green-700 font-bold">
                        {selectedFileIdentity ? selectedFileIdentity.name : ""}
                      </p>
                    </div>
                    <div className="mt-3">
                      <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey="6LdP_pgpAAAAAJjKUM4S7n5bvzAqXI0HaYv5hYeT"
                        onChange={onChangeRecaptcha}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>

            <div className="mt-6 flex items-center justify-end gap-x-6">
              <Button
                variant="outlined"
                disabled={isClicked}
                type="reset"
                className="rounded-full"
              >
                Abandonner
              </Button>
              <Button
                variant="gradient"
                disabled={!verified}
                type="submit"
                className="rounded-full bg-green-700"
              >
                Valider
              </Button>
            </div>
          </form>
        )}
        {offre.typeDeRecru == "PERSON" && (
          <form onSubmit={handleSubmitPerson}>
            <div className="space-y-12">
              <div>
                <Link to="/recrutement/recrutementperson">
                  <Button className="bg-green-700 text-white flex items-center gap-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      class="h-5 w-5"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18"
                      />
                    </svg>
                    Retour
                  </Button>
                </Link>
              </div>
              <h2 className="text-4xl font-semibold leading-relaxed text-neutral-900 pb-3 font-[' Montserrat']">
                Candidature pour le poste de :{" "}
                {offre ? (
                  <span className="text-green-700">{offre.poste}</span>
                ) : (
                  "..."
                )}
              </h2>
              <br />
              <div className="border-b border-gray-900/10 pb-12">
                <h2 className="text-2xl font-semibold leading-7 text-neutral-900 pb-3 font-[' Montserrat']">
                  Informations personnelles
                </h2>
                {/* <p className="mt-1 text-2xs leading-6 text-gray-500 font-[' Montserrat']">Utilisez une adresse permanente où vous pouvez recevoir du courrier.</p> */}

                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                  <div className="sm:col-span-3">
                    <label
                      htmlFor="first-name"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Prénom(s) <span className="text-red-500">*</span>
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        name="first-name"
                        id="first-name"
                        autoComplete="given-name"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                        onChange={(e) => setPrenom(e.target.value)}
                        value={prenom}
                        required
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-3">
                    <label
                      htmlFor="last-name"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Nom <span className="text-red-500">*</span>
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        name="last-name"
                        id="last-name"
                        autoComplete="family-name"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                        onChange={(e) => setNom(e.target.value)}
                        value={nom}
                        required
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-3">
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Adresse mail <span className="text-red-500">*</span>
                    </label>
                    <div className="mt-2">
                      <input
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                        onChange={(e) => setMail(e.target.value)}
                        value={mail}
                        required
                      />
                      <p className="flex items-center gap-1 mt-2 text-xs antialiased font-normal leading-normal text-gray-700">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          className="w-4 h-4 -mt-px"
                        >
                          <path
                            fillRule="evenodd"
                            d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm8.706-1.442c1.146-.573 2.437.463 2.126 1.706l-.709 2.836.042-.02a.75.75 0 01.67 1.34l-.04.022c-1.147.573-2.438-.463-2.127-1.706l.71-2.836-.042.02a.75.75 0 11-.671-1.34l.041-.022zM12 9a.75.75 0 100-1.5.75.75 0 000 1.5z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                        Renseignez une adresse mail valide afin de recevoir le
                        récapitulatif de votre demande.
                      </p>
                    </div>
                  </div>

                  <div className="sm:col-span-3">
                    <label
                      htmlFor="num"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Numéro de télephone mobile{" "}
                      <span className="text-red-500">*</span>
                    </label>
                    <div className="mt-2">
                      <PhoneInput
                        defaultCountry="bj"
                        value={tel}
                        onChange={(phone) => setTel(phone)}
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-3">
                    <label
                      htmlFor="country"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Département
                    </label>
                    <div className="mt-2">
                      <select
                        id="country"
                        name="country"
                        autoComplete="country-name"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                        onChange={deptChoose}
                        value={dept}
                      >
                        <option disabed selected>
                          Sélectionner un département
                        </option>
                        {depts.length > 0
                          ? depts.map((sp, index) => {
                              return (
                                <option key={index} value={sp.id}>
                                  {sp.libelle}
                                </option>
                              );
                            })
                          : "Aucun départements"}
                      </select>
                    </div>
                  </div>
                  <div className="sm:col-span-3">
                    <label
                      htmlFor="country"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Commune
                    </label>
                    <div className="mt-2">
                      <select
                        id="country"
                        name="country"
                        autoComplete="country-name"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                        disabled={dept ? false : true}
                        onChange={comChoose}
                        value={commune}
                      >
                        <option disabed selected>
                          Sélectionner une commune
                        </option>
                        {communesChoose.length > 0
                          ? communesChoose.map((sp, index) => {
                              return (
                                <option key={index} value={sp.id}>
                                  {sp.libelle}
                                </option>
                              );
                            })
                          : "Aucune commune"}
                      </select>
                    </div>
                  </div>
                  <div className="sm:col-span-3">
                    <label
                      htmlFor="country"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Arrondissement
                    </label>
                    <div className="mt-2">
                      <select
                        id="country"
                        name="country"
                        autoComplete="country-name"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                        disabled={commune ? false : true}
                        onChange={arrChoose}
                        value={arrondissement}
                      >
                        <option disabed selected>
                          Sélectionner un arrondissement
                        </option>
                        {arrondissementsChoose.length > 0
                          ? arrondissementsChoose.map((sp, index) => {
                              return (
                                <option key={index} value={sp.id}>
                                  {sp.libelle}
                                </option>
                              );
                            })
                          : "Aucun arrondissement"}
                      </select>
                    </div>
                  </div>

                  <div className="sm:col-span-3">
                    <label
                      htmlFor="country"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Village ou quartier de ville{" "}
                    </label>
                    <div className="mt-2">
                      <select
                        id="country"
                        name="country"
                        autoComplete="country-name"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                        disabled={arrondissement ? false : true}
                        onChange={(e) => setVillageOuQuartier(e.target.value)}
                        value={villageOuQuartier}
                      >
                        <option disabed selected>
                          Sélectionner votre village ou votre quartier
                        </option>
                        {vilsChoose.length > 0
                          ? vilsChoose.map((sp, index) => {
                              return (
                                <option key={index} value={sp.id}>
                                  {sp.libelle}
                                </option>
                              );
                            })
                          : "Aucun Village ou quartier"}
                      </select>
                    </div>
                  </div>
                  <div className="col-span-full mt-5">
                    <label
                      htmlFor="file-cv-upload"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Curriculum Vitae <span className="text-red-500">*</span>
                    </label>
                    <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                      <div className="text-center">
                        <div className="mt-4 flex text-sm leading-6 text-gray-600">
                          <label
                            htmlFor="file-cv-upload"
                            className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                          >
                            <span>Glisser un fichier</span>
                            <input
                              required
                              onChange={handleFileChangeCV}
                              id="file-cv-upload"
                              accept=".pdf, .jpg, .jpeg, .png, .PNG, .ZIP, .zip"
                              name="file-cv-upload"
                              type="file"
                              className="sr-only"
                            />
                          </label>
                        </div>
                        <p className="text-xs leading-5 text-gray-600">
                          Max 5MB
                        </p>
                      </div>
                    </div>
                    <p className="text-md leading-5 text-green-700 font-bold">
                      {selectedFileCV ? selectedFileCV.name : ""}
                    </p>
                  </div>
                  <div className="col-span-full mt-5">
                    <label
                      htmlFor="file-Lettre-upload"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Lettre de motivation{" "}
                      <span className="text-red-500">*</span>
                    </label>
                    <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                      <div className="text-center">
                        <div className="mt-4 flex text-sm leading-6 text-gray-600">
                          <label
                            htmlFor="file-Lettre-upload"
                            className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                          >
                            <span>Glisser un fichier</span>
                            <input
                              required
                              onChange={handleFileChangeLettre}
                              id="file-Lettre-upload"
                              accept=".pdf, .jpg, .jpeg, .png, .PNG, .ZIP, .zip"
                              name="file-Lettre-upload"
                              type="file"
                              className="sr-only"
                            />
                          </label>
                        </div>
                        <p className="text-xs leading-5 text-gray-600">
                          Max 5MB
                        </p>
                      </div>
                    </div>
                    <p className="text-md leading-5 text-green-700 font-bold">
                      {selectedFileLettre ? selectedFileLettre.name : ""}
                    </p>
                  </div>
                  <div className="col-span-full mt-5">
                    <label
                      htmlFor="file-Other-upload"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Autre fichier
                    </label>
                    <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                      <div className="text-center">
                        <div className="mt-4 flex text-sm leading-6 text-gray-600">
                          <label
                            htmlFor="file-Other-upload"
                            className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                          >
                            <span>Glisser un fichier</span>
                            <input
                              onChange={handleFileChangeOther}
                              id="file-Other-upload"
                              accept=".pdf, .jpg, .jpeg, .png, .PNG, .ZIP, .zip"
                              name="file-Other-upload"
                              type="file"
                              className="sr-only"
                            />
                          </label>
                        </div>
                        <p className="text-xs leading-5 text-gray-600">
                          Max 5MB
                        </p>
                      </div>
                    </div>
                    <p className="text-md leading-5 text-green-700 font-bold">
                      {selectedFileOther ? selectedFileOther.name : ""}
                    </p>
                  </div>
                  <div className="mt-3">
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey="6LdP_pgpAAAAAJjKUM4S7n5bvzAqXI0HaYv5hYeT"
                      onChange={onChangeRecaptcha}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-6 flex items-center justify-end gap-x-6">
              <Button
                variant="outlined"
                disabled={isClicked}
                type="reset"
                className="rounded-full"
              >
                Abandonner
              </Button>
              <Button
                variant="gradient"
                disabled={!verified}
                type="submit"
                className="rounded-full bg-green-700"
              >
                Valider
              </Button>
            </div>
          </form>
        )}
        {/* Modal success */}
        <Modal
          show={isModalShow}
          size="2xl"
          popup
          onClose={() => ModalClose}
          position="center"
        >
          {/* <Modal.Header>Document Viewer</Modal.Header> */}
          <Modal.Body>
            <div className="flex flex-row gap-2 px-10 pt-16 max-md:px-4 max-md:pt-4 max-md:flex max-md:flex-col">
              <div className="text-center flex justify-center items-center">
                <img
                  className="max-md:w-[50%] max-md:h-[50%]"
                  src={imgmodalsuccess}
                />
              </div>
              <div className="basis-1/2 text-center justify-center items-center flex flex-col">
                <div className="font-['Montserrat'] mb-3 font-bold text-2xl max-md:text-xl">
                  Félicitation !
                </div>
                <div className="font-['Montserrat'] mb-3 font-normal text-3sm">
                  Candiature soumise avec succès
                </div>
                <div>
                  <Button
                    onClick={ModalClose}
                    variant="gradient"
                    className="rounded-full text-white flex items-center gap-2 justify-center text-center bg-green-700"
                  >
                    Fermer
                  </Button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
}//detpt, commune, arrondissement, village ou quartiers et adresse(carré et autre), ajouter capital sociale(étape deux), nbre employer (étape 3), donner la possibilité d'enregistrer les associées selon si l'entreprise existe