import React, {useEffect, useState} from 'react';
import axios from 'axios'

const urlstandart = process.env.REACT_APP_URL_STANDART

function LesCommunique() {
    const [communiques, setCommuniques] = useState([]);

    useEffect(() => {
        const newurl = process.env.REACT_APP_URL_STANDART + "api/communiques"
        axios.get(newurl, { withCredentials: true })
            .then(response => {
                setCommuniques(response.data.data.communiques)
            })
            .catch(err => {
                alert('Erreur lors de la récupération des informations')
            })
    }, [])
    return (
        <>
            <div className='max-xl:flex max-xl:items-center max-xl:justify-center max-xl:h-screen max-xl:px-4 px-20 mx-auto max-w-screen-xl py-24 lg:py-15 sm:py-15 max-xl:text-left text-left'>
                {communiques.length <= 0 ? (<span>Aucun Communiqué pour le moment</span>) : 
                    <div className='lg:grid lg:grid-cols-4 lg:gap-4 max-xl:space-y-2'>
                        {communiques.map((el) => {
                            let imginfo = el.actualite.image
                            let tabimg = []
                            tabimg = imginfo.split(',')
                            imginfo = tabimg[0]
                            imginfo = imginfo.replace(/\\/g, "/")
                            
                            const dn = new Date(el.createdAt)
                            const startdate = dn.toISOString().split('T')[0]
                            return (
                                <div key={el.id} className="max-w-sm max-xl:bg-gray-50 bg-white max-xl:border max-xl:rounded">
                                    <a href={`/details-communiques/${el.actualite.title}`}>
                                        {el.actualite.image ? (<img crossOrigin="anonymous" className='w-full h-28' src={`${urlstandart}api/${imginfo}`} elt="" />) : ("")}
                                    </a>
                                    <div className="p-5">
                                        <p>
                                            <span className='inline-flex'>
                                                <svg
                                                    fill="#000000"
                                                    width={21} height={21}
                                                    viewBox="0 0 1024 1024"
                                                    xmlns="http://www.w3.org/2000/svg"

                                                >
                                                    <path d="M960 95.888l-256.224.001V32.113c0-17.68-14.32-32-32-32s-32 14.32-32 32v63.76h-256v-63.76c0-17.68-14.32-32-32-32s-32 14.32-32 32v63.76H64c-35.344 0-64 28.656-64 64v800c0 35.343 28.656 64 64 64h896c35.344 0 64-28.657 64-64v-800c0-35.329-28.656-63.985-64-63.985zm0 863.985H64v-800h255.776v32.24c0 17.679 14.32 32 32 32s32-14.321 32-32v-32.224h256v32.24c0 17.68 14.32 32 32 32s32-14.32 32-32v-32.24H960v799.984zM736 511.888h64c17.664 0 32-14.336 32-32v-64c0-17.664-14.336-32-32-32h-64c-17.664 0-32 14.336-32 32v64c0 17.664 14.336 32 32 32zm0 255.984h64c17.664 0 32-14.32 32-32v-64c0-17.664-14.336-32-32-32h-64c-17.664 0-32 14.336-32 32v64c0 17.696 14.336 32 32 32zm-192-128h-64c-17.664 0-32 14.336-32 32v64c0 17.68 14.336 32 32 32h64c17.664 0 32-14.32 32-32v-64c0-17.648-14.336-32-32-32zm0-255.984h-64c-17.664 0-32 14.336-32 32v64c0 17.664 14.336 32 32 32h64c17.664 0 32-14.336 32-32v-64c0-17.68-14.336-32-32-32zm-256 0h-64c-17.664 0-32 14.336-32 32v64c0 17.664 14.336 32 32 32h64c17.664 0 32-14.336 32-32v-64c0-17.68-14.336-32-32-32zm0 255.984h-64c-17.664 0-32 14.336-32 32v64c0 17.68 14.336 32 32 32h64c17.664 0 32-14.32 32-32v-64c0-17.648-14.336-32-32-32z" />
                                                </svg>
                                                &nbsp;
                                                <span className='text-gray-500 text-sm'><i>{startdate}</i></span>
                                            </span>
                                        </p>
                                        <a href={`/details-communiques/${el.actualite.title}`}>
                                            <h5 className="truncate overflow-ellipsis mb-2 text-2sm font-bold tracking-tight text-black-900 dark:text-white">{el.actualite.title}</h5>
                                        </a>
                                        <a className='hover:underline inline-flex text-amber-700 items-center' href={`/details-communiques/${el.actualite.title}`}>
                                            <span>Lire l'article</span>
                                            <svg className="w-3 h-3 ms-2.5 rtl:rotate-[270deg]" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
                                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 11v4.833A1.166 1.166 0 0 1 13.833 17H2.167A1.167 1.167 0 0 1 1 15.833V4.167A1.166 1.166 0 0 1 2.167 3h4.618m4.447-2H17v5.768M9.111 8.889l7.778-7.778" />
                                            </svg>
                                        </a>

                                    </div>
                                    <br />
                                </div>
                            )
                        })}
                    </div>
                }
            </div>
        </>
    );
}
// Ancienne version
// {/* <div className='text-left'>
//                                     <Card
//                                         className="max-w-sm animate-once animate-duration-1000 animate-delay-300"
//                                         key={el.id}
//                                     >
//                                         <img src={el.actualite.image} />
//                                         <h5 className="text-sm font-bold tracking-tight text-black dark:text-white">
//                                             <span className='inline-flex'>
//                                                 <img className='w-5 h-5' src={cal} />&nbsp;
//                                                 <span className='text-gray-500 text-sm'><i>{el.date}</i></span>
//                                             </span>
//                                         </h5>
//                                         <p className="truncate overflow-ellipsis text-2sm text-black dark:text-black font-bold">
//                                             {el.actualite.title}
//                                         </p>
//                                         <a className='hover:underline inline-flex text-amber-700 items-center' href={`/details-communiques/${el.actualite.title}`}>
//                                             <span>Lire l'article</span>
//                                             <svg className="w-3 h-3 ms-2.5 rtl:rotate-[270deg]" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
//                                                 <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 11v4.833A1.166 1.166 0 0 1 13.833 17H2.167A1.167 1.167 0 0 1 1 15.833V4.167A1.166 1.166 0 0 1 2.167 3h4.618m4.447-2H17v5.768M9.111 8.889l7.778-7.778" />
//                                             </svg>
//                                         </a>
//                                     </Card>
//                                 </div> */}
export default LesCommunique;