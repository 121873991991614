import React,{useState} from 'react';
import axios from 'axios';
import { Button } from "@material-tailwind/react";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import img from '../Images/imgcontact.jpeg'
import { Modal } from 'flowbite-react'
import plainteimg from '../Images/Plainte.PNG'
import ReCAPTCHA from "react-google-recaptcha";

function ContactMail(props) {
    const [nom, setNom] = useState('');
    const [mail, setMail] = useState('');
    const [tel, setTel] = useState('');
    const [sujet, setSujet] = useState('');
    const [msg, setMsg] = useState('');
    const [isClicked, setIsClicked] = useState(false);
    const [verified, setVerified] = useState(false);
    const [isModalShow, setIsModalShow] = useState(false)

    const LoginForm = (e) => {
        e.preventDefault();
        console.log(nom);
        console.log(mail);
        console.log(tel);
        console.log(msg);
        const newurl = process.env.REACT_APP_URL_STANDART + 'api/sendMail'
        console.log(newurl)
        setIsClicked(true)

        axios.post(newurl,
            { subject: sujet, from: "admresociaux@gmail.com", text: mail + '\n\n' + nom + ' ' + tel + '\n\n' + msg, to: "contact@adpme.bj" })
            .then(data => {
                setNom('')
                setMail('')
                setTel('')
                setMsg('')
                setSujet('')
                setIsModalShow(true)
                setIsClicked(false)
            })
            .catch(err => {
                alert('Erreur... Veuillez réessayer plus tard.')
                setIsClicked(false)
                setNom('')
                setMail('')
                setTel('')
                setMsg('')
                setSujet('')
                //window.location.reload()
            })
    }
    const ModalClose = () => {
        setIsModalShow(false)
        window.location.reload()
    }
    function onChangeRecaptcha(value) {
        setVerified(!verified)
    }

    return (
        <>
            <div className="lg:py-16 lg:pt-24 font-['Montserrat']">
                <div className="grid grid-cols-2 gap-4 max-md:flex max-md:flex-col">
                    <div>
                        <div className="font-bold text-3xl text-neutral-900 pb-3 max-md:text-2xl">Nos coordonnées</div>
                        <div className="font-semibold text-xl text-neutral-900 pb-3">Adresse</div>
                        <div className="text-5xs max-md:text-2xs text-neutral-900 pb-5">
                            Immeuble R+5 C/1184 Cadjèhoun 01 BP 8435 Cotonou
                        </div>
                        <div className="font-semibold text-xl text-neutral-900 pb-3">Contact</div>
                        <div className="font-normal text-5xs text-neutral-900 pb-3">Numéro de téléphone : <span className='font-semibold'>+229 41 29 27 22</span></div>
                        <div className="font-normal text-5xs text-neutral-900">Adresse E-mail : <span className='font-semibold underline'>contact@adpme.bj</span></div>
                        <div>
                            <ul className="flex mt-5 space-x-5">
                                <li>
                                    <a target='_blank' href="https://www.facebook.com/adpmebenin?mibextid=ViGcVu" className="text-gray-500 hover:text-gray-900 dark:hover:text-white dark:text-gray-400">
                                        <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true"><path fillRule="evenodd" d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" clipRule="evenodd" /></svg>
                                    </a>
                                </li>
                                <li>
                                    <a target='_blank' href="https://www.linkedin.com/company/adpme-benin/" className="text-gray-500 hover:text-gray-900 dark:hover:text-white dark:text-gray-400">
                                        <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0,0,256,256"
                                            style={{ fill: "#737373" }}>
                                            <g fill="#737373" fillRule="nonzero" stroke="none" strokeWidth="1" strokeLinecap="butt" strokeLinejoin="miter" strokeMiterlimit="10" strokeDasharray="" strokeDashoffset="0" style={{ mixBlendMode: "normal" }}><g transform="scale(8.53333,8.53333)"><path d="M24,4h-18c-1.105,0 -2,0.895 -2,2v18c0,1.105 0.895,2 2,2h18c1.105,0 2,-0.895 2,-2v-18c0,-1.105 -0.895,-2 -2,-2zM10.954,22h-2.95v-9.492h2.95zM9.449,11.151c-0.951,0 -1.72,-0.771 -1.72,-1.72c0,-0.949 0.77,-1.719 1.72,-1.719c0.948,0 1.719,0.771 1.719,1.719c0,0.949 -0.771,1.72 -1.719,1.72zM22.004,22h-2.948v-4.616c0,-1.101 -0.02,-2.517 -1.533,-2.517c-1.535,0 -1.771,1.199 -1.771,2.437v4.696h-2.948v-9.492h2.83v1.297h0.04c0.394,-0.746 1.356,-1.533 2.791,-1.533c2.987,0 3.539,1.966 3.539,4.522z"></path></g></g>
                                        </svg>
                                    </a>
                                </li>
                                <li>
                                    <a target='_blank' href="https://www.youtube.com/@adpmebenin" className="text-gray-500 hover:text-gray-900 dark:hover:text-white dark:text-gray-400">
                                        <svg className="w-5 h-5 hover:text-text-gray-900" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 16 16" style={{ fill: "#737373" }}>
                                            <path d="M 8 2 C 5.960938 2 4.15625 2.210938 3.058594 2.375 C 2.160156 2.507813 1.445313 3.210938 1.28125 4.113281 C 1.140625 4.902344 1 6.054688 1 7.5 C 1 8.945313 1.140625 10.097656 1.28125 10.886719 C 1.445313 11.789063 2.160156 12.488281 3.058594 12.625 C 4.160156 12.789063 5.972656 13 8 13 C 10.027344 13 11.835938 12.789063 12.9375 12.625 L 12.941406 12.625 C 13.839844 12.492188 14.554688 11.789063 14.71875 10.886719 C 14.859375 10.09375 15 8.941406 15 7.5 C 15 6.054688 14.859375 4.902344 14.714844 4.113281 C 14.554688 3.210938 13.839844 2.507813 12.941406 2.375 C 11.84375 2.210938 10.039063 2 8 2 Z M 8 3 C 9.96875 3 11.730469 3.203125 12.792969 3.363281 C 13.261719 3.433594 13.640625 3.800781 13.730469 4.289063 C 13.863281 5.027344 14 6.121094 14 7.5 C 14 8.878906 13.863281 9.972656 13.734375 10.707031 C 13.644531 11.199219 13.265625 11.566406 12.792969 11.636719 C 11.722656 11.792969 9.957031 12 8 12 C 6.042969 12 4.273438 11.792969 3.207031 11.636719 C 2.738281 11.566406 2.355469 11.199219 2.265625 10.707031 C 2.136719 9.96875 2 8.878906 2 7.5 C 2 6.117188 2.136719 5.027344 2.265625 4.289063 C 2.355469 3.800781 2.734375 3.433594 3.203125 3.367188 L 3.207031 3.367188 C 4.269531 3.207031 6.03125 3 8 3 Z M 6 5 L 6 10 L 11 7.5 Z"></path>
                                        </svg>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div>
                        <img src={img} />
                    </div>
                </div>
            </div>
            <div className="lg:py-16 lg:pt-24 font-['Montserrat']">
                <div className="font-bold text-3xl text-neutral-900 pb-3 mb-6 max-md:text-2xl max-md:mt-5 max-md:mb-3">Écrivez-nous</div>
                <div className="grid grid-cols-2 gap-10 max-md:flex max-md:flex-col">
                    <div>
                        
                        <div className="w-full">
                            <div className="relative rounded-lg bg-transparent dark:bg-dark-2">
                                <form onSubmit={LoginForm}>
                                    <div className="mb-6">
                                        <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900">
                                            Nom et Prénom(s) <span className='text-red-500'>*</span>
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                type="text"
                                                placeholder='Entrez votre Nom et Prénom(s)'
                                                name="first-name"
                                                id="first-name"
                                                autoComplete="given-name"
                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                                                onChange={(e) => setNom(e.target.value)} value={nom}
                                                required
                                            />
                                        </div>
                                    </div>

                                    <div className="mb-6">
                                        <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                            Adresse mail <span className='text-red-500'>*</span>
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                id="email"
                                                placeholder='Entrez votre adresse mail'
                                                name="email"
                                                type="email"
                                                autoComplete="email"
                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                                                onChange={(e) => setMail(e.target.value)} value={mail}
                                                required
                                            />
                                        </div>
                                    </div>

                                    <div className="mb-6">
                                        <label htmlFor="num" className="block text-sm font-medium leading-6 text-gray-900">
                                            Numéro de télephone mobile <span className='text-red-500'>*</span>
                                        </label>
                                        <div className="mt-2">
                                            {/* <input
                                                            id="num"
                                                            name="num"
                                                            type="text"
                                                            autoComplete="num"
                                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                                                            onChange={(e) => setTel(e.target.value)} value={tel}
                                                            required
                                                        /> */}
                                            <PhoneInput
                                                placeholder='Entrez votre numéro'
                                                defaultCountry="bj"
                                                value={tel}
                                                onChange={(phone) => setTel(phone)}
                                            />
                                        </div>
                                    </div>

                                    <div className="mb-6">
                                        <label htmlFor="sujet" className="block text-sm font-medium leading-6 text-gray-900">
                                            Objet <span className='text-red-500'>*</span>
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                type="text"
                                                placeholder="Entrez l'objet de votre message"
                                                name="sujet"
                                                id="sujet"
                                                autoComplete="given-name"
                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                                                onChange={(e) => setSujet(e.target.value)} value={sujet}
                                                required
                                            />
                                        </div>
                                    </div>

                                    <div className="mb-6">
                                        <div>
                                            <label htmlFor="msg" className="block text-sm font-medium leading-6 text-gray-900">
                                                Votre message <span className='text-red-500'>*</span>
                                            </label>
                                            <div className="mt-2">
                                                <textarea
                                                    name="msg"
                                                    id="msg"
                                                    placeholder='Écrivez votre message'
                                                    autoComplete="given-name"
                                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                                                    onChange={(e) => setMsg(e.target.value)} value={msg}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-6">
                                        <ReCAPTCHA
                                            sitekey="6LdP_pgpAAAAAJjKUM4S7n5bvzAqXI0HaYv5hYeT"
                                            onChange={onChangeRecaptcha}
                                        />
                                    </div>
                                    
                                    <div>
                                        <Button type="submit" disabled={!verified} variant="gradient" className="rounded-full text-white flex items-center gap-2 justify-center text-center bg-green-700">
                                            Envoyer
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                strokeWidth={2}
                                                stroke="currentColor"
                                                className="h-5 w-5"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                                                />
                                            </svg>
                                        </Button>
                                        {/* Modal success */}
                                        <Modal show={isModalShow} size="2xl" popup onClose={() => setIsModalShow(false)} position="center">
                                            {/* <Modal.Header>Document Viewer</Modal.Header> */}
                                            <Modal.Body>
                                                <div className="flex flex-row gap-5 px-10 pt-16 ">
                                                    <div className="text-center flex justify-center items-center">
                                                        <img className='w-full' src={plainteimg} />
                                                    </div>
                                                    <div className="text-center justify-center items-center flex flex-col">
                                                        <div className="font-['Montserrat'] mb-3 font-bold text-3xl">
                                                            Mail soumis avec succès
                                                        </div>
                                                        <div className="font-['Montserrat'] mb-3 font-normal text-3sm">
                                                            Votre message a bien été soumis. Merci.
                                                        </div>
                                                        <div>
                                                            <Button onClick={ModalClose} variant="gradient" className="rounded-full text-white flex items-center gap-2 justify-center text-center bg-green-700">
                                                                Fermer
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>

                                            </Modal.Body>
                                        </Modal>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className='mx-auto pt-3 text-center h-full w-full'>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d242.34312303971822!2d2.3980005564259597!3d6.360033768111286!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e1!3m2!1sfr!2sbj!4v1700667591879!5m2!1sfr!2sbj" width="100%" height="450" style={{ border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ContactMail;