import React from 'react';
import { Carousel } from 'flowbite-react';
import homepage from '../Images/homme-afro-americain-peau-foncee-positive-rit-comme-entend-quelque-chose-drole.png'
import SearchBar from '../../../default_components/SearchBar/SearchBar';
import bgaccueil from '../Images/header_accueil.jpg'

function banniere() {
    return (
        <>
        {/* <section className="bg-cover bg-center bg-no-repeat bg-gray-200 bg-blend-multiply max-md:h-auto max-md:w-full" style={{backgroundImage: `url(${homepage})`, height:'10%'}}>
            <div className="px-4 mx-auto max-w-screen-xl text-left py-24 lg:py-46">
                <h1 className="max-md:text-4xl tracking-widest mb-4 text-2xl font-extrabold leading-none text-white md:text-2xl lg:text-4xl animate-fade-right animate-once animate-duration-1000 animate-delay-300">ENTREPRENDRE AU <span className="text-amber-500 dark:text-amber-500">BENIN</span></h1>
                <h3 className="max-md:text-4xl mb-4 text-2xl font-bold tracking-tight leading-none text-white md:text-2xl lg:text-4xl animate-fade-left animate-once animate-duration-1000 animate-delay-300">PLUS SIMPLE QUAND ON EST <span className="text-amber-500 dark:text-amber-500">BIEN GUIDÉ</span></h3>
                <p className="max-md:w-full tracking-wider mb-8 w-1/2 text-lg font-normal text-white lg:text-xl animate-flip-down animate-once animate-duration-1000 animate-delay-300">
                    Le guichet unique de promotion des Petites et Moyennes Entreprises et des Petites et Moyennes Industries au Bénin.
                </p>
                <div className="flex flex-col space-y-4 sm:flex-row sm:justify-left sm:space-y-0 animate-flip-up animate-once animate-duration-1000 animate-delay-300">
                    <a href="/pme-form" aria-disabled className="inline-flex justify-center items-center py-3 px-5 text-base font-medium text-center text-white rounded-l-lg rounded-tr-lg border-amber-700 bg-amber-500 hover:bg-amber-300 hover:border-amber-500 focus:ring-4 focus:ring-amber-300 dark:focus:ring-amber-900 border-b-4">
                        Enregistrer votre PME
                        <svg className="w-3.5 h-3.5 ms-2 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
                        </svg>
                    </a>
                    <button disabled className="inline-flex justify-center items-center py-3 px-5 text-base font-medium text-center text-white rounded-l-lg rounded-tr-lg border-amber-700 bg-amber-500 hover:bg-amber-300 hover:border-amber-500 focus:ring-4 focus:ring-amber-300 dark:focus:ring-amber-900 border-b-4">
                        Enregistrer votre PME
                        <svg className="w-3.5 h-3.5 ms-2 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
                        </svg>
                    </button>
                    <a href="/quisommesnous" className="inline-flex justify-center hover:text-gray-900 items-center py-3 px-5 sm:ms-4 text-base font-medium text-center text-white rounded-l-lg rounded-tr-lg border border-white hover:bg-gray-100 focus:ring-4 focus:ring-gray-400">
                        Qui sommes nous ?
                    </a>  
                </div>
            </div>
        </section> */}
            <div style={{ backgroundImage: `url(${bgaccueil})` }} className='w-full bg-no-repeat bg-cover justify-items-center pt-9'>
                <div className='flex flex-row max-md:text-center max-md:flex max-md:flex-col max-md:px-4 justify-center items-center'>
                    <div>
                        <div className="text-neutral-800 max-md:mb-5 max-md:text-[24px] text-[56px] font-semibold font-['Montserrat']">Entreprendre au Bénin,<br />devient plus simple<br />quand on est bien guidé !</div>
                        <div className="text-neutral-800 max-md:text-xl text-2xl font-normal font-['Montserrat']">Le guichet unique de promotion des Petites et Moyennes<br />Entreprises et des Petites et Moyennes Industries au Bénin.</div>
                    </div>
                    <div>
                        <img className='w-[521px] max-md:w-[521px] max-md:h-[350px] h-[550px]' src={homepage} />
                    </div>
                </div>
                {/* <div className='lg:px-44'>
                    <SearchBar />
                </div> */}
            </div>
        </>

    );
}

export default banniere;