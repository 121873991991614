import React, { useState, useEffect } from 'react';
import { Dropdown } from 'flowbite-react';
import axios from 'axios';
import ProjetCard from '../../../default_components/ProjetCard/ProjetCard';
import { Button } from "@material-tailwind/react";

const urlstandart = process.env.REACT_APP_URL_STANDART

function Projet(props) {
    const [projets, setProjets] = React.useState([]);
    const [value, setValue] = useState('Tous');
    const [selectStatus, setSelectedStatus] = useState('');
    const [tabProjet, setTab] = useState(projets);

    const handleChange = async (event) => {
        setValue(event.target.value)
        if (event.target.value != 'Tous') {
            setSelectedStatus(event.target.value)
            const filteredProjets = projets.filter((e) => (event.target.value == "OUVERT") ? (new Date(e.endDate) > Date.now()) : (new Date(e.endDate) < Date.now()));

            setTab(filteredProjets);
        } else {
            setSelectedStatus('')
        }

    };

    useEffect(() => {
        const newurl = process.env.REACT_APP_URL_STANDART + "api/appelAProjets"
        axios.get(newurl, { withCredentials: true })
            .then(response => {
                setProjets(response.data.data.appelAProjets)
            })
            .catch(err => {
                alert('Erreur lors de la récupération des informations')
            })
    }, [])

    return (
        <>
            <div className="lg:py-10 font-['Montserrat']">
                <div className="grid grid-rows-2 pb-14 gap-8 max-md:pb-16 max-md:flex max-md:flex-col">
                    <div className="font-bold max-md:text-xl text-4xl text-neutral-900">
                        Nos Projets
                    </div>
                    <div className='flex w-full flex-row gap-4 max-md:w-auto max-md:flex max-md:flex-col'>
                        <Button variant="outlined" className="text-2xs bg-white rounded-full border font-semibold border-gray-300 text-green-700" onClick={handleChange} value="Tous">
                            Tous
                        </Button>
                        <select value={selectStatus} onChange={(e) => handleChange(e)} className='outline-none text-3xs bg-white rounded-full border font-semibold border-gray-300 text-green-700 min-w-10 w-auto'>
                            <option disabled selected value="">Statut</option>
                            <option value="OUVERT">Ouvert</option>
                            <option value="FERME">Fermé</option>
                        </select>
                    </div>
                </div>
                <div className="grid lg:grid-cols-3 lg:gap-8 text-left grid-cols-2 gap-4 max-md:flex max-md:flex-col max-md:px-4">
                    {value == "Tous" ?
                        projets.map((el) => {
                            let imginfo = el.actualite.image
                            let tabimg = []
                            tabimg = imginfo.split(',')
                            imginfo = tabimg[0]
                            imginfo = imginfo.replace(/\\/g, "/")

                            const dn = new Date(el.startDate)
                            const startdate = dn.toISOString().split('T')[0]
                            const df = new Date(el.endDate)
                            const enddate = df.toISOString().split('T')[0]
                            return (
                                <ProjetCard imgprojet={imginfo} titre={el.actualite.title} subtitle={el.actualite.subTitle} date={startdate} endDate={enddate} lien={`/details-projets/${el.actualite.title}`} status={el.status} />
                            )
                        }) :
                        tabProjet.map((el) => {
                            let imginfo = el.actualite.image
                            let tabimg = []
                            tabimg = imginfo.split(',')
                            imginfo = tabimg[0]
                            imginfo = imginfo.replace(/\\/g, "/")

                            const dn = new Date(el.startDate)
                            const startdate = dn.toISOString().split('T')[0]
                            const df = new Date(el.endDate)
                            const enddate = df.toISOString().split('T')[0]
                            return (
                                <ProjetCard imgprojet={imginfo} titre={el.actualite.title} subtitle={el.actualite.subTitle} date={startdate} endDate={enddate} lien={`/details-projets/${el.actualite.title}`} status={el.status} />
                            )
                        })
                    }
                </div>
            </div>
        </>
    );
}

export default Projet;