import React from 'react';
import { Button } from "@material-tailwind/react";

const urlstandart = process.env.REACT_APP_URL_STANDART

function OpportuniteCard(props) {
    // const dateevent = new Date(props.date).toISOString().split('T')[0]
    const criteres = props.criteres
    const quatre_premier_criteres = criteres ? criteres.slice(0,4) : ""
    const resteCritere = criteres ? criteres.length - 4 : ""

    const today = new Date();
    const eventDate = new Date(props.date);

    const isDatePassed = today > eventDate;

    return (
        <>
            {/* <div className="max-w-sm font-['Montserrat'] p-5 h-[630px] bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                <div>
                    <h5 className="text-4xl font-semibold tracking-tight text-gray-900 dark:text-white line-clamp-1">{props.titre}</h5>
                </div>
                <div className='line-clamp-5 min-h-[130px] my-7 text-gray-900 font-normal'>
                    {props.subtitle}
                </div>
                <div>
                    <hr />
                </div>
                {criteres ? (
                    <div className='pt-7 pb-12 text-left text-gray-900 font-normal'>
                        {quatre_premier_criteres.map((el, index) => (
                            <li key={index} className="flex gap-4 items-center pb-3">
                                <svg className="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                </svg>
                                {el}
                            </li>
                        ))}
                        + {resteCritere} autres
                    </div>
                ) : ""}
                <div className='flex flex-col gap-4'>
                    <div>
                        <a href={props.lien}>
                            <Button variant="gradient" className="rounded-full text-white bg-green-700 flex items-center gap-2 w-full justify-center text-center">
                                Détail de l'offre
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={2}
                                    stroke="currentColor"
                                    className="h-5 w-5"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                                    />
                                </svg>
                            </Button>
                        </a>
                    </div>
                    <div>
                        <a href={props.document}>
                            <Button variant="outlined" className="rounded-full text-green-700 border-green-700 w-full">
                                Télécharger
                            </Button>
                        </a>
                    </div>
                </div>
            </div> */}
            <div className="max-w-sm font-['Montserrat'] p-5 h-[390px] bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                <div>
                    <h5 className="text-2xl max-md:text-xl font-semibold tracking-tight text-gray-900 dark:text-white line-clamp-1">{props.titre}</h5>
                </div>
                <div className='line-clamp-5 min-h-[130px] my-2 text-gray-900 font-normal'>
                    {props.subtitle}
                    <div className='mt-2 text-red-700'>
                        <span className='text-black'>Date de clôture : </span>{props.date}
                    </div>
                </div>
                <div>
                    <hr />
                </div>
                {criteres ? (
                    <div className='pt-2 pb-3 text-left text-gray-900 font-normal'>
                        {quatre_premier_criteres.map((el, index) => (
                            <li key={index} className="flex gap-4 items-center pb-3">
                                <svg className="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                </svg>
                                {el}
                            </li>
                        ))}
                        + {resteCritere} autres
                    </div>
                ) : ""}
                <div className='flex flex-col gap-4'>
                    <div>
                        <a href={props.lien}>
                            <Button variant="gradient" className="rounded-full text-white bg-green-700 flex items-center gap-2 w-full justify-center text-center">
                                Détail de l'offre
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={2}
                                    stroke="currentColor"
                                    className="h-5 w-5"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                                    />
                                </svg>
                            </Button>
                        </a>
                    </div>
                    <div>
                        {isDatePassed ? (
                            <Button
                                disabled
                                variant="outlined"
                                className="rounded-full text-green-700 border-green-700 w-full"
                            >
                                Postuler
                            </Button>
                        ) : (
                            <a href={`/postuler-recrutement/${props.titre}`}>
                                <Button
                                    variant="outlined"
                                    className="rounded-full text-green-700 border-green-700 w-full"
                                >
                                    Postuler
                                </Button>
                            </a>
                        )}
                    </div>
                    <div>
                        <a href={props.document} download>
                            <Button variant="outlined" className="rounded-full text-green-700 border-green-700 w-full">
                                Télécharger
                            </Button>
                        </a>
                    </div>
                </div>
            </div>
        </>
    );
}

export default OpportuniteCard;