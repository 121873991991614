import React,{useState, useEffect} from 'react';
import { Dropdown } from 'flowbite-react';
import axios from 'axios';
import CommuniqueCard from '../../../default_components/CommuniqueCard/CommuniqueCard';

const urlstandart = process.env.REACT_APP_URL_STANDART

function Communique(props) {
    const [communiques, setCommuniques] = useState([]);

    useEffect(() => {
        const newurl = process.env.REACT_APP_URL_STANDART + "api/communiques"
        axios.get(newurl, { withCredentials: true })
            .then(response => {
                setCommuniques(response.data.data.communiques)
            })
            .catch(err => {
                alert('Erreur lors de la récupération des informations')
            })
    }, [])

    return (
        <>
            <div className="lg:py-10 font-['Montserrat']">
                <div className="grid grid-cols-2 pb-32 max-md:pb-16 max-md:flex max-md:flex-col">
                    <div className="font-bold max-md:text-xl text-4xl text-neutral-900">
                       Communiqués importants
                    </div>
                    {/* <div className='grid justify-items-end'>
                        <Dropdown label="Dropdown bottom" placement="bottom">
                            <Dropdown.Item>Dashboard</Dropdown.Item>
                            <Dropdown.Item>Settings</Dropdown.Item>
                            <Dropdown.Item>Earnings</Dropdown.Item>
                            <Dropdown.Item>Sign out</Dropdown.Item>
                        </Dropdown>
                    </div> */}
                </div>
                {communiques.length <= 0 ? (<span>Aucun Communiqué pour le moment</span>) :
                    <div className='lg:grid lg:grid-cols-3 lg:gap-4 max-xl:space-y-2 max-md:flex max-md:flex-col max-md:px-4'>
                        {communiques.map((el) => {
                            let imginfo = el.actualite.image
                            let tabimg = []
                            tabimg = imginfo.split(',')
                            imginfo = tabimg[0]
                            imginfo = imginfo.replace(/\\/g, "/")

                            const dn = new Date(el.createdAt)
                            const startdate = dn.toISOString().split('T')[0]
                            return (
                                <CommuniqueCard imgcom={imginfo} titre={el.actualite.title} subtitle={el.actualite.subTitle} date={startdate} lien={`/details-communiques/${el.actualite.title}`} />
                            )
                        })}
                    </div>
                }
            </div>
        </>
    );
}

export default Communique;