import React from 'react';
import Description from './Components/Description';

function EvenementSelect(props) {
    return (
        <>
            <Description name={`${props.name}`} />
        </>
    );
}

export default EvenementSelect;