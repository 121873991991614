import React from 'react';
import Description from './Components/Description';

function CommuniqueSelect(props) {
    return (
        <>
            <Description name={`${props.name}`} />
        </>
    );
}

export default CommuniqueSelect;