import React from 'react';
import { Accordion } from 'flowbite-react';
import { FAQLIST } from '../../../NoSQL';

function FoireAuxQuestions(props) {
    return (
        <>
            <div className="lg:py-10 max-md:px-4 font-['Montserrat']">
                <div className="pb-16">
                    <div className="font-bold max-md:text-xl text-4xl text-neutral-900 pb-3">
                        Foire aux questions
                    </div>
                    <div className="font-normal max-md:text-2xs text-5xs text-neutral-900">
                        Ensemble contribuons à façonner un avenir meilleure pour nos PME avec nous.
                    </div>
                </div>
                <div>
                    <div>
                        {FAQLIST.map((donnelist, index) => {
                            const newTab = [...donnelist.questions];
                            return (
                                <div key={index}>
                                    {newTab.id == null ?
                                        (
                                            <>
                                                <h3 className="text-center pb-5 font-['Montserrat']">
                                                    <span className="text-xl text-neutral-900 font-semibold dark:text-white mr-5">{donnelist.title}</span>
                                                </h3>
                                            </>
                                        ) : ("")
                                    }
                                    {
                                        newTab.map((d, index) => (
                                            <Accordion collapseAll key={index}>
                                                <Accordion.Panel className="bg-transparent border-none font-[' Montserrat']">
                                                    <Accordion.Title className='text-black hover:bg-gray-300 focus:bg-gray-300 rounded-none'>{d.question}</Accordion.Title>
                                                    <Accordion.Content>
                                                        <p className="mb-2 text-left text-black dark:text-black bg-transparent">
                                                            {d.reponse}
                                                        </p>
                                                    </Accordion.Content>
                                                </Accordion.Panel>
                                            </Accordion>
                                        ))}
                                </div>
                            )
                        })}

                    </div>
                </div>
            </div>
        </>
    );
}

export default FoireAuxQuestions;