import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
// import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { FileInput, Label, Modal, TextInput, Select } from 'flowbite-react';
import {
    Tabs,
    TabsHeader,
    TabsBody,
    Tab,
    TabPanel,
} from "@material-tailwind/react";
import ReCAPTCHA from "react-google-recaptcha";
import MenuItem from '@mui/material/MenuItem';
import SelectM from '@mui/material/Select';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import axios from 'axios'
import { Button } from "@material-tailwind/react";
import imgmodalsuccess from '../Images/imgmodalsuccess.PNG'

export default function PMEForm() {
    const currentDate = new Date();
    const [activeTab, setActiveTab] = React.useState("entreprise");
    const [activeStep, setActiveStep] = useState(0);
    const [fullName, setFullName] = useState('')
    const [nom, setNom] = useState('')
    const [prenom, setPrenom] = useState('')
    const [old, setOld] = useState(new Date())
    const [genre, setGenre] = useState('')
    const [mail, setMail] = useState('')
    const [tel, setTel] = useState('')
    const [fonction, setFonction] = useState('')
    const [part, setPart] = useState(0)
    const [sigle, setSigle] = useState('')
    const [raisonSociale, setRaisonSociale] = useState('')
    const [secteur, setSecteur] = useState(0)
    const [sSecteur, setSSecteur] = useState([])
    const [sousSecteur, setSousSecteur] = useState(0)
    const [numeroRCCM, setNumeroRCCM] = useState('')
    const [villageOuQuartier, setVillageOuQuartier] = useState(0)
    const [arrondissement, setArrondissement] = useState(0)
    const [commune, setCommune] = useState(0)
    const [dept, setDept] = useState(0)
    const [dateCreation, setDateCreation] = useState(new Date())
    const [adresse, setAdresse] = useState('')
    const [telEntreprise, setTelEntreprise] = useState('')
    const [mailEntreprise, setMailEntreprise] = useState('')
    const [bp, setBp] = useState('')
    const [statutJuridique, setStatutJuridique] = useState(0)
    const [capitaleSocial, setCapitalSocial] = useState(0)
    const [currentYear, setCurrentYear] = useState(0);
    const [firstYear, setFirstYear] = useState(currentDate.getFullYear() - 1);
    const [firstYearCA, setFirstYearCA] = useState(0);
    const [firstYearEmployer, setFirstYearEmployer] = useState(0);
    const [secondYear, setSecondYear] = useState(currentDate.getFullYear() - 2);
    const [secondYearCA, setSecondYearCA] = useState(0);
    const [secondYearEmployer, setSecondYearEmployer] = useState(0);
    const [value, setValue] = React.useState('1');
    const [communes, setCommunes] = useState([]);
    const [communesChoose, setCommunesChoose] = useState([]);
    const [arrondissements, setArrondissements] = useState([]);
    const [arrondissementsChoose, setArrondissementsChoose] = useState([]);
    const [depts, setDepts] = useState([]);
    const [vils, setVils] = useState([]);
    const [secteurs, setSecteurs] = useState([]);
    const [sousSecteurs, setSousSecteurs] = useState([]);
    const [sousSecteursChoose, setSousSecteursChoose] = useState([]);
    const [vilsChoose, setVilsChoose] = useState([]);
    const [projets, setProjets] = useState([]);
    const [statusJs, setStatutJs] = useState([]);
    const [projet, setProjet] = useState([]);
    const [searchComapnyName, setSearchCompanyName] = useState('');
    const [companySearched, setCompanySearched] = useState([]);
    const [companySearchedId, setCompanySearchedId] = useState(0);
    const [selectedFileRccm, setSelectedFileRccm] = useState(null);
    const [selectedFileIdentity, setSelectedFileIdentity] = useState(null);
    const [selectedFileUpload, setSelectedFileUpload] = useState(null);
    const [selectedFileAuth, setSelectedFileAuth] = useState(null);
    const [getFileAuth, setGetFileAuth] = useState(false);
    const [errorFile, setErrorFile] = useState('');
    const [otherProjet, setOtherProject] = useState(false);
    const [otherProjetText, setOtherProjectText] = useState('');
    const [otherSecteur, setOtherSecteur] = useState(false);
    const [otherSecteurText, setOtherSecteurText] = useState('');
    const [isClicked, setIsClicked] = useState(false);
    const [isModalShow, setIsModalShow] = useState(false)
    const [msgSuccess, setMsgSuccess] = useState('')
    const [verified, setVerified] = useState(false);
    const [recaptchaRef, setRecaptchaRef] = useState(null);

    function onChangeRecaptcha(value) {
        setVerified(!verified)
    }

    const handleRadioChange = (event) => {
        setGenre(event.target.value);
    };
    const handleFileChangeAuth = (event) => {
        setSelectedFileAuth(event.target.files[0]);
    }
    const handleFileChangeRccm = (event) => {
        const selectedFile = event.target.files[0];
        const fileSizeInMegabytes = selectedFile.size / (1024 * 1024); // Taille du fichier en Mo
        console.log(fileSizeInMegabytes)
        console.log(selectedFile)
        if (fileSizeInMegabytes > 5) {
            alert("Veuillez joindre un fichier de moins de 5 MO.")
            setSelectedFileRccm(null);
        } else {
            setSelectedFileRccm(event.target.files[0]);
        }
    }
    const handleFileChangeIdentite = (event) => {
        const selectedFile = event.target.files[0];
        const fileSizeInMegabytes = selectedFile.size / (1024 * 1024); // Taille du fichier en Mo

        if (fileSizeInMegabytes > 5) {
            alert("Veuillez joindre un fichier de moins de 5 MO.")
            setSelectedFileIdentity(null);
        } else {
            setSelectedFileIdentity(event.target.files[0]);
        }

    }

    const deptChoose = (e) => {
        setDept(e.target.value)
        setCommunesChoose(communes.filter((com) => com.departementId == e.target.value))
        console.log("departementid :" + e.target.value)
        console.log(communesChoose)
    }

    const comChoose = (e) => {
        setCommune(e.target.value)
        setArrondissementsChoose(arrondissements.filter((com) => com.communeId == e.target.value))
        console.log("communeid :" + e.target.value)
        console.log(arrondissementsChoose)
    }

    const arrChoose = (e) => {
        setArrondissement(e.target.value)
        setVilsChoose(vils.filter((com) => com.arrondissementId == e.target.value))
        console.log("Arrondissementid :" + e.target.value)
        console.log(vilsChoose)
    }

    const sectChoose = (e) => {
        setSecteur(e.target.value)
        const tab = sousSecteurs.filter((com) => com.secteurId == e.target.value)
        tab.push({ libelle: "Autre", code: "AUT" })
        setSousSecteursChoose(tab)
        console.log("secteurId :" + e.target.value)
        console.log(vilsChoose)
    }

    const handleChangeSSecteur = (event) => {
        const { checked, id } = event.target;

        // Créer une copie de l'état actuel de projet
        const secteurCopy = [...sSecteur];

        if (checked) {
            // Ajouter la valeur de la case à cocher sélectionnée à la copie de projet
            secteurCopy.push(id);
            secteurCopy.map((s) => {
                console.log(s === "AUT")
                if (s === "AUT") {
                    setOtherSecteur(true)
                    return
                }
            })

        } else {
            // Retirer la valeur de la case à cocher désélectionnée de la copie de projet
            const index = secteurCopy.indexOf(id);
            secteurCopy.map((s) => {
                console.log(s === "AUT")
                if (s === "AUT") {
                    setOtherSecteur(false)
                    setOtherSecteurText('')
                    return
                }
            })

            if (index !== -1) {
                secteurCopy.splice(index, 1);
            }
        }

        // Mettre à jour l'état de projet avec la copie modifiée
        setSSecteur(secteurCopy);

        console.log(secteurCopy);
    };

    const ModalClose = () => {
        setIsClicked(false)
        window.location.reload()
    }

    const handleChangeProjet = (event) => {
        const { checked, id } = event.target;

        // Créer une copie de l'état actuel de projet
        const projetCopy = [...projet];

        if (checked) {
            // Ajouter la valeur de la case à cocher sélectionnée à la copie de projet
            projetCopy.push(id);
            id == 'Autre' ? setOtherProject(true) : setOtherProject(false)
        } else {
            // Retirer la valeur de la case à cocher désélectionnée de la copie de projet
            const index = projetCopy.indexOf(id);
            if (id == 'Autre') {
                setOtherProjectText('')
            }
            id == 'Autre' ? setOtherProject(false) : setOtherProject(true)
            if (index !== -1) {
                projetCopy.splice(index, 1);
            }
        }

        // Mettre à jour l'état de projet avec la copie modifiée
        setProjet(projetCopy);

        console.log(projetCopy);

    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    const handleNext = () => {
        setActiveStep((prevActiveStep) => {
            console.log(prevActiveStep)
            return prevActiveStep + 1
        });
    };

    const handleDateChange = (e) => {
        setOld(e.target.value);
        console.log(e.target.value)
        const today = new Date().toISOString().split('T')[0]

        if (e.target.value > today) {
            alert("Sélectionnez une date antérieure à la date d'aujourd'hui.")
            setOld('')
        } else {
            // Si l'âge est inférieur à 18 ans, afficher le champ de type fichier
            let controlleur = false
            const dateActuelle = new Date();
            const dateNaissanceObj = new Date(e.target.value);
            const differenceEnAnnees = dateActuelle.getFullYear() - dateNaissanceObj.getFullYear();
            controlleur = differenceEnAnnees < 18 ? true : false
            setGetFileAuth(controlleur);
        }

    };
    const handleDateEntChange = (e) => {
        setDateCreation(e.target.value);
        const today = new Date().toISOString().split('T')[0]

        if (e.target.value > today) {
            alert("Sélectionnez une date antérieure à la date d'aujourd'hui.")
            setDateCreation('')
        }

    };
    const handleSubmit = (e) => {
        e.preventDefault()
        setIsClicked(true)
        let tab = []
        sSecteur.map((el) => {
            let sectChoose = []
            console.log(el)
            console.log(sousSecteursChoose)
            if (el != "AUT") {

                sectChoose = sousSecteursChoose.find((s) => s.code == el)

                tab.push({ "id": parseInt(sectChoose.id, 10) })
            }
        })
        let tabp = []
        projet.map((el) => {
            let sectChoose = []
            if (el != "Autre") {


                sectChoose = projets.find((s) => s.actualite.title == el)
                // sectChoose = projets.find((s) => s.libelle == el)
                tabp.push({ "appelAProjetId": parseInt(sectChoose.id, 10), "decision": true })

            }
        })
        console.log(
            fullName + "\n " +
            old + "\n " +
            genre + "\n " +
            mail + "\n " +
            tel + "\n " +
            fonction + "\n " +
            part + "\n " +
            sigle + "\n " +
            raisonSociale + "\n " +
            secteur + "\n " +
            sousSecteur + "\n " +
            numeroRCCM + "\n " +
            villageOuQuartier + "\n " +
            arrondissement + "\n " +
            commune + "\n " +
            dept + "\n " +
            dateCreation + "\n " +
            adresse + "\n " +
            telEntreprise + "\n " +
            mailEntreprise + "\n " +
            bp + "\n " +
            statutJuridique + "\n " +
            capitaleSocial + "\n " +
            firstYear + "\n " +
            firstYearCA + "\n " +
            firstYearEmployer + "\n " +
            secondYear + "\n " +
            secondYearCA + "\n " +
            secondYearEmployer + "\n " +
            tab
        )
        const tabExercice = [];
        tabExercice.push(
            {
                "ca": parseInt(firstYearCA, 10),
                "nbreEmp": parseInt(firstYearEmployer, 10),
                "annee": parseInt(firstYear, 10)
            },
            {
                "ca": parseInt(secondYearCA, 10),
                "nbreEmp": parseInt(secondYearEmployer, 10),
                "annee": parseInt(secondYear, 10)
            }
        )
        const formdata = new FormData()
        if (otherProjetText != '') {
            formdata.append("autreAppelAProjet", otherProjetText)
        }
        if (otherSecteurText != '') {
            formdata.append("autreSecteur", otherSecteurText)
        }
        formdata.append("sigle", sigle)
        formdata.append("raisonSociale", raisonSociale)
        formdata.append("rccm", numeroRCCM)
        formdata.append("rccmFile", selectedFileRccm)
        formdata.append("adresse", adresse)
        formdata.append("phone", telEntreprise)
        formdata.append("email", mailEntreprise)
        formdata.append("capitalSocial", parseInt(capitaleSocial, 10))
        formdata.append("statusJuridiqueId", parseInt(statutJuridique, 10))
        formdata.append("villagesOuQuartiersDevilleId", villageOuQuartier)
        formdata.append("website", "website.rvr")
        formdata.append("boitePostale", bp)
        formdata.append("Exercice", JSON.stringify(tabExercice))
        formdata.append("pieceIdentite", selectedFileIdentity)
        formdata.append("autorisation", selectedFileAuth)
        formdata.append("CreateurDirigeant", JSON.stringify([
            {
                "firstname": prenom,
                "lastname": nom,
                "gender": genre,
                "phone": tel,
                "birthday": old,
                "email": mail,
                "pme_createur_dirigeants": [
                    {
                        "poste": fonction,
                        "part": parseInt(part, 10)
                    }
                ]
            }
        ]))
        formdata.append("ParticipeAppelAProjet", JSON.stringify(tabp))
        formdata.append("sousSecteurs", JSON.stringify(tab))
        if (window.confirm("Voulez-vous vraiment éffectuer cet enregistrement ?") == true) {
            const newurl = process.env.REACT_APP_URL_STANDART + "api/pmes"
            console.log(selectedFileRccm);
            
            axios.post(newurl, formdata, { withCredentials: true })
                .then(response => {
                    setMsgSuccess(`${response.data.data['uniqueIdentificationNumber'].split("-")[0]}`)
                    //window.location.reload()
                    setIsModalShow(true)
                    setIsClicked(false)
                })
                .catch(err => {
                    setIsClicked(false)
                    alert('Erreur lors de l\'enregistrement')
                })
        }
    };

    const SearchCompany = async (e) => {
        e.preventDefault();
        setFullName('')
        setOld('')
        setMail('')
        setTel('')
        setFonction(0)
        setPart(0)
        const newurlsearch = process.env.REACT_APP_URL_STANDART + `api/pmes/${searchComapnyName}`
        await axios.get(newurlsearch, { withCredentials: true })
            .then(response => {
                //alert('Recrutement supprimer')
                setCompanySearched(response.data.data);
                console.log(response.data.data)
                const tab = response.data.data[0]
                setCompanySearchedId(tab.id)
            })
            .catch(err => {
                alert('Aucune entreprise n\'a été trouvée. Assurez vous d\'avoir bien renseigné le nom ou la raison sociale')
            })
    }

    const EditForm = (e) => {
        e.preventDefault();
        const newurlsearch = process.env.REACT_APP_URL_STANDART + `api/pmes/addCreateurDirigeant/${companySearchedId}`
        const formdata = new FormData()
        formdata.append("pieceIdentite", selectedFileIdentity)
        formdata.append("autorisation", selectedFileAuth)
        formdata.append("firstname", fullName);
        formdata.append("lastname", fullName);
        formdata.append("gender", genre);
        formdata.append("phone", tel);
        formdata.append("birthday", old);
        formdata.append("email", mail);
        formdata.append("poste", fonction);
        formdata.append("part", parseInt(part, 10));
        // formdata.append("CreateurDirigeant", [
        // {
        // "firstname": fullName,
        // "lastname": fullName,
        // "gender": genre,
        // "phone": tel,
        // "birthday": old,
        // "email": mail,
        // "pme_createur_dirigeants": [{
        // "poste": fonction,
        // "part": parseInt(part, 10)
        // }]
        // }
        // ])
        axios.put(newurlsearch, formdata, { withCredentials: true })
            .then(response => {
                alert("Enregistrement réussi")
                window.location.reload()
            })
            .catch(err => {
                alert('Erreur lors de la soumission du formulaire')
            })
    }
    useEffect(() => {
        const getCurrentYear = () => {
            const currentDate = new Date();
            const year = currentDate.getFullYear();
            setCurrentYear(year);
        };
        getCurrentYear();
        const newurlcom = process.env.REACT_APP_URL_STANDART + "api/communes"
        axios.get(newurlcom, {}, { withCredentials: true })
            .then(response => {
                //alert('Recrutement supprimer')
                setCommunes(response.data.data)
            })
            .catch(err => {
                console.log('Erreur lors de la récupération des communes')
            })
        const newurlarr = process.env.REACT_APP_URL_STANDART + "api/arrondissements"
        axios.get(newurlarr, {}, { withCredentials: true })
            .then(response => {
                //console.log('Recrutement supprimer')
                setArrondissements(response.data.data)
            })
            .catch(err => {
                console.log('Erreur lors de la récupération des arrondissements')
            })
        const newurldept = process.env.REACT_APP_URL_STANDART + "api/departements"
        axios.get(newurldept, {}, { withCredentials: true })
            .then(response => {
                //console.log('Recrutement supprimer')
                setDepts(response.data.data)
            })
            .catch(err => {
                console.log('Erreur lors de la récupération du départements')
            })
        const newurlvils = process.env.REACT_APP_URL_STANDART + "api/villagesOuQuartiersDeVilles"
        axios.get(newurlvils, {}, { withCredentials: true })
            .then(response => {
                //console.log('Recrutement supprimer')
                setVils(response.data.data)
            })
            .catch(err => {
                console.log('Erreur lors de la récupération du villages ou quartiers')
            })
        const newurlsects = process.env.REACT_APP_URL_STANDART + "api/secteurs"
        axios.get(newurlsects, {}, { withCredentials: true })
            .then(response => {
                //console.log('Recrutement supprimer')
                setSecteurs(response.data.data)
            })
            .catch(err => {
                console.log('Erreur lors de la récupération du secteurs')
            })
        const newurlssects = process.env.REACT_APP_URL_STANDART + "api/sousSecteurs"
        axios.get(newurlssects, {}, { withCredentials: true })
            .then(response => {
                //console.log('Recrutement supprimer')
                setSousSecteurs(response.data.data)
            })
            .catch(err => {
                console.log('Erreur lors de la récupération du sous secteurs')
            })
        const newurlpro = process.env.REACT_APP_URL_STANDART + "api/appelAProjets"
        axios.get(newurlpro, {}, { withCredentials: true })
            .then(response => {
                //console.log('Recrutement supprimer')
                const tab = response.data.data.appelAProjets
                tab.push({ actualite: { title: "Autre" } })
                // tab = response.data.data
                setProjets(tab)
                console.log("Projets : ")
                console.log(response.data.data.appelAProjets)
                console.log(tab)
            })
            .catch(err => {
                console.log('Erreur lors de la récupération des projets')
            })
        const newurlstat = process.env.REACT_APP_URL_STANDART + "api/statutJuridiques"
        axios.get(newurlstat, {}, { withCredentials: true })
            .then(response => {
                //console.log('Recrutement supprimer')
                setStatutJs(response.data.data)
            })
            .catch(err => {
                console.log('Erreur lors de la récupération du statut juridique')
            })
    }, []);

    return (
        <div className="max-xl:px-4 lg:py-10 font-[' Montserrat']">
            <form onSubmit={handleSubmit}>
                <div className="space-y-12">
                    <div className="border-b border-gray-900/10 pb-12">
                        <h2 className="text-4xl font-semibold leading-7 text-neutral-900 pb-3 font-[' Montserrat']">Informations du dirigeant</h2>
                        <p className="mt-1 text-2xs leading-6 text-gray-500 font-[' Montserrat']">Utilisez une adresse permanente où vous pouvez recevoir du courrier.</p>

                        <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="sm:col-span-3">
                                <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900">
                                    Prénom(s) <span className='text-red-500'>*</span>
                                </label>
                                <div className="mt-2">
                                    <input
                                        type="text"
                                        name="first-name"
                                        id="first-name"
                                        autoComplete="given-name"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                                        onChange={(e) => setPrenom(e.target.value)} value={prenom}
                                        required
                                    />
                                </div>
                            </div>

                            <div className="sm:col-span-3">
                                <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-gray-900">
                                    Nom <span className='text-red-500'>*</span>
                                </label>
                                <div className="mt-2">
                                    <input
                                        type="text"
                                        name="last-name"
                                        id="last-name"
                                        autoComplete="family-name"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                                        onChange={(e) => setNom(e.target.value)} value={nom}
                                        required
                                    />
                                </div>
                            </div>

                            <div className="sm:col-span-3">
                                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                    Adresse mail <span className='text-red-500'>*</span>
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="email"
                                        name="email"
                                        type="email"
                                        autoComplete="email"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                                        onChange={(e) => setMail(e.target.value)} value={mail}
                                        required
                                    />
                                </div>

                            </div>

                            <div className="sm:col-span-3">
                                <label htmlFor="naissance" className="block text-sm font-medium leading-6 text-gray-900">
                                    Date de naissance <span className='text-red-500'>*</span>
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="naissance"
                                        name="naissance"
                                        type="date"
                                        autoComplete="naissance"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                                        onChange={handleDateChange}
                                        value={old}
                                        required
                                    />
                                </div>
                            </div>
                            {getFileAuth && (
                                <div className="col-span-full">
                                    <label htmlFor="cover-photoauto" className="block text-sm font-medium leading-6 text-gray-900">
                                        Autorisation <span className='text-red-500'>*</span>
                                    </label>
                                    <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                                        <div className="text-center">
                                            <div className="mt-4 flex text-sm leading-6 text-gray-600">
                                                <label
                                                    htmlFor="file-upload-auto"
                                                    className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                                                >
                                                    <span>Glisser un fichier</span>
                                                    <input onChange={handleFileChangeAuth} id="file-upload-auto" name="file-upload-auto" type="file" className="sr-only" />
                                                </label>
                                            </div>
                                            <p className="text-xs leading-5 text-gray-600">Max 5MB</p>
                                        </div>
                                    </div>
                                    <p className="text-xs leading-5 text-black font-bold">{selectedFileAuth ? selectedFileAuth.name : ("")}</p>
                                </div>
                            )}
                            <div className="sm:col-span-6">
                                <label htmlFor="naissance" className="block text-sm font-medium leading-6 text-gray-900">
                                    Genre <span className='text-red-500'>*</span>
                                </label>
                                <div className="flex items-center ps-4 border border-gray-200 rounded dark:border-gray-700">
                                    <input required onChange={handleRadioChange} id="bordered-radio-1" type="radio" value="HOMME" name="bordered-radio" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                    <label for="bordered-radio-1" className="w-full py-4 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Homme</label>
                                </div>
                                <div className="flex items-center ps-4 border border-gray-200 rounded dark:border-gray-700">
                                    <input required onChange={handleRadioChange} id="bordered-radio-2" type="radio" value="FEMME" name="bordered-radio" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                    <label for="bordered-radio-2" className="w-full py-4 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Femme</label>
                                </div>
                            </div>

                            <div className="sm:col-span-3">
                                <label htmlFor="num" className="block text-sm font-medium leading-6 text-gray-900">
                                    Numéro de télephone mobile <span className='text-red-500'>*</span>
                                </label>
                                <div className="mt-2">
                                    {/* <input
                                                            id="num"
                                                            name="num"
                                                            type="text"
                                                            autoComplete="num"
                                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                                                            onChange={(e) => setTel(e.target.value)} value={tel}
                                                            required
                                                        /> */}
                                    <PhoneInput
                                        defaultCountry="bj"
                                        value={tel}
                                        onChange={(phone) => setTel(phone)}
                                    />
                                </div>
                            </div>

                            <div className="sm:col-span-3">
                                <label htmlFor="country" className="block text-sm font-medium leading-6 text-gray-900">
                                    Fonction dans l'entreprise <span className='text-red-500'>*</span>
                                </label>
                                <div className="mt-2">
                                    <select
                                        id="country"
                                        name="country"
                                        autoComplete="country-name"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                                        onChange={(e) => setFonction(e.target.value)} value={fonction}
                                    >
                                        <option value=''>Sélectionner votre fonction dans l'entreprise</option>
                                        <option value='Associé'>Associé</option>
                                        <option value='Propriétaire'>Propriétaire</option>
                                        <option value='Actionnaire'>Actionnaire</option>
                                        <option value='Gestionnaire'>Gestionnaire</option>
                                        <option value='Collaborateur'>Collaborateur</option>
                                    </select>
                                </div>
                            </div>

                            <div className="sm:col-span-3">
                                <label htmlFor="adresse" className="block text-sm font-medium leading-6 text-gray-900">
                                    Quel est votre pourcentage des parts sociales que vous détenez ? <span className='text-red-500'>*</span>
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="adresse"
                                        name="adresse"
                                        type="number"
                                        autoComplete="number"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                                        onChange={(e) => setPart(e.target.value)} value={part}
                                        min="0"
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="border-b border-gray-900/10 pb-12">
                        <h2 className="text-4xl font-semibold leading-7 text-neutral-900 pb-3">Informations de l'entreprise</h2>
                        <p className="mt-1 text-2xs leading-6 text-gray-600">Cette section concerne les informations relatives à l'entreprise</p>

                        <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="sm:col-span-3">
                                <label htmlFor="sigle" className="block text-sm font-medium leading-6 text-gray-900">
                                    Sigle de l'entreprise <span className='text-red-500'>*</span>
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="sigle"
                                        name="sigle"
                                        type="text"
                                        autoComplete="text"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                                        onChange={(e) => setSigle(e.target.value)} value={sigle}
                                        placeholder='Ex: ADPME'
                                        required
                                    />
                                </div>

                            </div>

                            <div className="sm:col-span-3">
                                <label htmlFor="sigle" className="block text-sm font-medium leading-6 text-gray-900">
                                    Raison sociale <span className='text-red-500'>*</span>
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="sigle"
                                        name="sigle"
                                        type="text"
                                        autoComplete="text"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                                        onChange={(e) => setRaisonSociale(e.target.value)} value={raisonSociale}
                                        required
                                        placeholder='Ex: Agence de Developpement des Petites et Moyennes Entreprises'

                                    />
                                </div>

                            </div>

                            <div className="sm:col-span-3">
                                <label htmlFor="capital_social" className="block text-sm font-medium leading-6 text-gray-900">
                                    Capital social (en FCFA) <span className='text-red-500'>*</span>
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="capital_social"
                                        name="capital_social"
                                        type="number"
                                        autoComplete="text"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                                        onChange={(e) => setCapitalSocial(e.target.value)} value={capitaleSocial}
                                        min="0"
                                        required
                                    />
                                </div>

                            </div>

                            <div className="sm:col-span-3">
                                <label htmlFor="date_creation" className="block text-sm font-medium leading-6 text-gray-900">
                                    Date de création de l'entreprise <span className='text-red-500'>*</span>
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="date_creation"
                                        name="date_creation"
                                        type="date"
                                        autoComplete="text"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                                        onChange={handleDateEntChange} value={dateCreation}
                                        required

                                    />
                                </div>

                            </div>

                            <div className="sm:col-span-3">
                                <label htmlFor="tel_ent" className="block text-sm font-medium leading-6 text-gray-900">
                                    Télephone de l'entreprise <span className='text-red-500'>*</span>
                                </label>
                                <div className="mt-2">
                                    {/* <input
                                                            id="tel_ent"
                                                            name="tel_ent"
                                                            type="text"
                                                            autoComplete="text"
                                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                                                            onChange={(e) => setTelEntreprise(e.target.value)} value={telEntreprise}
                                                            required
                                                        /> */}
                                    <PhoneInput
                                        defaultCountry="bj"
                                        value={telEntreprise}
                                        onChange={(phone) => setTelEntreprise(phone)}
                                    />
                                </div>

                            </div>

                            <div className="sm:col-span-3">
                                <label htmlFor="mail_ent" className="block text-sm font-medium leading-6 text-gray-900">
                                    Adresse mail de l'entreprise <span className='text-red-500'>*</span>
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="mail_ent"
                                        name="mail_ent"
                                        type="mail"
                                        autoComplete="text"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                                        onChange={(e) => setMailEntreprise(e.target.value)} value={mailEntreprise}
                                        placeholder='Ex: adpme@gmail.com'
                                        required
                                    />
                                </div>

                            </div>

                            <div className="sm:col-span-3">
                                <label htmlFor="country" className="block text-sm font-medium leading-6 text-gray-900">
                                    Département <span className='text-red-500'>*</span>
                                </label>
                                <div className="mt-2">
                                    <select
                                        id="country"
                                        name="country"
                                        autoComplete="country-name"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                                        onChange={deptChoose}
                                        value={dept}
                                    >
                                        <option disabed selected>Sélectionner un département</option>
                                        {depts.length > 0 ?
                                            depts.map((sp, index) => {
                                                return (
                                                    <option key={index} value={sp.id}>{sp.libelle}</option>
                                                )
                                            }) :
                                            ("Aucun départements")}
                                    </select>
                                </div>
                            </div>
                            <div className="sm:col-span-3">
                                <label htmlFor="country" className="block text-sm font-medium leading-6 text-gray-900">
                                    Commune <span className='text-red-500'>*</span>
                                </label>
                                <div className="mt-2">
                                    <select
                                        id="country"
                                        name="country"
                                        autoComplete="country-name"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                                        disabled={dept ? false : true}
                                        onChange={comChoose} value={commune}
                                    >
                                        <option disabed selected>Sélectionner une commune</option>
                                        {communesChoose.length > 0 ?
                                            communesChoose.map((sp, index) => {
                                                return (
                                                    <option key={index} value={sp.id}>{sp.libelle}</option>
                                                )
                                            }) :
                                            ("Aucune commune")}
                                    </select>
                                </div>
                            </div>
                            <div className="sm:col-span-3">
                                <label htmlFor="country" className="block text-sm font-medium leading-6 text-gray-900">
                                    Arrondissement <span className='text-red-500'>*</span>
                                </label>
                                <div className="mt-2">
                                    <select
                                        id="country"
                                        name="country"
                                        autoComplete="country-name"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                                        disabled={commune ? false : true}
                                        onChange={arrChoose} value={arrondissement}
                                    >
                                        <option disabed selected>Sélectionner un arrondissement</option>
                                        {arrondissementsChoose.length > 0 ?
                                            arrondissementsChoose.map((sp, index) => {
                                                return (
                                                    <option key={index} value={sp.id}>{sp.libelle}</option>
                                                )
                                            }) :
                                            ("Aucun arrondissement")}
                                    </select>
                                </div>
                            </div>

                            <div className="sm:col-span-3">
                                <label htmlFor="country" className="block text-sm font-medium leading-6 text-gray-900">
                                    Village ou quartier de ville <span className='text-red-500'>*</span>
                                </label>
                                <div className="mt-2">
                                    <select
                                        id="country"
                                        name="country"
                                        autoComplete="country-name"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                                        disabled={arrondissement ? false : true}
                                        onChange={(e) => setVillageOuQuartier(e.target.value)} value={villageOuQuartier}
                                    >
                                        <option disabed selected>Sélectionner votre village ou votre quartier</option>
                                        {vilsChoose.length > 0 ?
                                            vilsChoose.map((sp, index) => {
                                                return (
                                                    <option key={index} value={sp.id}>{sp.libelle}</option>
                                                )
                                            }) :
                                            ("Aucun Village ou quartier")}
                                    </select>
                                </div>
                            </div>

                            <div className="sm:col-span-6">
                                <label htmlFor="adresse_physique" className="block text-sm font-medium leading-6 text-gray-900">
                                    Adresse physique de l'entreprise (Carré, Lot, ect...) <span className='text-red-500'>*</span>
                                </label>
                                <div className="mt-2">
                                    <input
                                        type="text"
                                        name="adresse_physique"
                                        id="adresse_physique"
                                        autoComplete="given-name"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                                        onChange={(e) => setAdresse(e.target.value)} value={adresse}
                                        placeholder='Ex: c/20XX Lot X'
                                        required
                                    />
                                </div>
                            </div>

                            <div className="sm:col-span-6">
                                <label htmlFor="ideas" className="block text-sm font-medium leading-6 text-gray-900">
                                    Domaines d'activités <span className='text-red-500'>*</span>
                                </label>
                                <select
                                    id="country"
                                    name="country"
                                    autoComplete="country-name"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"

                                    onChange={sectChoose} value={secteur}
                                >
                                    <option value={0} disabed selected>Sélectionner le secteur de votre entreprise</option>
                                    {secteurs.length > 0 ?
                                        secteurs.map((sp, index) => {
                                            return (
                                                <option key={index} value={sp.id}>{sp.libelleSecteur}</option>
                                            )
                                        }) :
                                        ("Aucun Secteur ou quartier")}
                                </select>
                            </div>

                            {secteur ? (
                                <>
                                    <div className="sm:col-span-6">
                                        <div className="mb-2 block">
                                            <Label htmlFor="sous-secteur" value={<span className='font-bold'>Sous-secteur (Vous pouvez faire plusieurs sélection) <span className='text-red-500'>*</span></span>} />
                                        </div>
                                        {sousSecteursChoose.map((sec, index) => (
                                            <div key={index} className="flex items-center ps-4 border border-gray-200 rounded dark:border-gray-700">
                                                <input renderValue={(selected) => selected.join(', ')} onChange={handleChangeSSecteur} value={sec.libelle} id={sec.code} type="checkbox" name={sec.code} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                <label for={sec.code} className="w-full py-4 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">{sec.libelle}</label>
                                            </div>
                                        ))}
                                    </div>
                                    <div className='sm:col-span-6 mt-2'>
                                        <div>
                                            <label htmlFor="othersecteur" className="block text-sm font-medium leading-6 text-gray-900">
                                                Précisez le sous-secteur de votre entreprise
                                            </label>
                                            <div className="mt-2">
                                                <textarea
                                                    name="othersecteur"
                                                    id="othersecteur"
                                                    autoComplete="given-name"
                                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                                                    onChange={(e) => setOtherSecteurText(e.target.value)} value={otherSecteurText}
                                                    disabled={!otherSecteur ? true : false}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ) : ("")}


                            <div className="sm:col-span-6">
                                <label htmlFor="rccm" className="block text-sm font-medium leading-6 text-gray-900">
                                    N° RCCM <span className='text-red-500'>*</span>
                                </label>
                                <div className="mt-2">
                                    <input
                                        type="text"
                                        name="rccm"
                                        id="rccm"
                                        autoComplete="given-name"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                                        onChange={(e) => setNumeroRCCM(e.target.value)} value={numeroRCCM}
                                        placeholder='Ex: RCCM RB/COT/XX B XXXXX'
                                        required
                                    />
                                </div>
                            </div>

                            <div className="sm:col-span-6">
                                <label htmlFor="bp" className="block text-sm font-medium leading-6 text-gray-900">
                                    Boîte postale
                                </label>
                                <div className="mt-2">
                                    <input
                                        type="text"
                                        name="bp"
                                        id="bp"
                                        autoComplete="given-name"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                                        onChange={(e) => setBp(e.target.value)} value={bp}
                                        placeholder='Ex: BP XXXXX'
                                    />
                                </div>
                            </div>

                            <div className="sm:col-span-6">
                                <label htmlFor="status" className="block text-sm font-medium leading-6 text-gray-900">
                                    Statut juridique de votre entreprise <span className='text-red-500'>*</span>
                                </label>
                                <select
                                    id="status"
                                    name="status"
                                    autoComplete="status-name"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"

                                    onChange={(e) => setStatutJuridique(e.target.value)} value={statutJuridique}
                                >
                                    <option value={0} disabed selected>Sélectionner le statut juridique de votre entreprise</option>
                                    {statusJs.length > 0 ?
                                        statusJs.map((sp, index) => {
                                            return (
                                                <option key={index} value={sp.id}>{sp.libelle}</option>
                                            )
                                        }) :
                                        ("Aucun Statut juridique")}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="border-b border-gray-900/10 pb-12">
                        <h2 className="text-4xl font-semibold leading-7 text-neutral-900 pb-3">Potentiel commercial et stratégie de croissance</h2>
                        <p className="mt-1 text-2xs leading-6 text-gray-600">Soumettez vos chiffres d'affaires les deux dernières années et les pièces jointes.</p>

                        <div>
                            <span>Quel est le montant de votre chiffre annuel moyen au cours des deux dernières années et le nombre d'employé que vous avez ? (en FCFA) <span className='text-red-500'>*</span></span>
                            <fieldset className="border border-solid border-gray-300 p-3">
                                <legend><div><span className='font-bold'>{currentYear - 2}</span></div></legend>
                                <div className='mt-2'>

                                    <div>
                                        <label htmlFor="firstyearca" className="block text-sm font-medium leading-6 text-gray-900">
                                            Indiquer le chiffre d'affaire
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                type="number"
                                                name="firstyearca"
                                                id="firstyearca"
                                                autoComplete="given-name"
                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                                                onChange={(e) => setFirstYearCA(e.target.value)} value={firstYearCA}
                                                placeholder='Ex: 520XXXXXX'
                                                min="0"
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='mt-2'>

                                    <div>
                                        <label htmlFor="firstyearempl" className="block text-sm font-medium leading-6 text-gray-900">
                                            Indiquer le nombre d'employé
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                type="number"
                                                name="firstyearempl"
                                                id="firstyearempl"
                                                autoComplete="given-name"
                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                                                onChange={(e) => setFirstYearEmployer(e.target.value)} value={firstYearEmployer}
                                                min="0"
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                            <br />
                            <fieldset className="border border-solid border-gray-300 p-3">
                                <legend><div><span className='font-bold'>{currentYear - 1}</span></div></legend>
                                <div className='mt-2'>
                                    <div>
                                        <label htmlFor="firstyearca" className="block text-sm font-medium leading-6 text-gray-900">
                                            Indiquer le chiffre d'affaire
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                type="number"
                                                name="firstyearca"
                                                id="firstyearca"
                                                autoComplete="given-name"
                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                                                onChange={(e) => setSecondYearCA(e.target.value)} value={secondYearCA}
                                                placeholder='Ex: 520XXXXXX'
                                                min="0"
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='mt-2'>
                                    <div>
                                        <label htmlFor="firstyearempl" className="block text-sm font-medium leading-6 text-gray-900">
                                            Indiquer le nombre d'employé
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                type="number"
                                                name="firstyearempl"
                                                id="firstyearempl"
                                                autoComplete="given-name"
                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                                                onChange={(e) => setSecondYearEmployer(e.target.value)} value={secondYearEmployer}
                                                min="0"
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                        </div>

                        {/* <div className="sm:col-span-6">
                                                <div className="mb-2 block">
                                                    <Label htmlFor="projet" value={<span className='font-bold'>Quels sont les projets dont vous avez déjà bénéficiez ? (Vous pouvez faire plusieurs sélection)<span className='text-red-500'>*</span></span>} />
                                                </div>
                                                <FormControl className='w-full'>
                                                    <SelectM
                                                        style={{ height: '41px' }}
                                                        labelId="demo-multiple-checkbox-label"
                                                        id="demo-multiple-checkbox"
                                                        multiple
                                                        value={projet}
                                                        onChange={handleChangeProjet}
                                                        renderValue={(selected) => selected.join(', ')}
                                                    >
                                                        {projets.map((sec, index) => (
                                                            <MenuItem
                                                                key={index}
                                                                value={sec.actualite.title}
                                                            >
                                                                {sec.actualite.title}
                                                            </MenuItem>
                                                        ))}
                                                    </SelectM>
                                                </FormControl>
                                            </div> */}
                        <div className="sm:col-span-6 mt-5">
                            <div className="mb-2 block">
                                <Label htmlFor="projet" value={<span className='font-bold'>Quels sont les projets dont vous avez déjà bénéficiez ? (Vous pouvez faire plusieurs sélection)<span className='text-red-500'>*</span></span>} />
                            </div>
                            {projets.map((sec, index) => (
                                <div key={index} className="flex items-center ps-4 border border-gray-200 rounded dark:border-gray-700">
                                    <input renderValue={(selected) => selected.join(', ')} onChange={handleChangeProjet} value={sec.actualite.title} id={sec.actualite.title} type="checkbox" name={sec.actualite.title} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                    <label for={sec.actualite.title} className="w-full py-4 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">{sec.actualite.title}</label>
                                </div>
                            ))}
                        </div>
                        <div className='mt-2'>
                            <div>
                                <label htmlFor="otherproject" className="block text-sm font-medium leading-6 text-gray-900">
                                    Précisez le projet dont vous avez bénéficiez
                                </label>
                                <div className="mt-2">
                                    <textarea
                                        name="otherproject"
                                        id="otherproject"
                                        autoComplete="given-name"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                                        onChange={(e) => setOtherProjectText(e.target.value)} value={otherProjetText}
                                        disabled={!otherProjet ? true : false}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-span-full mt-5">
                            <label htmlFor="rccm" className="block text-sm font-medium leading-6 text-gray-900">
                                RCCM <span className='text-red-500'>*</span>
                            </label>
                            <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                                <div className="text-center">
                                    <div className="mt-4 flex text-sm leading-6 text-gray-600">
                                        <label
                                            htmlFor="file-rccm-upload"
                                            className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                                        >
                                            <span>Glisser un fichier</span>
                                            <input required onChange={handleFileChangeRccm} id="file-rccm-upload" name="file-rccm-upload" type="file" className="sr-only" />
                                        </label>
                                    </div>
                                    <p className="text-xs leading-5 text-gray-600">Max 5MB</p>
                                </div>
                            </div>
                            <p className="text-xs leading-5 text-black font-bold">{selectedFileRccm ? selectedFileRccm.name : ("")}</p>
                        </div>
                        <div className="col-span-full mt-5">
                            <label htmlFor="carte" className="block text-sm font-medium leading-6 text-gray-900">
                                Carte d'identité <span className='text-red-500'>*</span>
                            </label>
                            <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                                <div className="text-center">
                                    <div className="mt-4 flex text-sm leading-6 text-gray-600">
                                        <label
                                            htmlFor="file-upload"
                                            className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                                        >
                                            <span>Glisser un fichier</span>
                                            <input required onChange={handleFileChangeIdentite} id="file-upload" name="file-upload" type="file" className="sr-only" />
                                        </label>
                                    </div>
                                    <p className="text-xs leading-5 text-gray-600">Max 5MB</p>
                                </div>
                            </div>
                            <p className="text-xs leading-5 text-black font-bold">{selectedFileIdentity ? selectedFileIdentity.name : ("")}</p>
                        </div>
                        <div className="mt-3">
                            <ReCAPTCHA
                                ref={recaptchaRef}
                                sitekey="6LdP_pgpAAAAAJjKUM4S7n5bvzAqXI0HaYv5hYeT"
                                onChange={onChangeRecaptcha}
                            />
                        </div>
                    </div>
                </div>

                <div className="mt-6 flex items-center justify-end gap-x-6">
                    <Button variant="outlined" disabled={isClicked} type='reset' className="rounded-full">
                        Abandonner
                    </Button>
                    <Button variant="gradient" disabled={!verified} type="submit" className="rounded-full bg-green-700">
                        Valider
                    </Button>
                </div>
                {/* Modal success */}
                <Modal show={isModalShow} size="2xl" popup onClose={() => setIsModalShow(false)} position="center">
                    {/* <Modal.Header>Document Viewer</Modal.Header> */}
                    <Modal.Body>
                        <div className="flex flex-row gap-5 px-10 pt-16 max-md:px-4 max-md:pt-4 max-md:flex max-md:flex-col">
                            <div className="text-center flex justify-center items-center">
                                <img className='w-[650px] h-[120px] max-md:w-[50%] max-md:h-[50%]' src={imgmodalsuccess} />
                            </div>
                            <div className="text-center justify-center items-center flex flex-col">
                                <div className="font-['Montserrat'] mb-3 font-bold text-3xl max-md:text-xl">
                                    Entreprise créée avec succès
                                </div>
                                <div className="font-['Montserrat'] mb-3 font-normal text-3sm">
                                    Félicitations ! Votre entreprise est désormais officiellement créée. Bienvenue dans le monde entrepreneurial! Votre entreprise est enregistrer sous le numero: <span className='font-bold'>{msgSuccess}</span>. Veuillez noter ce numero, elle servira pour toute modification ou toute recherche à l'avenir.
                                </div>
                                <div>
                                    <Button onClick={ModalClose} variant="gradient" className="rounded-full text-white flex items-center gap-2 justify-center text-center bg-green-700">
                                        Fermer
                                    </Button>
                                </div>
                            </div>
                        </div>

                    </Modal.Body>
                </Modal>
            </form>
        </div>
    );
}//detpt, commune, arrondissement, village ou quartiers et adresse(carré et autre), ajouter capital sociale(étape deux), nbre employer (étape 3), donner la possibilité d'enregistrer les associées selon si l'entreprise existe