import React, {useState} from 'react';
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { Button } from "@material-tailwind/react";
import img from '../Images/img_after_form_1.png'
import ModalSuccessPlainte from '../../../default_components/ModalSuccessPlainte/ModalSuccessPlainte';
import axios from 'axios'
import plainteimg from '../Images/Plainte.PNG'
import { Modal } from 'flowbite-react'
import ReCAPTCHA from "react-google-recaptcha";

function Plainte(props) {
    const [nom, setNom] = useState('');
    const [prenom, setPrenom] = useState('');
    const [mail, setMail] = useState('');
    const [tel, setTel] = useState('');
    const [sujet, setSujet] = useState('');
    const [msg, setMsg] = useState('');
    const [isModalShow, setIsModalShow] = useState(false)
    const [isClicked, setIsClicked] = useState(false);
    const [verified, setVerified] = useState(false);
    const [recaptchaRef, setRecaptchaRef] = useState(null);
    
    const LoginForm = (e) => {
        e.preventDefault();
        console.log(nom);
        console.log(mail);
        console.log(tel);
        console.log(msg);
        const newurl = process.env.REACT_APP_URL_STANDART + 'api/sendMail'
        console.log(newurl)
        setIsClicked(true)
        const nomComplet = prenom + ' ' + nom;
        //recaptchaRef.current.reset();
        setNom('')
        setPrenom('')
        setMail('')
        setTel('')
        setMsg('')
        setSujet('')

        axios.post(newurl,
            { subject: "Plainte", from: "admresociaux@gmail.com", text: mail || "" + '\n\n' + nomComplet || "" + ' ' + tel || "" + '\n\n' + msg, to: "contact@adpme.bj" })
            .then(data => {
                setNom('')
                setPrenom('')
                setMail('')
                setTel('')
                setMsg('')
                setSujet('')
                setIsModalShow(true)
                setIsClicked(false)
                recaptchaRef.current.reset();
            })
            .catch(err => {
                alert('Erreur... Veuillez réessayer plus tard.')
                setIsClicked(false)
                setNom('')
                setPrenom('')
                setMail('')
                setTel('')
                setMsg('')
                setSujet('')
                //window.location.reload()
            })
    }
    const ModalClose = ()=>{
        setIsModalShow(false)
        // window.location.reload()
    }
    function onChangeRecaptcha(value) {
        setVerified(!verified)
    }
    return (
        <>
            <div className="max-xl:px-4 max-md:px-4 lg:py-10 font-[' Montserrat']">
                <h2 className="text-4xl max-md:text-xl font-semibold leading-7 text-neutral-900 pb-3 font-[' Montserrat']">Prérequis pour une plainte</h2>
                <p className="mt-1 text-2xs leading-6 text-gray-500 font-[' Montserrat'] pb-10">
                    <div>
                        Si vous avez été victime d'une infraction, vous pouvez porter plainte auprès des autorités compétentes.
                        Pour porter plainte, vous pouvez :
                    </div>
                    <div>
                        - Vous rendre dans un commissariat de police ou une brigade de gendarmerie.
                    </div>
                    <div>
                        - Envoyer un courrier au procureur de la République.
                    </div>
                    <div>
                        - Déposer une plainte en ligne...
                    </div>
                </p>
                <div className='grid grid-cols-2 gap-4 max-md:flex max-md:flex-col'>
                    <div>
                        <form onSubmit={LoginForm}>
                            <div className="mb-6">
                                <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900">
                                    Nom
                                </label>
                                <div className="mt-2">
                                    <input
                                        type="text"
                                        placeholder='Entrez votre Nom'
                                        name="first-name"
                                        id="first-name"
                                        autoComplete="given-name"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                                        onChange={(e) => setNom(e.target.value)} value={nom}
                                        
                                    />
                                </div>
                            </div>
                            <div className="mb-6">
                                <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900">
                                    Prénom(s)
                                </label>
                                <div className="mt-2">
                                    <input
                                        type="text"
                                        placeholder='Entrez votre Prénom(s)'
                                        name="first-name"
                                        id="first-name"
                                        autoComplete="given-name"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                                        onChange={(e) => setPrenom(e.target.value)} value={prenom}
                                        
                                    />
                                </div>
                            </div>

                            <div className="mb-6">
                                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                    Adresse mail
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="email"
                                        placeholder='Entrez votre adresse mail'
                                        name="email"
                                        type="email"
                                        autoComplete="email"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                                        onChange={(e) => setMail(e.target.value)} value={mail}
                                        
                                    />
                                </div>
                            </div>

                            <div className="mb-6">
                                <label htmlFor="num" className="block text-sm font-medium leading-6 text-gray-900">
                                    Numéro de télephone mobile
                                </label>
                                <div className="mt-2">
                                    {/* <input
                                                            id="num"
                                                            name="num"
                                                            type="text"
                                                            autoComplete="num"
                                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                                                            onChange={(e) => setTel(e.target.value)} value={tel}
                                                            required
                                                        /> */}
                                    <PhoneInput
                                        placeholder='Entrez votre numéro'
                                        defaultCountry="bj"
                                        value={tel}
                                        onChange={(phone) => setTel(phone)}
                                    />
                                </div>
                            </div>

                            <div className="mb-6">
                                <div>
                                    <label htmlFor="msg" className="block text-sm font-medium leading-6 text-gray-900">
                                        Votre message <span className='text-red-500'>*</span>
                                    </label>
                                    <div className="mt-2">
                                        <textarea
                                            name="msg"
                                            id="msg"
                                            placeholder='Écrivez votre message'
                                            autoComplete="given-name"
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                                            onChange={(e) => setMsg(e.target.value)} value={msg}
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="mb-6">
                                <ReCAPTCHA
                                    ref={recaptchaRef}
                                    sitekey="6LdP_pgpAAAAAJjKUM4S7n5bvzAqXI0HaYv5hYeT"
                                    onChange={onChangeRecaptcha}
                                />
                            </div>
                            <div>
                                <Button type="submit" disabled={!verified} variant="gradient" className="rounded-full text-white flex items-center gap-2 justify-center text-center bg-green-700">
                                    Envoyer
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={2}
                                        stroke="currentColor"
                                        className="h-5 w-5"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                                        />
                                    </svg>
                                </Button>
                                {/* Modal success */}
                                <Modal show={isModalShow} size="2xl" popup onClose={() => setIsModalShow(false)} position="center">
                                    {/* <Modal.Header>Document Viewer</Modal.Header> */}
                                    <Modal.Body>
                                        <div className="flex flex-row gap-5 px-10 pt-16 max-md:px-4 max-md:pt-4 max-md:flex max-md:flex-col">
                                            <div className="text-center flex justify-center items-center">
                                                <img className='w-full max-md:w-[50%] max-md:h-[50%]' src={plainteimg} />
                                            </div>
                                            <div className="text-center justify-center items-center flex flex-col">
                                                <div className="font-['Montserrat'] mb-3 font-bold text-3xl max-md:text-xl">
                                                    Plainte soumise avec succès
                                                </div>
                                                <div className="font-['Montserrat'] mb-3 font-normal text-3sm">
                                                    Plainte enregistrée ! Votre message a été transmis avec succès. Nous prendrons les actions appropriées. Merci de nous aider à améliorer nos services.
                                                </div>
                                                <div>
                                                    <Button onClick={ModalClose} variant="gradient" className="rounded-full text-white flex items-center gap-2 justify-center text-center bg-green-700">
                                                        Fermer
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </Modal.Body>
                                </Modal>
                            </div>
                        </form>
                    </div>
                    <div className='flex justify-center items-center'>
                        <img src={img} />
                    </div>
                </div>
            </div>
        </>
    );
}

export default Plainte;