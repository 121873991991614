import React, { useState } from 'react';

function EserviceCard(props) {
    return (
        <>
            <div className="max-w-sm font-['Montserrat'] hover:bg-green-400 text-neutral-800 hover:text-white shadow-sm h-[260px] bg-white rounded-lg dark:bg-gray-800 dark:border-gray-700">
                <div className="p-5">
                    <div className='pt-5'>
                        <a href="#">
                            <h5 className="mb-2 text-3xl font-semibold tracking-tight dark:text-white line-clamp-3">{props.title}</h5>
                        </a>
                    </div>
                    <div className='pt-5'>
                        <a href="#">
                            <h5 className="mb-2 text-3xs text-gray-500 font-semibold tracking-tight dark:text-white line-clamp-3">{props.subtitle}</h5>
                        </a>
                    </div>
                    <div className='flex flex-col text-left pt-6 pb-6'>
                        <a className='flex flex-row text-left items-center pb-1 font-bold text-green-700 hover:underline cursor-pointer' href={props.lien} target='_blank'>
                            <span>Consulter le service</span>
                            <svg className="w-3 h-3 ms-2.5 rtl:rotate-[270deg]" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 11v4.833A1.166 1.166 0 0 1 13.833 17H2.167A1.167 1.167 0 0 1 1 15.833V4.167A1.166 1.166 0 0 1 2.167 3h4.618m4.447-2H17v5.768M9.111 8.889l7.778-7.778" />
                            </svg>
                        </a>
                        <div>
                            <span>Source ADPME</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default EserviceCard;