import React from 'react';
import Banniere from '../../default_pages/banniere/Banniere';
import FormContact from './Components/formContact';
import Maps from './Components/Maps';
import TabsComponentsContact from './Components/TabsComponentsContact';

function Contact(props) {
    return (
        <>
            <div className='bg-[#E3F2E5]'>
                <Banniere desc1="Contactez" desc2="-Nous" />
                <TabsComponentsContact />
            </div>
        </>
    );
}

export default Contact;